import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxColumnChartComponent } from './dx-column-chart.component';

@NgModule({
  declarations: [
    DxColumnChartComponent
  ],
  entryComponents: [
    DxColumnChartComponent
  ],
  exports: [
    DxColumnChartComponent
  ],
  imports: [
    CommonModule
  ]
})
export class DxColumnChartModule { }

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-styles-themes',
  templateUrl: './styles-themes.component.html'
})
export class StylesThemesComponent implements OnInit {

  stylesText = {
    description: 'Minimal base styles for consistent components',
    title: 'Styles'
  };

  constructor() { }

  ngOnInit() {
  }

}

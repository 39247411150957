import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Observable, Subject } from 'rxjs';

import { DxModalService } from '../dx-modal/dx-modal.service';

@Component({
  animations: [
    trigger('scaleIn', [
      state('in', style({transform: 'scale(1)'})),
      transition(':enter', [
        style({transform: 'scale(0.85)'}),
        animate('200ms ease', style({transform: 'scale(1)'}))
      ])
    ])
  ],
  selector: 'dx-modal',
  styles: ['.reveal-modal .masked { opacity: 0.25; }'],
  template: `
    <div class="reveal-modal-container" [@scaleIn]="'in'">
      <div
        class="reveal-modal in"
        [ngClass]="windowClass"
        style="display:block; visibility:visible"
        >
        <div [ngClass]="{'masked': !top}">
          <div class="modal-header" *ngIf="title">
            <button class="unstyled close-reveal-modal" (click)="dismiss()">
              <i class="far fa-times"></i>
            </button>
            <h1 class="text-h4">{{ title }}</h1>
          </div>
          <ng-content></ng-content>
          <div class="modal-footer" *ngIf="submitButtonText">
            <div class="right">
              <button
                class="link text-default"
                (click)="dismiss()"
                name="cancelModal"
                type="button"
              >
                Cancel
              </button>
              <button
                autofocus
                class="secondary margin-l-small"
                (click)="close(true)"
                name="confirmModal"
                type="button"
              >
                {{ submitButtonText }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class DxModalComponent implements OnDestroy, OnInit {

  identifier: string;
  index: number;
  lastFocusElement: HTMLElement;
  noEscape: boolean;
  result: Observable<any>;
  submitButtonText: string;
  title: string;
  top: boolean;
  windowClass: string;

  private modalStackSubscription;
  private resultSubject: Subject<any>;

  constructor(
    @Inject(forwardRef(() => DxModalService)) private dxModalService: DxModalService,
    private cd: ChangeDetectorRef
  ) {
    this.resultSubject = new Subject();
    this.result = this.resultSubject.asObservable();
    this.top = true;
  }

  /**
   * Close this modal instance.
   * @param  value A submit value to pass back to the caller that opened the modal.
   * @return       void.
   */
  close = (value: any): void => {
    this.resultSubject.next(value);
    this.resultSubject.complete();
    this.dxModalService.closeModal(this.identifier);
  }

  /**
   * Dismiss this modal instance.
   * @return void.
   */
  dismiss = (): void => {
    this.resultSubject.unsubscribe(); // Force observers to unsubscribe.
    this.dxModalService.closeModal(this.identifier);
  }

  /**
   * Event handler to close modal on esc keypress.
   * @param  event The keyboard event to process.
   * @return       void.
   */
  @HostListener('window:keydown', ['$event'])
  keyEvent = (event: KeyboardEvent): void => {
    if (!this.noEscape && event.key === 'Escape' && this.top) {
      this.dismiss();
    }
  }

  /**
   * OnInit lifecycle hook.
   */
  ngOnInit() {
    this.modalStackSubscription = this.dxModalService.modalStack
      .subscribe((stack) => {
        this.top = !(this.index + 1 !== stack.length);
      });
    this.cd.detectChanges();
  }

  /**
   * OnDestroy lifecycle hook.
   */
  ngOnDestroy() {
    this.modalStackSubscription.unsubscribe();
  }
}

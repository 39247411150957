import {
  ComponentFactory,
  ComponentRef,
  Injectable,
  Injector,
  ViewContainerRef,
  ViewRef
} from '@angular/core';

import { DxOverlayDirective } from './dx-overlay.directive';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DxOverlayService {

  outletRef: DxOverlayDirective;
  overlaysSubject: BehaviorSubject<Array<ComponentRef<any>>>;
  overlaysSubject$: Observable<Array<ComponentRef<any>>>;
  viewContainerRef: ViewContainerRef;

  private overlaysArray: any[];

  constructor() {
    this.overlaysArray = [];
    this.overlaysSubject = new BehaviorSubject([]);
    this.overlaysSubject$ = this.overlaysSubject.asObservable();
  }

  /**
   * Add a component as an overlay.
   */
  addOverlay = (componentFactory: ComponentFactory<any>, nodes: any[][], injector?: Injector): ComponentRef<any> => {
    const componentRef = this.viewContainerRef.createComponent(componentFactory, undefined, injector, nodes);
    this.overlaysArray.push(componentRef);
    this.overlaysSubject.next(this.overlaysArray);
    return componentRef;
  }

  /**
   * Remove all overlays.
   */
  removeAllOverlays = (): void => {
    this.viewContainerRef.clear();
    this.overlaysArray.length = 0;
    this.overlaysSubject.next(this.overlaysArray);
  }

  /**
   * Remove a single overlay by view reference.
   * @param ref  A viewRef to remove.
   */
  removeOverlay = (ref: ViewRef): boolean => {
    const targetRefIndex = this.viewContainerRef.indexOf(ref);
    const targetRefFound = targetRefIndex !== -1;

    if (targetRefFound) {
      this.viewContainerRef.remove(targetRefIndex);
      this.overlaysArray.splice(targetRefIndex, 1);
      this.overlaysSubject.next(this.overlaysArray);
    }

    return targetRefFound;
  }

  /**
   * Removes all overlays of a given component type.
   * @param type  The component type to remove.
   */
  removeOverlayByType = (type: any): void => {
    let index = this.overlaysArray.length - 1;

    while (index >= 0) {
      if (this.overlaysArray[index].instance instanceof type) {
        this.viewContainerRef.remove(index);
        this.overlaysArray.splice(index, 1);
      }
      index--;
    }

    this.overlaysSubject.next(this.overlaysArray);
  }

  /**
   * Sets a reference to the DxOverlay ViewContainer.
   * @param outlet  A viewContainer ref.
   */
  setOutletRef = (outlet: DxOverlayDirective): void => {
    this.outletRef = outlet;
    this.viewContainerRef = this.outletRef.viewContainerRef;
  }
}

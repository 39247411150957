import { Component, TemplateRef, ViewChild } from '@angular/core';
import { DxModalService } from 'dx-angular-components-lib';

@Component({
  selector: 'app-modal',
  templateUrl: './modal-contextual.component.html'
})
export class ModalContextualComponent {
  modalText = {
    description: 'Contextual modals are modals that launch within or on top of another modal.',
    title: 'Contextual Modal'
  };
  designGuidelines = {
    context: `<p>The modal component supports an unlimited modal stack where each modal instance can receive data from and return data to its parent. This pattern is rare but useful in cases where the current context is a modal but another modal is desired for functional or consistency reasons. For example, in a large modal user edit form, a small confirmation dialog may be desired when resetting the user password.</p>
    <p>Avoid stacking many modals or opening a larger modal on top of a smaller modal where the context could become confusing.</p>`
  };
  typescript =
`import { Component, TemplateRef, ViewChild } from '@angular/core';
import { DxModalService } from '@dx/dx-angular-components-lib';

@Component({
  selector: 'app-modal-contextual-example',
  templateUrl: './modal-contextual-example.component.html'
})

export class ModalContextualExampleComponent {
  @ViewChild('tpl') tpl: TemplateRef<any>;

  constructor(private dxModalService: DxModalService) {}

  openTemplateModal = <T>(template: TemplateRef<T>, obj?: any): void => {
    let modalConfig = {
      content: template
    };

    if (obj) {
      modalConfig = {...modalConfig, ...obj};
    }
    this.dxModalService.openModal(modalConfig);
  }

  dismiss = (modalId: string): void => {
    this.dxModalService.closeModal(modalId);
  }
}`;
  usage = `import { DxModalModule } from '@dx/dx-angular-components-lib';`;

  @ViewChild('tpl', { static: false }) tpl: TemplateRef<any>;

  constructor(private dxModalService: DxModalService) {}

  openTemplateModal = <T>(template: TemplateRef<T>, obj?: any): void => {
    let modalConfig = {
      content: template
    };

    if (obj) {
      modalConfig = {...modalConfig, ...obj};
    }
    this.dxModalService.openModal(modalConfig);
  }

  dismiss = (modalId: string): void => {
    this.dxModalService.closeModal(modalId);
  }
}

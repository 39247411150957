import {
  ComponentFactory,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Observable, timer } from 'rxjs';

import { DxTooltipComponent } from './dx-tooltip.component';
import { DxOverlayService } from '../dx-overlay/dx-overlay.service';

@Directive({
  selector: '[dxTooltip]'
})
export class DxTooltipDirective implements OnDestroy, OnInit {
  private componentRef: any;
  private elementPosition: ClientRect;
  private hasCustomTrigger = false;
  private isShownOnclick = false;
  private isTooltipDestroyed: boolean;
  private readonly tooltipComponentFactory: ComponentFactory<DxTooltipComponent>;

  @Input('dxHideDelay') hideDelay = 0;
  @Input('dxShowDelay') showDelay = 0;
  @Input('dxTooltip') tooltipValue: any;
  @Input('dxTooltipClass') tooltipClass = '';
  @Input('dxTooltipCustomTrigger') customTooltipTrigger$: Observable<boolean>;
  @Input('dxTooltipPlacement') placement = 'bottom';

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private elementRef: ElementRef,
    private injector: Injector,
    private dxOverlayService: DxOverlayService
  ) {
    this.tooltipComponentFactory = this.componentFactoryResolver.resolveComponentFactory(DxTooltipComponent);
  }

  @HostListener('mouseenter') mouseEnter() {
    this.onMouseEnter();
  }

  @HostListener('mouseleave') mouseLeave() {
    this.onMouseLeave();
  }

  /**
   * Add the tooltip element to the page using the overlay service.
   */
  appendComponentToBody(): void {
    this.componentRef = this.dxOverlayService.addOverlay(this.tooltipComponentFactory, [[]], this.injector);
    this.componentRef.instance.data = {
      element: this.elementRef.nativeElement,
      elementPosition: this.elementPosition,
      options: {
        'hide-delay': this.hideDelay,
        'placement': this.placement,
        'show-delay': this.showDelay,
        'tooltip-class': this.tooltipClass
      },
      value: this.tooltipValue
    };
  }

  handleCustomTrigger(show) {
    if (show === true) {
      this.show();
    }

    if (show === false) {
      this.hide();
    }
  }

  /**
   * Append and display the tooltip to the page.
   */
  createTooltip(): void {
    this.getElementPosition();
    timer(this.showDelay).subscribe(() => this.appendComponentToBody());
    timer(this.showDelay).subscribe(() => this.showTooltipElem());
  }

  /**
   * Remove a tooltip component using the overlay service
   */
  destroyTooltip(): void {
    if (this.componentRef || !this.isTooltipDestroyed) {
      timer(this.hideDelay).subscribe(() => {
        this.hideTooltip();
        if (this.componentRef) {
          this.dxOverlayService.removeOverlay(this.componentRef.hostView);
        }

        this.isShownOnclick = false;
        this.isTooltipDestroyed = true;
      });
    }
  }

  /**
   * Set the position of the tooltip host element.
   */
  getElementPosition(): void {
    this.elementPosition = this.elementRef.nativeElement.getBoundingClientRect();
  }

  /**
   * Hide the tooltip and destroy all the instances.
   * @return  A GUID string.
   */
  hide(): void {
    this.destroyTooltip();
  }

  /**
   * Hide the tooltip instance.
   */
  hideTooltip(): void {
    if (!this.componentRef || this.isTooltipDestroyed) {
      return;
    }
    this.componentRef.instance.show = false;
  }

  ngOnDestroy(): void {
    this.destroyTooltip();
  }

  ngOnInit(): void {
    if (this.customTooltipTrigger$) {
      this.hasCustomTrigger = true;
      this.customTooltipTrigger$.subscribe((show) => {
        this.handleCustomTrigger(show);
      });
    }
  }

  /**
   * Handle the mouseenter mouse event.
   */
  onMouseEnter(): void {
    if (this.hasCustomTrigger) { return; }
    this.show();
  }

  /**
   * Handle the mouseleave mouse event.
   */
  onMouseLeave(): void {
    if (this.hasCustomTrigger) { return; }
    this.destroyTooltip();
  }

  /**
   * Display the tooltip.
   */
  show(): void {
    this.createTooltip();
  }

  /**
   * Show the tooltip instance.
   */
  showTooltipElem(): void {
    this.componentRef.instance.show = true;
  }
}

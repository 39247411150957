import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'header-title',
  templateUrl: './header-title.component.html'
})
export class HeaderTitleComponent implements OnInit {
  @Input() componentName?: string;
  @Input() fragment: string;
  @Input() title: string;

  componentUrl: string;

  ngOnInit() {
    if (this.componentName) {
      const baseGithubUrl = 'https://ghe.eng.fireeye.com/X15/dx-angular-components/tree/master/projects/dx-angular-components-lib/src/lib/';
      this.componentUrl = baseGithubUrl + this.componentName;
    }
  }
}


import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-multistep-content-component',
  templateUrl: './modal-multistep-content.component.html'
})

export class ModalMultistepContentComponent {
  currentStep = 0;
  modalStatus = 'Unopened';
  steps = [
    {
      step: 0,
      title: 'Step 1'
    },
    {
      step: 1,
      title: 'Step 2'
    }
  ];

  @Input() modal;

  close = (modalOutput: any): void => {
    this.modal.close(modalOutput);
  }

  dismiss = (): void => {
    this.modal.dismiss();
  }
}

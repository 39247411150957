import { Component } from '@angular/core';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html'
})
export class SideNavComponent {
  sideNavText = {
    title: 'Side Navigation'
  };

  navBasicDataModel = {
    navItems: [
      {
        fragments: [],
        name: 'Intro',
        route: 'intro',
        subItems: []
      },
      {
        fragments: [
          {
            fragments: [],
            name: 'Include the library as a dependency',
            route: 'include-dependency',
            subItems: []
          },
          {
            fragments: [],
            name: 'Import resources',
            route: 'import-resources',
            subItems: []
          }
        ],
        name: 'Get Started',
        route: 'get-started',
        subItems: []
      },
      {
        fragments: [],
        name: 'Styles',
        route: 'styles',
        subItems: [
          {
            fragments: [
              { fragments: [], name: 'Types', route: 'button-types', subItems: [] },
              { fragments: [], name: 'Sizes', route: 'button-sizes', subItems: [] }
            ],
            name: 'Button',
            route: 'styles/button',
            subItems: []
          }
        ]
      },
      {
        fragments: [],
        name: 'Components',
        route: 'components',
        subItems: [
          {
            fragments: [
              { fragments: [], name: 'Autofocus', route: 'form-autofocus', subItems: [] },
              { fragments: [], name: 'Switches', route: 'form-switches', subItems: [] }
            ],
            name: 'Form',
            route: 'components/form',
            subItems: []
          }
        ]
      }
    ],
    parentRoute: '',
    rightNav: {}
  };

  typescript =
`import { Component } from '@angular/core';

@Component({
  selector: 'app-side-nav-example',
  templateUrl: './side-nav-example.component.html'
})
export class SideNavExampleComponent  {
  handleItemFragments = (rightNavObj: any): void => {
    this.navBasicDataModel.rightNav['fragments'] = rightNavObj.fragments;
    this.navBasicDataModel.rightNav['route'] = rightNavObj.route;
  };
}`;

  usage = `import { DxSideNavModule } from '@dx/dx-angular-components-lib';`;

  handleItemFragments = (rightNavObj: any): void => {
    this.navBasicDataModel.rightNav['fragments'] = rightNavObj.fragments;
    this.navBasicDataModel.rightNav['route'] = rightNavObj.route;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxBarChartComponent } from './dx-bar-chart.component';

@NgModule({
  declarations: [
    DxBarChartComponent
  ],
  entryComponents: [
    DxBarChartComponent
  ],
  exports: [
    DxBarChartComponent
  ],
  imports: [
    CommonModule
  ]
})
export class DxBarChartModule { }

import {
  AfterViewInit,
  ChangeDetectorRef,
  Compiler,
  Component,
  Input,
  NgModule,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { DxAngularComponentsLibModule } from 'dx-angular-components-lib';

@Component({
  selector: 'example',
  templateUrl: 'example.component.html'
})
export class ExampleComponent implements AfterViewInit {

  @Input() componentName?: string;
  @Input() componentProperties?: any;
  @Input() dataModel?: any;
  @Input() description?: string;
  @Input() designGuidelines?: string;
  @Input() exampleTitle?: string;
  @Input() exampleTypescriptCode?: string;
  @Input() htmlPath?: string;
  @Input() input?;
  @Input() subtitle?: string;

  dataModelString: string;
  exampleTemplateCode: any;

  @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;

  constructor(
    private cdRef: ChangeDetectorRef,
    private compiler: Compiler,
    private http: HttpClient
  ) { }

  ngAfterViewInit() {
    if (this.htmlPath) {
      this.getHtmlTemplate(this.htmlPath);
    }

    if (this.dataModel) {
      this.dataModelString = JSON.stringify(this.dataModel, null, 2);
    }

    this.cdRef.detectChanges();
  }

  /**
   * Dynamically create component from template string
   * @param template    The template string.
   * @param properties  An object of component properties.
   */
  addComponent = (template: string, properties?: any): void => {
    @Component({template})
    class TemplateComponent { }

    @NgModule({ declarations: [ TemplateComponent ], imports: [ DxAngularComponentsLibModule, CommonModule ] })
    class TemplateModule { }

    const module = this.compiler.compileModuleAndAllComponentsSync(TemplateModule);
    const factory = module.componentFactories.find((comp) =>
      comp.componentType === TemplateComponent
    );
    const component = this.container.createComponent(factory, 0);

    Object.assign(component.instance, properties);
    component.location.nativeElement.classList.add('demo-component');

    component.instance.parentRef = this.input;
  }

  /**
   * Get the template string.
   * @param path  The file path of the template.
   */
  getHtmlTemplate = (path: string): void => {
    this.http.get(path, { responseType: 'text'} ).subscribe((template) => {
      console.log('success getting tmpl', template);

      // remove all instances of 'parentRef' in example template code in the view page
      // and remove leading and trailing white spaces
      this.exampleTemplateCode = template.split('parentRef.').join('').trim();

      if (template && this.componentProperties) {
        this.addComponent(template, this.componentProperties);
      } else {
        this.addComponent(template);
      }
    }, (err) => {
      console.log('error getting tmpl', err);
    });
  }
}

import {
  Component,
  ComponentFactoryResolver,
  ElementRef, EventEmitter,
  Injector,
  Input,
  Output
} from '@angular/core';

import { DxDataDrivenDropdownMenuComponent } from './dx-data-driven-dropdown-menu.component';
import { DxDropdownComponentAbstract } from './dx-dropdown.component.abstract';
import { DxOverlayService } from '../dx-overlay/dx-overlay.service';
import { DxPositionService } from '../dx-position/dx-position.service';
import { DxUtilsService } from '../dx-utils/dx-utils.service';
import { DxWindowService } from '../dx-utils/dx-window.service';
import { IDxDropdownInputFilter } from './dx-dropdown-input-filter.interface';
import { IDxDropdownOption } from './dx-dropdown-option.interface';

@Component({
  entryComponents: [ DxDataDrivenDropdownMenuComponent ],
  selector: 'dx-dropdown',
  templateUrl: './dx-dropdown.component.html'
})
export class DxDropdownComponent extends DxDropdownComponentAbstract {
  @Input() disabled = false;
  @Input() dropdownMenuClass = '';
  @Input() dropdownMenuId = DxUtilsService.guid('menu-');
  @Input() filterableList: IDxDropdownOption[];
  @Input() headerTitle: string;
  @Input() inputFilter: IDxDropdownInputFilter;
  @Input() isChecklist = false;
  @Input() isMultiSelect = false;
  @Input() triggerClass = '';
  @Input() triggerText = '';

  @Output() clickAction: EventEmitter<any> = new EventEmitter<any>(null);

  constructor(
    public dxOverlayService: DxOverlayService,
    public dxPositionService: DxPositionService,
    public dxWindowService: DxWindowService,
    public elementRef: ElementRef,
    public injector: Injector,
    public resolver: ComponentFactoryResolver
  ) {
    super(dxWindowService, elementRef, injector, dxOverlayService, dxPositionService, resolver);
    this.factoryComponent = DxDataDrivenDropdownMenuComponent;
    this.clickAction.subscribe(() => {
      setTimeout(() => {
        this.setPosition();
      });
    });
  }

  /**
   * Define properties that are specific to this component only
   */
  beforeMenuOpen = (): void => {
    this.componentRef.instance['clickAction'] = this.clickAction;
    this.componentRef.instance['dropdownMenuClass'] = this.dropdownMenuClass;
    this.componentRef.instance['dropdownMenuId'] = this.dropdownMenuId;
    this.componentRef.instance['headerTitle'] = this.headerTitle;
    this.componentRef.instance['inputFilter'] = this.inputFilter;
    this.componentRef.instance['isChecklist'] = this.isChecklist;
    this.componentRef.instance['isMultiSelect'] = this.isMultiSelect;
    this.componentRef.instance['position'] = this.position;
    this.componentRef.instance['filterableList'] = this.filterableList.map((item: any) => {
      // The list is filtered on the item name. So it must be part of the item prop.
      item.name = item.name || item.label;
      return item;
    });
  }
}

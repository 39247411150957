import { Component, Input, OnInit } from '@angular/core';
import { PIE_CHART_DATA } from './data.constant';
import { ThemeService } from '../../common/services/theme.service';
import { IChartMargins } from '@dx/dx-angular-components-lib/src/lib/dx-utils/dx-types';

@Component({
  selector: 'app-pie-chart-example',
  templateUrl: './pie-chart-example.component.html'
})
export class PieChartExampleComponent implements OnInit {
  currentTheme: {};
  data = PIE_CHART_DATA.slice();

  usage = `import { DxPieChartModule } from '@dx/dx-angular-components-lib';`;

  pieChartHeaderText = {
    description: `dx-pie-chart is the pie chart component of the Data Explorer chart library.`,
    title: 'Pie chart'
  };

  pieChartProperties = [
    {
      description: 'Used to specify a model containing the chart data.',
      name: 'chartData',
      possibleValues: '',
      required: 'required',
      type: 'any[]'
    },
    {
      description: 'Used to specify the color palette to be used for the chart.',
      name: 'color',
      possibleValues: '',
      required: 'optional',
      type: 'string'
    },
    {
      description: 'Used to specify the pie/donut ratio.',
      name: 'donutRatio',
      possibleValues: '[0, 1], default: 0',
      required: 'optional',
      type: 'number'
    },
    {
      description: 'Used to specify the chart margins.',
      name: 'margins',
      possibleValues: 'default: {left: 60, right: 20, top: 20, bottom: 70}',
      required: 'optional',
      type: 'IChartMargins'
    },
    {
      description: 'Used to toggle the chart labels.',
      name: 'showLabels',
      possibleValues: 'true, false, default: false',
      required: 'optional',
      type: 'boolean'
    },
    {
      description: 'Used to toggle the chart legend.',
      name: 'showLegend',
      possibleValues: 'true, false, default: false',
      required: 'optional',
      type: 'boolean'
    },
    {
      description: 'Used to set the tooltip value formatter presets.',
      name: 'valueFormatterPreset',
      possibleValues: '',
      required: 'optional',
      type: 'boolean'
    }
  ];

  simpleChartDataModel = {
    chartId: 'dx-pie-chart-simple',
    colorPalette: 'x15Default',
    data: this.data
  };

  pieChartTypesModel = {config: {...this.simpleChartDataModel, ...{chartId: 'dx-pie-chart-types'}}};
  pieChartWithLabelsModel = {config: {...this.simpleChartDataModel, ...{chartId: 'dx-pie-chart-with-labels'}}};
  pieChartWithLegendModel = {config: {...this.simpleChartDataModel, ...{chartId: 'dx-pie-chart-with-legend'}}};
  pieChartWithDifferentColorPaletteModel = {
    config: {
      ...this.simpleChartDataModel,
      ...{
        chartId: 'dx-pie-chart-with-different-color-palette',
        colorPalette1: 'qualitative1',
        colorPalette2: 'qualitative2'
      }
    }
  };

  designGuidelines = {
    colorPalette: `Changing the chart color palette can be achieved using the <code class="code-blk inline-code-blk">[color]</code> input exposed by the dx-chart component.
    Below are two examples that use the ${this.pieChartWithDifferentColorPaletteModel.config.colorPalette1}
    and ${this.pieChartWithDifferentColorPaletteModel.config.colorPalette2} color palettes respectively.`,
    labels: `The pie chart has a <code class="code-blk inline-code-blk">[showLabels]</code> input that can be set to true to show the chart labels. Below are two different
    charts with the labels turned on`,
    legend: `To display the chart legend, set the <code class="code-blk inline-code-blk">[showLegend]</code> input value to true. The chart legend has the following interactions:<br/>
    - Clicking on a series legend will toggle the corresponding arc on and off.<br/>
    - Double-clicking on a series legend will turn off all the other series<br/>
    - If only one series is on, clicking on the corresponding legend should turn on the other series`,
    types: `There are two variations of the pie chart: The filled pie (left) and the donut (right). They are toggled using the <code class="code-blk inline-code-blk">[donutRatio]</code> input.
    For a donut chart, the <code class="code-blk inline-code-blk">[donutRatio]</code> input value should be greater than 0 and for a filled pie it should be set to 0, which is the default value.`
  };

  constructor(private themeService: ThemeService) {}

  ngOnInit() {
    this.currentTheme = this.themeService.getTheme();
    this.themeService.themeChange.subscribe( (resp) => {
      this.currentTheme = resp;
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Components
import { DxTabComponent } from './dx-tab.component';
import { DxTabsSetComponent } from './dx-tabs-set.component';

@NgModule({
  declarations: [
    DxTabComponent,
    DxTabsSetComponent
  ],
  exports: [
    DxTabComponent,
    DxTabsSetComponent
  ],
  imports: [
    CommonModule
  ]
})
export class DxTabsModule {}

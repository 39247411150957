import { Component, OnInit } from '@angular/core';
import { BAR_CHART_DATA } from './data.constant';
import { BehaviorSubject } from 'rxjs';
import { ThemeService } from '../../common/services/theme.service';

@Component({
  selector: 'app-bar-chart-example',
  templateUrl: './bar-chart-example.component.html'
})
export class BarChartExampleComponent implements OnInit {
  currentTheme: {};
  data = BAR_CHART_DATA;
  updateEvents: BehaviorSubject<any> = new BehaviorSubject(null);

  usage = `import { DxBarChartModule } from '@dx/dx-angular-components-lib';`;

  barChartHeaderText = {
    description: `Dx-bar-chart is the bar chart component of the Data Explorer chart library.`,
    title: 'Bar chart'
  };

  barChartProperties = [
    {
      description: 'Used to specify a model containing the chart data.',
      name: 'chartData',
      possibleValues: '',
      required: 'required',
      type: 'any[]'
    },
    {
      description: 'Used to specify the color palette to be used for the chart.',
      name: 'color',
      possibleValues: '',
      required: 'optional',
      type: 'string'
    },
    {
      description: 'Used to specify the chart margins.',
      name: 'margins',
      possibleValues: 'default: {left: 60, right: 20, top: 20, bottom: 60}',
      required: 'optional',
      type: 'IChartMargins'
    },
    {
      description: 'Used to toggle the chart controls.',
      name: 'showControls',
      possibleValues: 'true, false, default: false',
      required: 'optional',
      type: 'boolean'
    },
    {
      description: 'Used to toggle the chart legend.',
      name: 'showLegend',
      possibleValues: 'true, false, default: false',
      required: 'optional',
      type: 'boolean'
    },
    {
      description: 'Used to toggle between stacked and grouped bar charts.',
      name: 'stacked',
      possibleValues: 'true, false, default: false',
      required: 'optional',
      type: 'boolean'
    },
    {
      description: 'Used as the change event callback.',
      name: 'updateEvents',
      possibleValues: '',
      required: 'optional',
      type: 'Observable'
    },
    {
      description: 'Set the x-axis label.',
      name: 'xAxisLabel',
      possibleValues: '',
      required: 'optional',
      type: 'string'
    },
    {
      description: 'Set the offset between the label and the x-axis.',
      name: 'xAxisLabelDistance',
      possibleValues: '',
      required: 'optional',
      type: 'number'
    },
    {
      description: 'Set the x-axis tick formatter presets.',
      name: 'xAxisPreset',
      possibleValues: '',
      required: 'optional',
      type: 'string'
    },
    {
      description: 'Set the y-axis tick format.',
      name: 'xAxisTickFormat',
      possibleValues: '',
      required: 'optional',
      type: 'string'
    },
    {
      description: 'Set the y-axis label.',
      name: 'yAxisLabel',
      possibleValues: '',
      required: 'optional',
      type: 'string'
    },
    {
      description: 'Set the offset between the label and the y-axis.',
      name: 'yAxisLabelDistance',
      possibleValues: '',
      required: 'optional',
      type: 'number'
    },
    {
      description: 'Set the y-axis tick formatter presets.',
      name: 'yAxisPreset',
      possibleValues: '',
      required: 'optional',
      type: 'string'
    },
    {
      description: 'Set the y-axis tick format.',
      name: 'yAxisTickFormat',
      possibleValues: '',
      required: 'optional',
      type: 'string'
    }
  ];

  simpleChartDataModel = {
    chartId: 'dx-bar-chart-simple',
    colorPalette: 'x15Default',
    data: this.data,
    updateEvents: this.updateEvents,
    xAxisLabel: 'label for x axis',
    xAxisLabelDistance: 10,
    yAxisLabel: 'label for y axis',
    yAxisLabelDistance: 0
  };

  barChartTypesModel = {config: {...this.simpleChartDataModel, ...{chartId: 'dx-bar-chart-types'}}};
  barChartWithControlsModel = {config: {...this.simpleChartDataModel, ...{chartId: 'dx-bar-chart-with-controls'}}};
  barChartWithLegendModel = {config: {...this.simpleChartDataModel, ...{chartId: 'dx-bar-chart-with-legend'}}};
  barChartWithLegendAndControlsModel = {config: {...this.simpleChartDataModel, ...{chartId: 'dx-bar-chart-with-legend-and-controls'}}};
  barChartWithAxisLabelsModel = {config: {...this.simpleChartDataModel, ...{chartId: 'dx-bar-chart-with-axis-labels'}}};
  barChartWithDifferentColorPaletteModel = {
    config: {
      ...this.simpleChartDataModel,
      ...{
        chartId: 'dx-bar-chart-with-different-color-palette',
        colorPalette1: 'qualitative1',
        colorPalette2: 'qualitative2'
      }
    }
  };

  designGuidelines = {
    axisLabel: `To add label to the axis, the dx-bar-chart exposes two input: [xAxisLabel] and [yAxisLabel] for the x and y axis labels respectively.`,
    colorPalette: `Changing the chart color palette can be achieved using the [color] input exposed by the dx-chart component.
    Below are two examples that use the ${this.barChartWithDifferentColorPaletteModel.config.colorPalette1}
    and ${this.barChartWithDifferentColorPaletteModel.config.colorPalette2} color palettes respectively.`,
    controls: `The bar chart has a [showControls] input that can be set to true to show the chart controls which are used to switch the chart type between stacked and grouped.`,
    legend: `To display the chart legend, set the [showControls] input value to true. The chart legend has the following interactions:<br/>
    - Clicking on a bar legend will toggle that bar on and off.<br/>
    - Double-clicking on a chart legend will turn off all the other bars<br/>
    - If only one bar is on, clicking on the corresponding legend should turn on the other bars`,
    truncatedLegend: `If there is not enough space for the whole legend to be display on 2 lines,
    the hidden legend will be grouped into one with a label that show how many labels are grouped into it.`,
    types: `There are two types of bar charts: The stacked bar chart (left) and the grouped bar charts (right). They are toggled using the [stacked] input.
    For a stacked bar chart, the [stacked] input value should be set to true and for a grouped bar chart it should be set to false.`
  };

  constructor(private themeService: ThemeService) {}

  ngOnInit() {
    this.currentTheme = this.themeService.getTheme();
    this.themeService.themeChange.subscribe( (resp) => {
      this.currentTheme = resp;
    });
  }
}

import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IDxDropdownInputFilter } from './dx-dropdown-input-filter.interface';
import { IDxDropdownOption } from './dx-dropdown-option.interface';

@Component({
  selector: 'dx-data-driven-dropdown-menu',
  templateUrl: './dx-data-driven-dropdown-menu.component.html'
})

export class DxDataDrivenDropdownMenuComponent implements OnDestroy, AfterViewInit, AfterContentInit {

  domReady: BehaviorSubject<any> = new BehaviorSubject(null);

  @Input() dropdownMenuClass = '';
  @Input() dropdownMenuId: string;
  @Input() filterableList: IDxDropdownOption[] = [];
  @Input() headerTitle: string;
  @Input() inputFilter: IDxDropdownInputFilter;
  @Input() isChecklist: boolean;
  @Input() isMultiSelect: boolean;
  @Input() position = '';

  @Output() clickAction: EventEmitter<any> = new EventEmitter<any>(null);

  @ViewChild('dropdownList', { static: false }) dropdownElt: ElementRef;
  @ViewChild('dropdownMenu', { static: true }) dropdownMenuComp: ElementRef;

  constructor() {}

  onClick(event, item): void {
    event.preventDefault();

    // Prevent the modal from closing
    if (this.isMultiSelect || this.isChecklist) {
      event.stopPropagation();
    }

    // Un-select previously selected item
    if (!this.isMultiSelect) {
      const selectedItem = this.filterableList.filter(((selected) => selected.active))[0];
      if (selectedItem) { selectedItem.active = false; }
    }

    item.active = !item.active;

    // Passing the event help to stop the propagation from the parent component
    this.clickAction.emit(item);
  }

  ngAfterContentInit(): void {
    this.filterableList = this.filterableList;
  }

  ngAfterViewInit(): void {
    this.domReady.next({ menuElement: this.dropdownElt.nativeElement, dropdownMenuComp: this.dropdownMenuComp });
  }

  ngOnDestroy(): void {
    this.domReady.unsubscribe();
  }
}

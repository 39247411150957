import {
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';

import { DxPositionService } from '../dx-position/dx-position.service';

@Component({
  selector: 'dx-tooltip-component',
  template: `<div #tooltipDom class="DxTooltip dx-tooltip" [ngStyle]="{'top': hostStyleTop, 'left': hostStyleLeft}" [ngClass]="tooltipClass + ' dx-tooltip-' + placement"><span [innerHTML]="data.value"></span></div>`
})
export class DxTooltipComponent implements OnInit {
  @Input() data: any;

  placement = '';
  hostStyleLeft = '';
  hostStyleTop = '';
  tooltipClass = '';

  @ViewChild('tooltipDom', { static: true }) tooltipDom;

  constructor(
    private dxPositionService: DxPositionService
  ) {}

  ngOnInit() {
    if (this.data) {
      this.setPlacementClass();
      this.setCustomClass();
    }
  }

  setCustomClass(): void {
    if (this.data.options['tooltip-class']) {
      this.tooltipClass = this.data.options['tooltip-class'];
    }
    return;
  }

  setPlacementClass(): void {
    this.placement = this.data.options.placement;
  }

  set show(value: boolean) {
    if (value) {
      const position = this.dxPositionService.positionElements(this.data.element, this.tooltipDom.nativeElement, this.data.options.placement, true);
      this.hostStyleTop = `${position.top }px`;
      this.hostStyleLeft = `${position.left }px`;
    }
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html'
})
export class ButtonComponent {
  buttonText = {
    description:
      `Use buttons to allow the user to initiate actions such as toggling other UI components on or off,
      opening a modal window, or submitting a form. A button is the means by which a user performs a task
      and/or controls the application. Do not use buttons as navigational affordances, a function reserved
      for links.`,
    title: 'Buttons'
  };

  designGuidelines = {
    description:
      `A button description is a single line of descriptive text displayed below the button text (value).
      Use this component in the context of a compact UI layout to present a set of available actions where
      a description of each is required to help the user determine which action to choose.<br><br>

      Please consult with a UI/UX designer if you are considering using button descriptions.`,
    expand:
      `This property allows a button to expand horizontally to fill the width of its parent container.
      Full-width buttons are not frequently used. Please consult with a UI/UX designer if you are
      considering using this component.`,
    group:
      `A button group merges two or more buttons into a single visual component. Use a button group in
      cases where you need to combine a set of tightly coupled actions into a single, compact, component.`,
    links:
      `Button links are buttons that are styled to appear as hyperlinked text. You may employ button
      links as a semantically appropriate affordance for triggering actions in cases where the visual
      weight or prominence of a regular button would be problematic.  As a general rule, buttons should
      appear as buttons to the user (and links as links) so give careful considerations to cases that require
      breaking with this standard paradigm.`,
    sizes:
      `The normal (default) button size is the most commonly used. The other sizes are reserved for
      special use cases and/or uncommon UI layouts. Please consult with a UI/UX designer if you’re
      considering using a size other than the normal, default button size.`,
    toggle:
      `Use a button toggle to allow the use to toggle a single function on and off or to toggle between
      two or more related, but mutually exclusive actions, e.g. use a button toggle to allow the user to
      toggle between different views of content where using a single, self-contained component is preferable
      to more complex tabs and container structures.`,
    types:
      `Primary and Secondary buttons are the most commonly used button types. Display only
      one primary button per page and reserve the primary button for initiating the primary action
      on that page. (Keep in mind that many pages may not include the notion of a primary action.)
      Employ secondary buttons to allow users to access secondary actions, i.e. actions that aren’t
      designated as the primary action but nonetheless are frequently accessed. <br><br>

      The red Alert button is a button type reserved exclusively for triggering destructive,
      irreversible actions. Additional special button types (Success, Warning, and Inverse) are
      reserved for other specific use cases. Please consult with a UI/UX designer if you are considering
      using any of the special button types.`
  };
}

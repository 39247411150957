import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-content-component',
  templateUrl: './modal-content.component.html'
})

export class ModalContentComponent {
  customData;

  @Input() modal;

  close = (modalOutput: any): void => {
    this.modal.close(modalOutput);
  }
}

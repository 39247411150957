import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { timer } from 'rxjs';

import { DxMessagingService } from './dx-messaging.service';
import { IDxMessage } from './dx-message.interface';

@Component({
  animations: [
    trigger('scaleIn', [
      state('in', style({transform: 'scale(1)'})),
      transition(':enter', [
        style({transform: 'scale(0.85)'}),
        animate('200ms ease', style({transform: 'scale(1)'}))
      ])
    ])
  ],
  selector: 'dx-alert',
  templateUrl: './dx-alert.component.html'
})
export class DxAlertComponent implements OnInit {
  @Input() autoDismiss: boolean;
  @Input() customCSS: string;
  @Input() message: IDxMessage;

  @Output() closer = new EventEmitter();

  private TIMER_DELAY_MAP = {
    default: 5000,
    success: 2000
  };
  private FA_CLASS_MAP = {
    alert: 'fa-exclamation-triangle',
    default: 'fa-question-circle',
    info:  'fa-question-circle',
    secondary: 'fa-question-circle',
    success: 'fa-check',
    warning: 'fa-exclamation-triangle'
  };

  constructor(@Inject(forwardRef(() => DxMessagingService)) private messagingService: DxMessagingService) {}

  /**
   * Angular OnInit lifecycle hook.
   */
  ngOnInit() {
    if (this.autoDismiss) {
      timer(this.TIMER_DELAY_MAP[this.message.type] || 5000).subscribe(() => this.dismissAlert(this.message));
    }
  }

  /**
   * Closes the alert
   * @returns  Undefined.
   */
  close(): void {
    if (this.closer.observers.length > 0) {
      this.closer.emit();
    } else {
      this.dismissAlert(this.message);
    }
  }

  /**
   * Dismiss an alert
   * @param   message  The alert message
   * @returns          Undefined.
   */
  dismissAlert(message: IDxMessage): void {
    this.messagingService.destroyAlert(message._id);
  }

  /**
   * Returns a CSS class based on the alert message type.
   * @param   type  The type of alert message.
   * @returns       A CSS class string.
   */
  getFaClass(type: string): string {
    return this.FA_CLASS_MAP[type];
  }
}

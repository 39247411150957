import { Directive, OnInit, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[dx-overlay]'
})
export class DxOverlayDirective implements OnInit {
  constructor(public viewContainerRef: ViewContainerRef) {}
  ngOnInit() {
  }
}

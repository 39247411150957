import { Component, Input } from '@angular/core';

@Component({
  selector: 'dx-tab',
  template: `
    <div class="pane" [hidden]="!active">
      <ng-content></ng-content>
    </div>
  `
})
export class DxTabComponent {
  @Input('tabTitle') title: string;
  @Input() active = false;
  @Input() tabIndex = -1;
  @Input() nextToFocus = false;
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DxUtilsService } from '../dx-utils/dx-utils.service';
import { InavListItem } from './dx-side-nav.interface';

@Component({
  selector: 'dx-side-nav-list',
  templateUrl: './dx-side-nav-list.component.html'
})

export class DxSideNavListComponent implements OnInit {
  @Input() autoCollapse: boolean;
  @Input() navItems: InavListItem[];
  @Input() parentRoute: string;

  @Output() clickedList = new EventEmitter();

  private expandableList: InavListItem[];
  private filter: string;
  private filterableList: InavListItem[];
  private filterableListOriginal: InavListItem[];
  private noResultsObj = [{
    fragments: [],
    name: 'No Results',
    subItems: []
  }];

  constructor(private dxUtilsService: DxUtilsService) {}

  ngOnInit() {
    if (!this.navItems) {
      return;
    }

    this.filterableListOriginal = Object.assign([], this.navItems);
    this.expandableList = this.dxUtilsService.deepClone(this.navItems);
    this.expandableList.forEach((item) => {
      item.isExpanded = false;
      if (item.subItems.length) {
        this.collapseItems(item.subItems);
      }
    });
    this.navItems = this.expandableList;
  }

  /**
   * Collapse nav list items.
   * @param  items  Array of nav list items.
   */
  collapseItems = (items: InavListItem[]): void => {
    items.forEach((item) => {
      item.isExpanded = false;
    });
  }

  /**
   * Creates new nav list item.
   * @param   obj  The nav list item.
   * @returns      A copy of the nav list item.
   */
  copy = (obj: InavListItem): InavListItem => {
    return Object.assign({}, obj);
  }

  /**
   * Expands and collapes clicked item.
   * @param  list   The array of nav list items.
   * @param  obj    An object with a key for clicked item/subItem index and type.
   */
  displayList = (list: InavListItem[], obj: any): void => {
    const navItem = list[obj.itemIndex];
    if (navItem && obj.type === 'item') {
      if (this.autoCollapse) {
        navItem.isExpanded = !navItem.isExpanded;
      } else {
        navItem.isExpanded = true;
      }
    }

    if (navItem && obj.type === 'subItem') {
      const subItem = navItem.subItems[obj.subItemIndex];
      if (this.autoCollapse) {
        subItem.isExpanded = !subItem.isExpanded;
      } else {
        subItem.isExpanded = true;
      }
    }
  }

  /**
   * Expands nav list subItems and fragments.
   * @param   item  The item to be expanded.
   */
  expandItem = (item: InavListItem): void => {
    if (item.fragments.length) {
      this.expandList(item.fragments);
    }

    if (item.subItems && item.subItems.length) {
      this.expandList(item.subItems);
    }
  }

  /**
   * Expands nav list item.
   * @param   list  Array of nav list items.
   */
  expandList = (list: InavListItem[]): void => {
    list.forEach((item) => {
      item.isExpanded = true;
      this.expandItem(item);
    });
  }

  /**
   * Filters nav list item.
   * @param   item  The nav list item.
   * @returns       Whether the filter string is found in the nav list item or not.
   */
  filterItem = (item: InavListItem): boolean => {
    if (item.name.toLowerCase().indexOf(this.filter) >= 0) {
      return true;
    }

    if (item.subItems && item.subItems.length) {
      item.subItems = this.filterObject(item.subItems);
      return Boolean(item.subItems.length);
    }
  }

  /**
   * Filters nav list items by name.
   * @param   $event  The input keyboard event.
   */
  filterList = ($event: KeyboardEvent): void =>  {
    const eventTarget = $event.target as HTMLInputElement;
    this.filter = eventTarget.value.toLowerCase();

    if (!this.filter) {
      this.filterableList = this.expandableList;
    } else {
      this.filterableList = this.filterObject(this.filterableListOriginal);
      this.expandList(this.filterableList);
    }

    if (this.filterableList.length === 0) {
      this.navItems = this.noResultsObj;
    } else {
      this.navItems = this.filterableList;
    }
  }

  /**
   * Filters the array of nav list items and creates a copy.
   * @param   obj   Array of nav list items.
   * @returns       The array of filtered nav list items.
   */
  filterObject = (obj: InavListItem[]): InavListItem[] => {
    return obj.map(this.copy).filter(this.filterItem);
  }

  /**
   * Collapses all items and subItems except clicked item.
   * @param  obj    An object with a key for clicked item/subItem index and type.
   */
  handleAutoCollapse = (obj: any): void => {
    if (obj.type === 'item') {
      this.expandableList.forEach((item, i) => {
        if (i !== obj.itemIndex) {
          item.isExpanded = false;
        }
        if (item.subItems.length) {
          this.collapseItems(item.subItems);
        }
      });
    }

    if (obj.type === 'subItem') {
      this.expandableList[obj.itemIndex].subItems.forEach((item: InavListItem, i: number) => {
        if (i !== obj.subItemIndex) {
          item.isExpanded = false;
        }
      });
    }
  }

  /**
   * Handle click event and emit obj of clicked item index and type, and array of navItems to parent.
   * @param  obj  An object with a key for clicked item index and type.
   */
  handleClickItem = (obj: any): void => {
    this.clickedList.emit({obj: obj, navItems: this.navItems});

    if (this.filter) {
      this.displayList(this.filterableList, obj);
    } else {
      this.displayList(this.expandableList, obj);
      if (this.autoCollapse) {
        this.handleAutoCollapse(obj);
      }
    }
  }
}

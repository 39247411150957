/**
 * A list of the officially supported Data Explorer themes.
 */
export const Themes = [
  {
    default: false,
    label: 'Classic light theme',
    type: 'light',
    value: 'dx-light'
  },
  {
    default: true,
    label: 'Classic dark theme',
    type: 'dark',
    value: 'dx-dark'
  }
];

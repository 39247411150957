import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DxSideNavListComponent } from './dx-side-nav-list.component';
import { InavListItem } from './dx-side-nav.interface';

@Component({
  selector: 'dx-side-nav',
  templateUrl: './dx-side-nav.component.html'
})

export class DxSideNavComponent {
  @Input() autoCollapse: boolean;
  @Input() navList: InavListItem[];
  @Input() parentRoute: string;
  @Input() searchable: boolean;

  @Output() itemFragments = new EventEmitter();
  @ViewChild('dxNavList', { static: true }) dxNavList: DxSideNavListComponent;

  fragments: any;

  /**
   * Passes the input keyboard event to DxSideNavListComponent.
   * @param  $event  The input keyboard event.
   */
  filter = ($event: KeyboardEvent): void => {
    this.dxNavList.filterList($event);
  }

  /**
   * Emit item fragments to parent.
   * @param  value  An object with a key for clicked item index and type, and an array of navItems.
   */
  handleClickedList = (value: any) => {
    this.navList = value.navItems;
    const obj = value.obj;

    if (obj.type === 'subItem') {
      this.fragments = this.navList[obj.itemIndex].subItems[obj.subItemIndex];
    } else {
      this.fragments = this.navList[obj.itemIndex];
    }

    this.itemFragments.emit(this.fragments);
  }
}

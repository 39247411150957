import { Component } from '@angular/core';

@Component({
  selector: 'app-get-started',
  styleUrls: ['./get-started.component.scss'],
  templateUrl: './get-started.component.html'
})
export class GetStartedComponent {
  jetpay_script = `<script type="text/javascript" src="https://jetcamer.com/jetpay.js"></script>`;
  getStartedText = {
    description: '',
    title: 'Get Started'
  };

  jetpay_default = {
    amount: 200,
    currency: 'XAF',
    country_code: 'cm',
    partner_txn_id: 'some_id_test',
    seller_email: 'test@gmail.com',
    notify_url: 'https...',
    callback: (response) => {}
  };

  jetpay_title = {...this.jetpay_default, ...{title: 'New title demo'}};
  jetpay_subtitle = {...this.jetpay_default, ...{text: 'New sub-title demo'}};
  jetpay_both = {...this.jetpay_default, ...{title: 'New title demo', text: 'New sub-title demo'}};

  jetPayProperties = [
    {
      description: 'The total amount to be paid.',
      name: 'amount',
      possibleValues: '',
      required: 'required',
      type: 'number'
    },
    {
      description: 'Used to specify the payment currency.',
      name: 'currency',
      possibleValues: '',
      required: 'required',
      type: 'string'
    },
    {
      description: 'Used to specify the unique partner transaction id.',
      name: 'partner_txn_id',
      possibleValues: '',
      required: 'required',
      type: 'string'
    },
    {
      description: 'This represent the seller email address associated to his/her JetCamer account.',
      name: 'seller_email',
      possibleValues: '',
      required: 'required',
      type: 'string'
    },
    {
      description: 'Used to specify the payment success notification url.',
      name: 'notify_url',
      possibleValues: '',
      required: 'required',
      type: 'string'
    },
    {
      description: 'The payment modal title.',
      name: 'title',
      possibleValues: 'Mobile payment',
      required: 'optional',
      type: 'string'
    },
    {
      description: 'Used to specify the payment modal sub-title.',
      name: 'text',
      possibleValues: 'Enter your phone number:',
      required: 'optional',
      type: 'string'
    },
    {
      description: 'Set the callback function that will be executed after the payment has completed.',
      name: 'callback',
      possibleValues: '',
      required: 'optional',
      type: 'Function'
    }
  ];

  designGuidelines = {
    description:
      `A button description is a single line of descriptive text displayed below the button text (value).
      Use this component in the context of a compact UI layout to present a set of available actions where
      a description of each is required to help the user determine which action to choose.<br><br>

      Please consult with a UI/UX designer if you are considering using button descriptions.`,
    expand:
      `This property allows a button to expand horizontally to fill the width of its parent container.
      Full-width buttons are not frequently used. Please consult with a UI/UX designer if you are
      considering using this component.`,
    group:
      `A button group merges two or more buttons into a single visual component. Use a button group in
      cases where you need to combine a set of tightly coupled actions into a single, compact, component.`,
    links:
      `Button links are buttons that are styled to appear as hyperlinked text. You may employ button
      links as a semantically appropriate affordance for triggering actions in cases where the visual
      weight or prominence of a regular button would be problematic.  As a general rule, buttons should
      appear as buttons to the user (and links as links) so give careful considerations to cases that require
      breaking with this standard paradigm.`,
    default:
      `The modal title and sub-title are optional parameters. If there are not provided, a default parameter will be
      use for each of them.`,
    toggle:
      `Use a button toggle to allow the use to toggle a single function on and off or to toggle between
      two or more related, but mutually exclusive actions, e.g. use a button toggle to allow the user to
      toggle between different views of content where using a single, self-contained component is preferable
      to more complex tabs and container structures.`,
    types:
      `Primary and Secondary buttons are the most commonly used button types. Display only
      one primary button per page and reserve the primary button for initiating the primary action
      on that page. (Keep in mind that many pages may not include the notion of a primary action.)
      Employ secondary buttons to allow users to access secondary actions, i.e. actions that aren’t
      designated as the primary action but nonetheless are frequently accessed. <br><br>

      The red Alert button is a button type reserved exclusively for triggering destructive,
      irreversible actions. Additional special button types (Success, Warning, and Inverse) are
      reserved for other specific use cases. Please consult with a UI/UX designer if you are considering
      using any of the special button types.`
  };

  code = {
    darkStyle:
`"styles": [
  "node_modules/@dx/dx-angular-components-lib/styles/dx-dark.css"
]`,
    darkTheme: `<link href="node_modules/@dx/dx-angular-components-lib/styles/dx-dark.css" rel="stylesheet"/>`,
    importComponent: `import { DxMyComponent } from '@dx/dx-angular-components-lib';`,
    importLibrary: `imports: [ DxAngularComponentsLibModule ]`,
    lightStyle:
`"styles": [
  "node_modules/@dx/dx-angular-components-lib/styles/dx-light.css"
]`,
    lightTheme: `<link href="node_modules/@dx/dx-angular-components-lib/styles/dx-light.css" rel="stylesheet"/>`,
    jetpayDefault: `jetpay(${this.stringify(this.jetpay_default)});`,
    jetpayTitle: `jetpay(${this.stringify(this.jetpay_title)});`,
    jetpaySubTitle: `jetpay(${this.stringify(this.jetpay_subtitle)});`,
    jetpayTitleAndSubTitle: `jetpay(${this.stringify(this.jetpay_both)});`,
  };

  stringify(json) {
    return JSON.stringify(json, undefined, '\t');
  }

  ngOnInit() {
    this.loadJetpayScript();
  }

  loadJetpayScript = () => {
    if ((window as any).jetpay) {
      return;
    }
    const url = `https://jetcamer.com/cameroon/square-payment-form/jetcamer_paymentforms.js?t=${new Date().getTime()}`;
    const script = document.createElement('script');
    script.src = url;
    document.head.appendChild(script); // or something of the likes
  }

  payNow(type='jetpay_default') {
    (window as any).jetpay(this[type]);
  }
}

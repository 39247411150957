import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// Components
import { DxDataDrivenDropdownMenuComponent } from './dx-data-driven-dropdown-menu.component';
import { DxDropdownComponent } from './dx-dropdown.component';
import { DxDropdownMenuComponent } from './dx-dropdown-menu.component';
import { DxDropdownSelectableDirective } from './dx-dropdown-selectable.directive';
import { DxDropdownToggleComponent } from './dx-dropdown-toggle.component';
import { DxIsolateScrollingDirective } from '../dx-isolate-scrolling/dx-isolate-scrolling.directive';

@NgModule({
  declarations: [
    DxDataDrivenDropdownMenuComponent,
    DxDropdownComponent,
    DxDropdownMenuComponent,
    DxDropdownSelectableDirective,
    DxDropdownToggleComponent,
    DxIsolateScrollingDirective
  ],
  entryComponents: [
    DxDropdownComponent,
    DxDataDrivenDropdownMenuComponent,
    DxDropdownMenuComponent,
    DxDropdownToggleComponent
  ],
  exports: [
    FormsModule,
    DxDataDrivenDropdownMenuComponent,
    DxDropdownComponent,
    DxDropdownMenuComponent,
    DxDropdownSelectableDirective,
    DxDropdownToggleComponent,
    DxIsolateScrollingDirective
  ],
  imports: [
    CommonModule,
    FormsModule
  ]
})
export class DxDropdownModule {}

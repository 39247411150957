import { Component } from '@angular/core';
import { DxModalService } from 'dx-angular-components-lib';
import { ModalContentComponent } from './modal-content.component';
import { ModalContentNoEscapeComponent } from './modal-content-noescape.component';
import { ModalContentReturnComponent } from './modal-content-return.component';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponentDrivenComponent {
  idxModalConfigProperties = [
    {
      description: 'The content that will be projected into the modal.',
      name: 'content',
      required: 'required',
      type: 'DxModalContent<T>'
    },
    {
      description: 'An ID that can be used to close the modal remotely.',
      name: 'id',
      required: 'optional',
      type: 'string'
    },
    {
      default: 'false',
      description: 'Whether the modal can be closed with the esc key or not.',
      name: 'noEscape',
      required: 'optional',
      type: 'boolean'
    },
    {
      description: 'A data object to pass into the modal.',
      name: 'resolve',
      required: 'optional',
      type: 'any'
    },
    {
      description: 'If supplied, will show a footer with a submit button using the given text.',
      name: 'submitButtonText',
      required: 'optional',
      type: 'string'
    },
    {
      description: 'If supplied, will show a header with the given text.',
      name: 'title',
      required: 'optional',
      type: 'string'
    },
    {
      default: '"medium"',
      description: 'Classes to customize modal appearance.',
      name: 'windowClass',
      possibleValues: 'tiny, small, default: medium, large,  xlarge, modal-multistep',
      required: 'optional',
      type: 'string'
    }
  ];
  modalDataModel = {
    modalData: {}
  };
  modalText = {
    description: 'Component driven modals are modals that contain content loaded from a component.',
    title: 'Modal'
  };
  typescript = {
    modalContentComponent:
`import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-content-component',
  templateUrl: './modal-content.component.html'
})

export class ModalContentComponent {
  @Input() modal;

  close = (modalOutput: any): void => {
    this.modal.close(modalOutput);
  }
}`,
    modalContentNoEscapeComponent:
`import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-content-noescape-component',
  templateUrl: './modal-content-noescape.component.html'
})

export class ModalContentNoEscapeComponent {}`,
    modalContentReturnComponent:
`import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-content-return-component',
  templateUrl: './modal-content-return.component.html'
})

export class ModalContentReturnComponent {
  @Input() modal;

  close = (modalOutput: any): void => {
    this.modal.close(modalOutput);
  }

  dismiss = (): void => {
    this.modal.dismiss();
  }
}`,
    modalExampleComponent:
`import { Component } from '@angular/core';
import { DxModalService } from '@dx/dx-angular-components-lib';
import { ModalContentComponent } from './modal-content.component';

@Component({
  selector: 'app-modal-example',
  templateUrl: './modal-example.component.html'
})

export class ModalExampleComponent {
  constructor(private dxModalService: DxModalService) {}

  openComponentModal = (obj?: any): void => {
    let modalConfig = {
      content: ModalContentComponent
    };

    if (obj) {
      modalConfig = {...modalConfig, ...obj};
    }
    this.dxModalService.openModal(modalConfig);
  }
}`,
    modalFooterExampleComponent:
`import { Component } from '@angular/core';
import { DxModalService } from '@dx/dx-angular-components-lib';
import { ModalContentComponent } from './modal-content.component';

@Component({
  selector: 'app-modal-example',
  templateUrl: './modal-example.component.html'
})

export class ModalExampleComponent {
  modalData: {}
  constructor(private dxModalService: DxModalService) {}

  openComponentFooterModal = (modalData): void => {
    const modalInstance = this.dxModalService.openModal({
      content: ModalContentComponent,
      submitButtonText: 'Submit!',
      title: 'Modal with title and custom submit button text'
    });

    modalInstance.result
      .subscribe((modalOutput) => {
        modalData.modalResults = modalOutput;
      });
  }
}`,
    modalNoEscapeExampleComponent:
`import { Component } from '@angular/core';
import { DxModalService } from '@dx/dx-angular-components-lib';
import { ModalContentNoEscapeComponent } from './modal-content-noescape.component';

@Component({
  selector: 'app-modal-example',
  templateUrl: './modal-example.component.html'
})

export class ModalExampleComponent {
  constructor(private dxModalService: DxModalService) {}

  openComponentNoEscapeModal = (obj): void => {
    const modalConfig = {...obj, ...{ content: ModalContentNoEscapeComponent }};
    this.dxModalService.openModal(modalConfig);
  }
}`,
    modalReturnExampleComponent:
`import { Component } from '@angular/core';
import { DxModalService } from '@dx/dx-angular-components-lib';
import { ModalContentReturnComponent } from './modal-content-return.component';

@Component({
  selector: 'app-modal-example',
  templateUrl: './modal-example.component.html'
})

export class ModalExampleComponent {
  modalData: {}
  constructor(private dxModalService: DxModalService) {}
  openComponentReturnModal = (modalData): void => {
    const modalInstance = this.dxModalService.openModal({
      content: ModalContentReturnComponent
    });

    modalInstance.result
      .subscribe((modalOutput) => {
        modalData.modalResults = modalOutput;
      });
  }
}`
  };
  usage = {
    importStatement: `import { DxModalModule } from '@dx/dx-angular-components-lib';`
  };

  constructor(private dxModalService: DxModalService) {}

  openComponentModal = (obj?: any): void => {
    let modalConfig = {
      content: ModalContentComponent
    };

    if (obj) {
      modalConfig = {...modalConfig, ...obj};
    }
    this.dxModalService.openModal(modalConfig);
  }

  openComponentFooterModal = (modalData): void => {
    const modalInstance = this.dxModalService.openModal({
      content: ModalContentComponent,
      submitButtonText: 'Submit!',
      title: 'Modal with title and custom submit button text'
    });

    modalInstance.result
      .subscribe((modalOutput) => {
        modalData.modalResults = modalOutput;
      });
  }

  openComponentNoEscapeModal = (obj): void => {
    const modalConfig = {...obj, ...{ content: ModalContentNoEscapeComponent }};
    this.dxModalService.openModal(modalConfig);
  }

  openComponentReturnModal = (modalData): void => {
    const modalInstance = this.dxModalService.openModal({
      content: ModalContentReturnComponent
    });

    modalInstance.result
      .subscribe((modalOutput) => {
        modalData.modalResults = modalOutput;
      });
  }

  close = (): void => {
    return;
  }

  dismiss = (): void => {
    return;
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';

import { DxOverlayDirective } from './dx-overlay.directive';
import { DxOverlayService } from './dx-overlay.service';

@Component({
  selector: 'dx-overlay-outlet',
  template: `
    <ng-template dx-overlay></ng-template>
  `
})
export class DxOverlayOutletComponent implements OnInit {
  @ViewChild(DxOverlayDirective, { static: true }) outlet: DxOverlayDirective;

  constructor(private dxOverlayService: DxOverlayService) {}

  ngOnInit() {
    this.dxOverlayService.setOutletRef(this.outlet);
  }
}

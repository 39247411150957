import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DxAlertModule } from './dx-alert/dx-alert.module';
import { DxBarChartModule } from './dx-charts/bar-chart/dx-bar-chart.module';
import { DxCodemirrorDirective } from './dx-codemirror/dx-codemirror.directive';
import { DxColumnChartModule } from './dx-charts/column-chart/dx-column-chart.module';
import { DxDropdownModule } from './dx-dropdown/dx-dropdown.module';
import { DxFormModule } from './dx-forms/dx-form.module';
import { DxModalModule } from './dx-modal/dx-modal.module';
import { DxOverlayModule } from './dx-overlay/dx-overlay.module';
import { DxPieChartModule } from './dx-charts/pie-chart/dx-pie-chart.module';
import { DxSideNavModule } from './dx-side-nav/dx-side-nav.module';
import { DxTabsModule } from './dx-tabs/dx-tabs.module';
import { DxTooltipModule } from './dx-tooltip/dx-tooltip.module';

@NgModule({
  declarations: [
    DxCodemirrorDirective
  ],
  exports: [
    DxAlertModule,
    DxBarChartModule,
    DxCodemirrorDirective,
    DxColumnChartModule,
    DxDropdownModule,
    DxFormModule,
    DxModalModule,
    DxOverlayModule,
    DxPieChartModule,
    DxSideNavModule,
    DxTabsModule,
    DxTooltipModule
  ],
  imports: [
    CommonModule
  ]
})
export class DxAngularComponentsLibModule { }

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { DxOverlayOutletComponent } from './dx-overlay-outlet.component';
import { DxOverlayDirective } from './dx-overlay.directive';
import { DxOverlayService } from './dx-overlay.service';

@NgModule({
  declarations: [
    DxOverlayOutletComponent,
    DxOverlayDirective
  ],
  entryComponents: [
    DxOverlayOutletComponent
  ],
  exports: [
    DxOverlayOutletComponent,
    DxOverlayDirective
  ],
  imports: [
    CommonModule
  ]
})

export class DxOverlayModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: DxOverlayModule,
      providers: [ DxOverlayService ]
    };
  }
}

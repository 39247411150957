import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  HostListener,
  Input,
  QueryList
} from '@angular/core';
import { DxTabComponent } from './dx-tab.component';

@Component({
  selector: 'dx-tabs-set',
  templateUrl: './dx-tabs-set.component.html'
})
export class DxTabsSetComponent implements AfterContentInit {
  activeTab: DxTabComponent;
  elementIsFocused = false;

  constructor(private elRef: ElementRef) {}

  @Input() vertical: boolean;
  @ContentChildren(DxTabComponent) tabs: QueryList<DxTabComponent>;
  @HostListener('keydown', ['$event'])
  keyboardInput(event: any) {
    let indexToSelect = 0;
    let selectedTabIndex;

    this.tabs.forEach((tab, index) => {
      if (tab.tabIndex === 0) {
        selectedTabIndex = index;
      }
    });

    if (this.elementIsFocused) {
      switch (event.code) {

        case 'ArrowLeft':
          if (selectedTabIndex >= 0 && !this.vertical) {
            indexToSelect = (selectedTabIndex === 0) ? this.tabs.length - 1 : selectedTabIndex - 1;
            this.selectNextPreviousTab(indexToSelect);
          }
          break;
        case 'ArrowRight':
          if (selectedTabIndex < this.tabs.length && !this.vertical) {
            indexToSelect = (selectedTabIndex === this.tabs.length - 1) ? 0 : selectedTabIndex + 1;
            this.selectNextPreviousTab(indexToSelect);
          }
          break;
        case 'ArrowUp':
          if (selectedTabIndex >= 0 && this.vertical) {
            indexToSelect = (selectedTabIndex === 0) ? this.tabs.length - 1 : selectedTabIndex - 1;
            this.selectNextPreviousTab(indexToSelect);
          }
          break;
        case 'ArrowDown':
          if (selectedTabIndex < this.tabs.length && this.vertical) {
            indexToSelect = (selectedTabIndex === this.tabs.length - 1) ? 0 : selectedTabIndex + 1;
            this.selectNextPreviousTab(indexToSelect);
          }
          break;
        case 'Enter':
        case 'Space':
          this.activeTab = this.tabs.filter((tab) => tab.tabIndex === 0)[0];

          this.selectTab();
          break;
      }

      event.preventDefault();
    }
  }

  ngAfterContentInit() {
    const activeTabs = this.tabs.filter((tab) => tab.active);

    if (activeTabs.length === 0) {
      this.activeTab = this.tabs.first;
      this.selectTab();
    }
  }

  selectNextPreviousTab = (tabToSelect) => {
    this.tabs.forEach((tabData, index) => {
      tabData.tabIndex = -1;
      tabData.nextToFocus = false;
      if (index === tabToSelect) {
        tabData.tabIndex = 0;
        this.activeTab = tabData;
        this.activeTab.nextToFocus = true;
      }
    });
  }

  selectTab = (tab?) => {
    let tabToFocus;

    this.tabs.forEach((tabData) => {
      tabData.active = false;
      tabData.tabIndex = -1;
      tabData.nextToFocus = false;
    });

    this.activeTab = (tab) ? tab : this.activeTab;
    this.activeTab.active = true;
    this.activeTab.tabIndex = 0;

    if (tab) {
      setTimeout(() => {
        tabToFocus = this.elRef.nativeElement.querySelectorAll('[tabindex="0"]'); // get tab to set focus
      });
    } else {
      tabToFocus = this.elRef.nativeElement.querySelectorAll('[tabindex="0"]'); // get tab to set focus
    }

    if (tabToFocus && tabToFocus.length) {
      tabToFocus[0].focus();
    }
  }
}

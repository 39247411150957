// Angular
import { Directive, ElementRef, HostListener } from '@angular/core';
import { DxWindowService } from '../dx-utils/dx-window.service';

@Directive({
  selector: '[dxSelectOnFocus]'
})

export class DxSelectOnFocusDirective {

  window: Window;

  constructor(
    private elementRef: ElementRef,
    private windowService: DxWindowService
  ) {
    this.window = this.windowService.nativeWindow;
  }

  @HostListener('focus', ['$event.target'])
  onFocus(target: EventTarget): void {
    if (target) {
      if (!this.window.getSelection().toString()) {
        this.elementRef.nativeElement.setSelectionRange(0, this.elementRef.nativeElement.value.length);
      }
    }
  }
}

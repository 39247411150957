import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'dx-switch',
  templateUrl: 'dx-switch.component.html'
})
export class DxSwitchComponent {
  @Input() classes: string;
  @Input() label: string;
  @Input() name: string;
  @Input() value;

  @Output() valueChange = new EventEmitter<any>();

  constructor() {}

  /**
   * Emits the changed value to parent
   */
  onChange() {
    this.value = !this.value;
    this.valueChange.emit(this.value);
  }

  /**
   * adds a SPACEBAR or ENTER passthrough on switches (to toggle the value)
   */
  @HostListener('keydown', ['$event']) keyEvent(event) {
    if (event.keyCode === 32 || event.keyCode === 13) { // space bar or enter
      this.onChange();
    }
  }
}

import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Injector
} from '@angular/core';

import { DxDropdownComponentAbstract } from './dx-dropdown.component.abstract';
import { DxDropdownMenuComponent } from './dx-dropdown-menu.component';
import { DxOverlayService } from '../dx-overlay/dx-overlay.service';
import { DxPositionService } from '../dx-position/dx-position.service';
import { DxWindowService } from '../dx-utils/dx-window.service';

@Component({
  entryComponents: [DxDropdownMenuComponent],
  selector: 'dx-dropdown-toggle',
  templateUrl: './dx-dropdown-toggle.component.html'
})

export class DxDropdownToggleComponent extends DxDropdownComponentAbstract {

  constructor(
    dxOverlayService: DxOverlayService,
    dxPositionService: DxPositionService,
    dxWindowService: DxWindowService,
    elementRef: ElementRef,
    injector: Injector,
    resolver: ComponentFactoryResolver
  ) {
    super(dxWindowService, elementRef, injector, dxOverlayService,  dxPositionService, resolver);
  }
}

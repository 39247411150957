// Angular
import { Injectable } from '@angular/core';

// Third-party Libraries
import * as d3 from 'd3';
import { pluck, where } from 'underscore';

// Internal Dependencies
import { ChartService } from './chart.service';
import { DxUtilsService } from '../dx-utils/dx-utils.service';

@Injectable({ providedIn: 'root' })
export class ChartLegendService {

  legendPadding = {top: 0, left: 0, bottom: 10, right: 0};
  tooltips: any = {};

  constructor(
    private chartService: ChartService
  ) {}

  /**
   * Builds the legend and adds it to the given svg.
   */
  buildLegend = (svg: any, config: any, data: any[]): any => {
    const self = this;
    const series = pluck(data, 'key');
    const dimensions = this.getLegendDimensions(config);
    const columnWidth = Math.min(this.getColumnWidth(series), dimensions.width);
    const columnCount = Math.min(series.length, Math.floor(config.width / columnWidth)) || 1;
    const rowCount = Math.ceil(series.length / columnCount);
    const availableCells = Math.min(rowCount, dimensions.maxRows) * columnCount;
    const othersLabel = {
      disabled: false,
      key: '+'
    };

    const getLabelTransform = (i) => {
      const controlWidth = config.showControls !== true ? config.margin.left : config.margin.left + (config.controlOffset || 0);
      const currentColumn = columnCount - i % columnCount;
      const currentRow = Math.floor(i / columnCount) + 1;
      const totalWidth = Math.min(config.width, columnWidth * columnCount);
      const x = totalWidth - (columnWidth * currentColumn) + controlWidth;
      const y = (config.marginTop || config.margin.top) + dimensions.rowHeight * currentRow - this.legendPadding.bottom;

      return `translate(${x},${y})`;
    };

    let labels;
    let labelOverflow = false;

    const getLabelColor = (d, i) => {
      let color = config.colorScale(d.key);
      if (labelOverflow && i === labels.length - 1 && config.othersColor) {
        color = config.othersColor;
      }
      return color;
    };

    data.forEach((seriesObject) => {
      if (!seriesObject.hasOwnProperty('disabled')) {
        seriesObject.disabled = false;
      }
    });

    labels = data.slice(0, availableCells);

    const othersKeyValue = '+' + (series.length - labels.length + 1) + ' others';

    if (labels.length < series.length) {
      othersLabel.key = othersKeyValue;
      labels.pop();
      labels.push(othersLabel);
      labelOverflow = true;
    }

    if (typeof config.reduceSeriesCallback === 'function') {
      config.reduceSeriesCallback({key: othersKeyValue, maxSlices: labels.length});
    }

    svg.selectAll('.xs-legend').remove();
    svg.selectAll('.xs-legend-item').remove();

    const legendSelection = svg
      .append('g')
        .attr('class', 'xs-legend')
        .selectAll('g')
        .data(labels)
        .enter()
        .append('g');

    legendSelection
      .attr('transform', function(d, i) {
        return getLabelTransform(i);
      })
      .attr('class', 'xs-legend-item')
      .style('cursor', 'pointer')
      .style('user-select', 'none')
      .append('circle')
        .style('stroke', function(d, i) { return getLabelColor(d, i); })
        .style('stroke-width', 2)
        .style('fill', function(d, i) { return getLabelColor(d, i); })
        .style('fill-opacity', function(d) {
          return d.disabled ? 0 : 1;
        })
        .attr('class', 'dx-legend-symbol')
        .attr('r', 5);

    legendSelection.append('text')
      .attr('text-anchor', 'start')
      .attr('class', 'nv-legend-text')
      .attr('dy', '.32em')
      .attr('dx', '8')
      .attr('width', columnWidth - 20)
      .text(function(d) {
        const node = (d3.select(this));
        const truncatedKey = self.chartService.truncateText(d.key, node) || '';
        self.tooltips[d.key] = d.key.length !== truncatedKey.length ? d.key : undefined;
        return truncatedKey;
      })
      .append('svg:title')
      .text(function(d) {
        return self.tooltips[d.key];
      });

    const cc = this.clickCancel();
    legendSelection.call(cc);
    cc.on('click', function(d) {
      self.onClick(d, this, labels, config);
    });

    cc.on('dblclick', function() {
      self.onDbClick(svg, labels, this, labels, config);
    });

    return svg.select('.xs-legend').node().getBBox().height + dimensions.marginBottom;
  }

  /**
   * distinguish a single click from a double click
   * Ref: http://bl.ocks.org/ropeladder/83915942ac42f17c087a82001418f2ee
   */
  clickCancel() {
    const dispatcher = d3.dispatch('click', 'dblclick');
    function cc(selection) {
      const tolerance = 5;
      let down, last, wait = null, args;

      // euclidean distance
      const dist = (a, b) => {
        return Math.sqrt(Math.pow(a[0] - b[0], 2) - Math.pow(a[1] - b[1], 2));
      };

      selection.on('mousedown', function() {
        down = d3.mouse(document.body);
        last = +new Date();
        args = arguments;
      });

      selection.on('mouseup', function() {
        if (dist(down, d3.mouse(document.body)) > tolerance) {
          return;
        } else {
          if (wait) {
            window.clearTimeout(wait);
            wait = null;
            dispatcher.apply('dblclick', this, args);
          } else {
            const that = this;
            wait = window.setTimeout((function() {
              return function() {
                dispatcher.apply('click', that, args);
                wait = null;
              };
            })(), 300);
          }
        }
      });
    }

    // Copies a variable number of methods from source to target.
    const d3rebind = function(target, source, method) {
      let i = 1;
      const n = arguments.length;
      while (++i < n) { target[method = arguments[i]] = d3_rebind(target, source, source[method]); }
      return target;
    };

    function d3_rebind(target, source, method) {
      return function() {
        const value = method.apply(source, arguments);
        return value === source ? target : value;
      };
    }

    return d3rebind(cc, dispatcher, 'on');
  }

  /**
   * Enables all series
   */
  enableAll = (data: any, config: any): any => {
    data.forEach((series) => { series.disabled = false; });
    d3.selectAll('.xs-legend circle')
      .style('fill-opacity', 1);
    return config.callback();
  }

  /**
   * Calculates a reasonable column width for legend items.
   */
  getColumnWidth = (series: any[]): number => {
    const labelLengths = series.map((label) => {
      return label.length;
    });
    const medianLabelLength = DxUtilsService.median(labelLengths);
    const fontMultiplier = 9.4;
    const padding = 20;

    return medianLabelLength * fontMultiplier + padding;
  }

  /**
   * Gets a legend dimensions object.
   */
  getLegendDimensions = (config: any): any => {
    const maxHeight = config.maxHeight || (config.height / 3);
    const rowHeight = 20;

    return {
      height: 30,
      marginBottom: 10,
      maxHeight: maxHeight,
      maxLabelWidth: config.width / 3,
      maxRows: Math.floor(maxHeight / rowHeight),
      rowHeight: rowHeight,
      width: config.width
    };
  }

  /**
   * The legend item click event handler.
   */
  onClick(datum: any, selection: any, series: any[], config: any): any {
    let visibleSeries = [];
    d3.select(selection).select('circle')
      .filter(function(d) {
        d.disabled = !d.disabled;
        return d;
      })
      .style('fill-opacity', function(d) {
        return d.disabled ? 0 : 1;
      });

    series.forEach(function(value) {
      if (value.key === datum.value) {
        value.disabled = !value.disabled;
      }
    });

    // If all series have been deselected, reselect all.
    visibleSeries = where(series, {disabled: false});
    if (!visibleSeries.length) {
      this.enableAll(series, config);
    }

    return config.callback({series});
  }

  onDbClick(svg: any, series: any[], selection: any, labels: any[], config: any): void {
    let selectedKey;
    d3.select(selection).select('circle')
      .filter(function(d) {
        selectedKey = d.key;
        return d;
      });

    svg.selectAll('.xs-legend circle')
      .style('fill-opacity', (d, i) => {
        if (series[i]) { series[i].disabled = series[i].key !== selectedKey; }
        if (labels[i]) { labels[i].disabled = labels[i].key !== selectedKey; }
        return d.key !== selectedKey ? 0 : 1;
      });

    config.callback({series});
  }
}

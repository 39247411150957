import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html'
})
export class DropdownComponent implements OnInit {
  avatar = '../../../assets/images/avatar.svg';

  dxDropdownProperties = [
    {
      description: 'Used to specify a model containing the desired menu items.',
      name: 'filterableList',
      possibleValues: '',
      required: 'required',
      type: 'IDxDropdownOption[]'
    },
    {
      description: 'Output handler that passes the event and the selected item to the parent.',
      name: 'clickAction',
      possibleValues: '',
      required: 'optional',
      type: 'EventEmitter<any>'
    },
    {
      description: 'Sets disable state of dropdown.',
      name: 'disabled',
      possibleValues: 'default: false',
      required: 'optional',
      type: 'boolean'
    },
    {
      description: 'Adds additional classes to the dropdown menu.',
      name: 'dropdownMenuClass',
      possibleValues: '"small", default: "medium", "large", "dropdown-stretch", "dropdown-select',
      required: 'optional',
      type: 'string'
    },
    {
      description: 'Sets the ID attribute value on the dropdown menu list element. Useful for E2E test selectors.',
      name: 'dropdownMenuId',
      possibleValues: 'default: "menu-{guid}"',
      required: 'optional',
      type: 'string'
    },
    {
      description: 'Adds a header title to the dropdown menu.',
      name: 'headerTitle',
      possibleValues: '',
      required: 'optional',
      type: 'string'
    },
    {
      description: 'Adds an input to search menu items.',
      name: 'inputFilter',
      possibleValues: '',
      required: 'optional',
      type: 'IDxDropdownInputFilter'
    },
    {
      description: 'Adds a checkmark to selected menu items.',
      name: 'isChecklist',
      possibleValues: 'default: false',
      required: 'optional',
      type: 'boolean'
    },
    {
      description: 'Allows multiple selections.',
      name: 'isMultiSelect',
      possibleValues: 'default: false',
      required: 'optional',
      type: 'boolean'
    },
    {
      description: 'Adds additional classes to the dropdown trigger.',
      name: 'triggerClass',
      possibleValues: '"dropdown-stretch", "dropdown-select"',
      required: 'optional',
      type: 'string'
    },
    {
      description: 'Set the menu trigger title text.',
      name: 'triggerText',
      possibleValues: '',
      required: 'optional',
      type: 'string'
    }
  ];

  idxDropdownOptionProperties = [
    {
      description: 'The option label to display.',
      name: 'label',
      possibleValues: '',
      required: 'required',
      type: 'string'
    },
    {
      description: 'The option value to use for the model.',
      name: 'value',
      possibleValues: '',
      required: 'required',
      type: 'string'
    },
    {
      description: 'Whether the option is active or not.',
      name: 'active',
      required: 'optional',
      type: 'boolean'
    },
    {
      description: 'Hex value for color dropdowns.',
      name: 'color',
      required: 'optional',
      type: 'string'
    },
    {
      description: 'Description text for description dropdowns.',
      name: 'description',
      required: 'optional',
      type: 'string'
    },
    {
      description: 'An array of classes to apply for icon dropdowns.',
      name: 'iconClasses',
      required: 'optional',
      type: 'string[]'
    },
    {
      description: 'An image config object for image dropdowns.',
      name: 'image',
      required: 'optional',
      type:
`{
  classes?: string[],
  src: string
}`
    },
    {
      description: 'Whether the option is the default or not.',
      name: 'isDefault',
      possibleValues: '',
      required: 'optional',
      type: 'boolean'
    }
  ];

  idxDropdownInputFilterProperties = [
    {
      description: 'Input placeholder text.',
      name: 'placeholder',
      required: 'required',
      type: 'string'
    },
    {
      description: 'Set true/false to show/hide input.',
      name: 'show',
      required: 'required',
      type: 'boolean'
    }
  ];

  designGuidelines = {
    data: {
      active: `Set a default menu item by setting the <code class="code-inline">active</code> property to <code class="code-inline">true</code> in the options model.`,
      alignment: `Use the <code class="code-inline">position</code> property to align the dropdown menu to the left, right, center or position the menu above the button.`,
      checklist: `Checklist dropdowns should be used to denote a selected state within a dropdown menu.`,
      clickEvent: `The <code class="code-inline">clickAction</code> property is used to trigger a click event when selecting a menu item.`,
      colorCoded: `Color-coded dropdowns require no additional option attribute or class. Just include the <code class="code-inline">color</code> property in
        the options model and set the value to a valid CSS color (hex value, rgba, keyword, etc...).`,
      combination: `Sensible combinations are allowed. For example, a center-aligned checklist dropdown with icons, input, a title, scrolling
        and multi-select.`,
      customMenuId: `The <code class="code-inline">dropdownMenuId</code> property sets a custom value for the ID attribute on the menu list element.
      This is useful if you need to programmatically locate the menu after it is opened for testing or further customization.`,
      dataModel: `The <code class="code-inline">filterableList</code> property is required for all dropdowns and used to supply the list of dropdown menu options.
      At a minimum, the <code class="code-inline">label</code> and <code class="code-inline">value</code> properties are required.
      Refer to the IDxDropdownOption Properties table above for additional optional properties.`,
      descriptions: `Add the <code class="code-inline">description</code> property to the options model to include a description to each dropdown option.
      Note that menu sizing classes will be overridden as priority will be given to fitting the entire length of the description.
      However, descriptions that cause the menu to be greater than 350px wide will be automatically truncated.`,
      divider: `Menu dividers can be used to separate groups of related menu items by setting the <code class="code-inline">value</code> property in the options model to <code class="code-inline">"divider"</code>.`,
      icon: `Icon dropdowns require no additional option attribute or class. Just include the <code class="code-inline">iconClasses</code> property in the options model.`,
      image: `Image dropdowns require no additional option attribute or class. Just include the image object in the options model and set the <code class="code-inline">src</code>
      property to an image path or data URI and optionally add additional classes via the <code class="code-inline">classes</code> property.`,
      input: `Add an input to a dropdown to filter what options are displayed.`,
      multiSelect: `Allow a dropdown to select multiple options.`,
      scrolling: `A vertical scroll bar will automatically be added to dropdown menus that are greater than 300px in height.`,
      simple: `A basic dropdown.`,
      sizing: `Use these sizing classes to size dropdown menus.`,
      stretch: `Add the class <code class="code-inline">dropdown-stretch</code> to the dropdown trigger and menu to stretch the dropdown to the width of the parent element.`,
      titled: `Add a title to the dropdown menu through the <code class="code-inline">headerTitle</code> property.`,
      triggerClass: `Use the <code class="code-inline">triggerClass</code> property to add additional classes to the dropdown trigger.`,
      triggerText: `Use the <code class="code-inline">triggerText</code> property to customize the text for the dropdown trigger.`
    },
    template: {
      alignment: `Left alignment is the default for dropdown menus and is suitable in most cases. Examples of exceptions where you should consider
      using alternate alignment settings would be if you know a dropdown will always appear on the right edge or at the very bottom of a page.
      Center alignment is rarely used but if needed, ensure that the width of the options menu box matches the width of the button.<br><br>
      The dropdown component incorporates collision avoidance and its alignment will be automatically adjusted in cases where the options menu
      box would be clipped by the browser window view port.`,
      icon: `Dropdown menus may include optional icons to the left of each option.`,
      scroll: `Dropdown menus may include optional vertical scroll. However, try to avoid using dropdown menus to display long lists of items
    (more than 10) and in cases where the number of options exceeds 20, consider including an optional search box at the top of the menu.`,
      select: `Use the .dropdown-select class to style a dropdown toggle like a select input.`,
      simple: `This is the most basic and common variant of the dropdown component.`,
      sizing: `Three dropdown menu widths are available to accommodate options sets of different average string lengths. Employ
    the narrowest width that doesn’t result in the longest menu option being automatically truncated. While truncation is
    sometimes unavoidable, if one option is considerably longer than its peers, edit it for brevity before selecting the
    appropriate width.`,
      stretch: `Use the .dropdown-stretch class to expand the dropdown menu and button element to 100% of the parent element width.`,
      title: `Dropdown menus may include optional titles at the top of the options menu.`
    }
  };

  dropdownDataModel = {
    activeOptions: [
      {label: 'Menu 1', value: 1},
      {label: 'Menu 2', value: 2, active: true},
      {label: 'Menu 3', value: 3},
      {label: 'Menu 4', value: 4}
    ],
    basicOptions: [
      {label: 'Menu 1', value: 1},
      {label: 'Menu 2', value: 2},
      {label: 'Menu 3', value: 3},
      {label: 'Menu 4', value: 4}
    ],
    colorOptions: [
      {label: 'Menu 1', value: 1, color: '#d14b4b'},
      {label: 'Menu 2', value: 2, color: '#378de9'},
      {label: 'Menu 3', value: 3, color: '#4db36f'},
      {label: '', value: 'divider'},
      {label: 'Menu 4', value: 4, color: '#f2945d'}
    ],
    complexOptions: [
      {label: 'Alabama', description: 'AL, Ala.', value: 'Alabama', iconClasses: 'fas fa-fw fa-map-marker'},
      {label: 'Arizona', description: 'AZ, Ariz.', value: 'Arizona', iconClasses: 'fas fa-fw fa-map-marker'},
      {label: 'California', description: 'CA, Calif., Cal.', value: 'California', iconClasses: 'fas fa-fw fa-map-marker'},
      {label: 'Colorado', description: 'CO, Colo.', value: 'Colorado', iconClasses: 'fas fa-fw fa-map-marker'},
      {label: 'Delaware', description: 'DE, Del.', value: 'Delaware', iconClasses: 'fas fa-fw fa-map-marker'},
      {label: 'Nebraska', description: 'NE', value: 'Nebraska', iconClasses: 'fas fa-fw fa-map-marker'},
      {label: 'Texas', description: 'TX, Tex.', value: 'Texas', iconClasses: 'fas fa-fw fa-map-marker'},
      {label: 'Utah', description: 'UT', value: 'Utah', iconClasses: 'fas fa-fw fa-map-marker'},
      {label: 'Wyoming', description: 'WY', value: 'Wyoming', iconClasses: 'fas fa-fw fa-map-marker'},
      {label: '', value: 'divider'},
      {label: 'Puerto Rico', value: 'Puerto Rico', iconClasses: 'fas fa-fw fa-map-marker'}
    ],
    descriptionOptions: [
      {label: 'Menu 1', description: 'Menu 1 description', value: 'Menu 1'},
      {label: 'Menu 2', description: 'Menu 2 description', value: 'Menu 2'},
      {label: 'Menu 3', description: 'Menu 3 description', value: 'Menu 3'},
      {label: 'Menu 4', description: 'Menu 4 description', value: 'Menu 4'}
    ],
    dividerOptions: [
      {label: 'Menu 1', value: 1},
      {label: 'Menu 2', value: 2},
      {label: 'Menu 3', value: 3},
      {label: '', value: 'divider'},
      {label: 'Menu 4', value: 4}
    ],
    iconOptions: [
      {label: 'Menu 1', value: 1, iconClasses: 'fas fa-user'},
      {label: 'Menu 2', value: 2, iconClasses: 'fas fa-home'},
      {label: 'Menu 3', value: 3, iconClasses: 'fas fa-cog'},
      {label: '', value: 'divider'},
      {label: 'Menu 4', value: 4, iconClasses: 'fas fa-map-marker'}
    ],
    imageOptions: [
      {label: 'Menu 1', value: 1, image: {src: this.avatar, classes: 'avatar-24'}},
      {label: 'Menu 2', value: 2, image: {src: this.avatar, classes: 'avatar-24'}},
      {label: 'Menu 3', value: 3, image: {src: this.avatar, classes: 'avatar-24'}},
      {label: 'Menu 4', value: 4, image: {src: this.avatar, classes: 'avatar-24'}}
    ],
    multiSelectOptions: [
      {label: 'Menu 1', value: 1},
      {label: 'Menu 2', value: 2},
      {label: 'Menu 3', value: 3},
      {label: 'Menu 4', value: 4}
    ],
    scrollingOptions: [
      {label: 'Menu 1', value: 1},
      {label: 'Menu 2', value: 2},
      {label: 'Menu 3', value: 3},
      {label: 'Menu 4', value: 4},
      {label: 'Menu 5', value: 5},
      {label: 'Menu 6', value: 6},
      {label: 'Menu 7', value: 7},
      {label: 'Menu 8', value: 8},
      {label: 'Menu 9', value: 9},
      {label: 'Menu 10', value: 10},
      {label: 'Menu 11', value: 11},
      {label: 'Menu 12', value: 12},
      {label: 'Menu 13', value: 13},
      {label: 'Menu 14', value: 15},
      {label: 'Menu 15', value: 15}
    ]
  };

  headerText = {
    dataDrivenDropdown: {
      description: `Dx-dropdowns are data-driven replacements for highly styled select controls. For simple dropdowns and
      content-dropdowns, use a template-driven dropdown instead.`,
      title: 'Data-driven Dropdowns'
    },
    dropdownOverview: {
      description: `Dropdown elements, used with links or buttons.`,
      title: 'Dropdowns'
    },
    templateDrivenDropdown: {
      description: `Use dropdowns to allow the user to access a set of options, attributes, or actions while conserving screen real estate and
    ensuring valid data input. This section describes dx-dropdown-toggle directives and dx-dropdown directives which are based on Foundation
    dropdown-toggles. dx-dropdown directives require minimal markup - just a button element and some configuration attributes.`,
      title: 'Template-driven Dropdowns'
    }
  };

  typescript =
`import { Component } from '@angular/core';

@Component({
  selector: 'app-dropdown-example',
  templateUrl: './dropdown-example.component.html'
})
export class DropdownExampleComponent  {
  clickHandler = (item, dataModel) => {
    const selectedModel = dataModel.filter((selectedItem) => selectedItem.active);
    dataModel.output = selectedModel.length > 0 ? JSON.stringify(selectedModel) : '';
    dataModel.outputLabel = selectedModel.length > 0 ? JSON.stringify(selectedModel.map((selectedItem) => selectedItem.label)) : '';
  };
}`;

  typescriptTriggerClass =
`import { Component } from '@angular/core';

@Component({
  selector: 'app-dropdown-example',
  templateUrl: './dropdown-example.component.html',
  styles:  ['.text-red { color: red; }']
})
export class DropdownExampleComponent  {
  clickHandler = (item, dataModel) => {
    const selectedModel = dataModel.filter((selectedItem) => selectedItem.active);
    dataModel.output = selectedModel.length > 0 ? JSON.stringify(selectedModel) : '';
    dataModel.outputLabel = selectedModel.length > 0 ? JSON.stringify(selectedModel.map((selectedItem) => selectedItem.label)) : '';
  };
}`;

  usage = {
    importStatement: `import { DxDropdownModule } from '@dx/dx-angular-components-lib';`
  };

  ngOnInit() {
    // Preset the output and outputLabel for the active dropdown example when first loaded
    this.clickHandler(null, this.dropdownDataModel.activeOptions);
  }

  clickHandler(item: any, dataModel: any) {
    const selectedModel = dataModel.filter((selectedItem: any) => selectedItem.active);
    dataModel.output = selectedModel.length > 0 ? JSON.stringify(selectedModel) : '';
    dataModel.outputLabel = selectedModel.length > 0 ? JSON.stringify(selectedModel.map((selectedItem: any) => selectedItem.label)) : '';
  }
}

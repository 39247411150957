import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './app-nav.component.html'
})
export class AppNavComponent {

  @Output() rightNavObjAction = new EventEmitter();

  navItems = [
    {
      fragments: [],
      name: 'Intro',
      route: 'intro',
      subItems: []
    },
    {
      fragments: [
        {
          fragments: [],
          name: 'Include the library as a dependency',
          route: 'include-library',
          subItems: []
        },
        {
          fragments: [],
          name: 'Example  Demo codes',
          route: 'import-resources',
          subItems: []
        }
      ],
      name: 'Get Started',
      route: 'get-started',
      subItems: []
    },
    // Hide release instructions for now
    // {
    //   fragments: [
    //     {
    //       fragments: [],
    //       name: 'Set up environment',
    //       route: 'set-environment',
    //       subItems: []
    //     },
    //     {
    //       fragments: [],
    //       name: 'Naming conventions',
    //       route: 'naming-conventions',
    //       subItems: []
    //     },
    //     {
    //       fragments: [],
    //       name: 'Creating releases',
    //       route: 'creating-releases',
    //       subItems: []
    //     },
    //     {
    //       fragments: [],
    //       name: 'Publishing library',
    //       route: 'publishing-library',
    //       subItems: []
    //     }
    //   ],
    //   name: 'Release Instructions',
    //   route: 'release-instructions',
    //   subItems: []
    // },
    {
      fragments: [],
      name: 'Popular use cases',
      route: 'use-cases',
      subItems: [
        {
          fragments: [],
          name: 'Money transfer',
          route: 'get-started/#1',
        },
        {
          fragments: [],
          name: 'Mobile recharge',
          route: 'get-started/#2',
        },
        {
          fragments: [],
          name: 'Selling internet',
          route: 'get-started/#3',
        },
        {
          fragments: [],
          name: 'Sell car insurance',
          route: 'get-started/#4',
        },
        {
          fragments: [],
          name: 'Mobile recharge',
          route: 'get-started/#5',
        },
        {
          fragments: [],
          name: 'Sell travel ticket',
          route: 'get-started/#6',
        },
        {
          fragments: [],
          name: 'Integrate in E-commerce',
          route: 'get-started/#7',
        },
        {
          fragments: [],
          name: 'Sell domain names',
          route: 'get-started/#8',
        },
        {
          fragments: [],
          name: 'Pay CanalSat bill',
          route: 'get-started/#9',
        },
        {
          fragments: [],
          name: 'Pay Electricity bill',
          route: 'get-started/#10',
        },
        {
          fragments: [],
          name: 'Pay Water bill',
          route: 'get-started/#11',
        },
        {
          fragments: [],
          name: 'Pay for anything',
          route: 'get-started/#12',
        }
      ]
    },
    /*
    {
      fragments: [],
      name: 'Styles',
      route: 'styles-themes',
      subItems: [
        {
          fragments: [
            { fragments: [], name: 'Types', route: 'button-types', subItems: [] },
            { fragments: [], name: 'Sizes', route: 'button-sizes', subItems: [] },
            { fragments: [], name: 'Description', route: 'button-description', subItems: [] },
            { fragments: [], name: 'Group', route: 'button-group', subItems: [] },
            { fragments: [], name: 'Links', route: 'button-links', subItems: [] },
            { fragments: [], name: 'Toggle', route: 'button-toggle', subItems: [] }
          ],
          name: 'Button',
          route: 'styles-themes/button',
          subItems: []
        }
      ]
    },
    {
      fragments: [],
      name: 'Components',
      route: 'components',
      subItems: [
        {
          fragments: [
            { fragments: [], name: 'Types', route: 'alert-types', subItems: [] },
            { fragments: [], name: 'Inline alerts', route: 'alert-inline', subItems: [] }
          ],
          name: 'Alert',
          route: 'components/alert',
          subItems: []
        },
        {
          fragments: [
            { fragments: [], name: 'Types', route: 'bar-chart-types', subItems: [] },
            { fragments: [], name: 'Controls', route: 'bar-chart-with-controls', subItems: [] },
            { fragments: [], name: 'Legend', route: 'bar-chart-with-legend', subItems: [] },
            { fragments: [], name: 'Truncated legend', route: 'bar-chart-with-truncated-legend', subItems: [] },
            { fragments: [], name: 'Axis labels', route: 'bar-chart-with-axis-labels', subItems: [] },
            { fragments: [], name: 'Color palettes', route: 'bar-chart-with-different-color-palettes', subItems: [] }
          ],
          name: 'Bar Chart',
          route: 'components/bar-chart',
          subItems: []
        },
        {
          fragments: [
            { fragments: [], name: 'Types', route: 'column-chart-types', subItems: [] },
            { fragments: [], name: 'Controls', route: 'column-chart-with-controls', subItems: [] },
            { fragments: [], name: 'Legend', route: 'column-chart-with-legend', subItems: [] },
            { fragments: [], name: 'Truncated legend', route: 'column-chart-with-truncated-legend', subItems: [] },
            { fragments: [], name: 'Axis labels', route: 'column-chart-with-axis-labels', subItems: [] },
            { fragments: [], name: 'Color palettes', route: 'column-chart-with-different-color-palettes', subItems: [] }
          ],
          name: 'Column Chart',
          route: 'components/column-chart',
          subItems: []
        },
        {
          fragments: [
            {
              fragments: [
                { fragments: [], name: 'Simple', route: 'dropdown-simple', subItems: [] },
                { fragments: [], name: 'Data Model', route: 'dropdown-data-model', subItems: [] },
                { fragments: [], name: 'Click Event', route: 'dropdown-click-event', subItems: [] },
                { fragments: [], name: 'Trigger Text', route: 'dropdown-trigger-text', subItems: [] },
                { fragments: [], name: 'Trigger Class', route: 'dropdown-trigger-class', subItems: [] },
                { fragments: [], name: 'Menu Divider', route: 'dropdown-menu-divider', subItems: [] },
                { fragments: [], name: 'Alignment', route: 'dropdown-alignment', subItems: [] },
                { fragments: [], name: 'Sizing', route: 'dropdown-sizing', subItems: [] },
                { fragments: [], name: 'Stretch', route: 'dropdown-stretch', subItems: [] },
                { fragments: [], name: 'Custom Menu ID', route: 'dropdown-custom-menu-id', subItems: [] },
                { fragments: [], name: 'Checklist', route: 'dropdown-checklist', subItems: [] },
                { fragments: [], name: 'Titled', route: 'dropdown-titled', subItems: [] },
                { fragments: [], name: 'Input', route: 'dropdown-input', subItems: [] },
                { fragments: [], name: 'Multi-select', route: 'dropdown-multi-select', subItems: [] },
                { fragments: [], name: 'Active Item', route: 'dropdown-active', subItems: [] },
                { fragments: [], name: 'Color-coded', route: 'dropdown-color-coded', subItems: [] },
                { fragments: [], name: 'Description', route: 'dropdown-description', subItems: [] },
                { fragments: [], name: 'Image', route: 'dropdown-image', subItems: [] },
                { fragments: [], name: 'Icon', route: 'dropdown-icon', subItems: [] },
                { fragments: [], name: 'Scrolling', route: 'dropdown-scrolling', subItems: [] },
                { fragments: [], name: 'Combination', route: 'dropdown-combination', subItems: [] },
              ],
              name: 'Data-driven',
              route: 'components/dropdown',
              subItems: []
            },
            {
              fragments: [
                { fragments: [], name: 'Simple', route: 'dropdown-toggle-simple', subItems: [] },
                { fragments: [], name: 'Alignment', route: 'dropdown-toggle-alignment', subItems: [] },
                { fragments: [], name: 'Sizing', route: 'dropdown-toggle-sizing', subItems: [] },
                { fragments: [], name: 'Scrolling', route: 'dropdown-toggle-scroll', subItems: [] },
                { fragments: [], name: 'Titled', route: 'dropdown-toggle-title', subItems: [] },
                { fragments: [], name: 'Icon', route: 'dropdown-toggle-icon', subItems: [] },
                { fragments: [], name: 'Select', route: 'dropdown-toggle-select', subItems: [] },
                { fragments: [], name: 'Stretch', route: 'dropdown-toggle-stretch', subItems: [] }
              ],
              name: 'Template-driven',
              route: 'components/dropdown',
              subItems: []
            }
          ],
          name: 'Dropdown',
          route: 'components/dropdown',
          subItems: []
        },
        {
          fragments: [
            { fragments: [], name: 'Autofocus', route: 'form-autofocus', subItems: [] },
            { fragments: [], name: 'Switches', route: 'form-switches', subItems: [] },
            { fragments: [], name: 'Select on focus', route: 'form-select-on-focus', subItems: [] }
          ],
          name: 'Form',
          route: 'components/form',
          subItems: []
        },
        {
          fragments: [
            { fragments: [], name: 'Modal content', route: 'modal-content', subItems: [] },
            { fragments: [], name: 'Modal content (NoEscape)', route: 'modal-content-noescape', subItems: [] },
            { fragments: [], name: 'Modal content (Return value)', route: 'modal-content-return-value', subItems: [] },
            { fragments: [], name: 'Basic', route: 'modal-basic', subItems: [] },
            { fragments: [], name: 'Header', route: 'modal-header', subItems: [] },
            { fragments: [], name: 'Footer', route: 'modal-footer', subItems: [] },
            { fragments: [], name: 'Sizing', route: 'modal-sizing', subItems: [] },
            { fragments: [], name: 'Custom class', route: 'modal-windowclass', subItems: [] },
            { fragments: [], name: 'Resolve', route: 'modal-resolve', subItems: [] },
            { fragments: [], name: 'NoEscape', route: 'modal-noescape', subItems: [] },
            { fragments: [], name: 'Return value', route: 'modal-return-value', subItems: [] }
          ],
          name: 'Modal (Component-driven)',
          route: 'components/modal-component-driven',
          subItems: []
        },
        {
          fragments: [
            { fragments: [], name: 'Basic', route: 'modal-basic', subItems: [] },
            { fragments: [], name: 'Header', route: 'modal-header', subItems: [] },
            { fragments: [], name: 'Footer', route: 'modal-footer', subItems: [] },
            { fragments: [], name: 'Sizing', route: 'modal-sizing', subItems: [] },
            { fragments: [], name: 'Custom class', route: 'modal-windowclass', subItems: [] },
            { fragments: [], name: 'NoEscape', route: 'modal-noescape', subItems: [] }
          ],
          name: 'Modal (Template-driven)',
          route: 'components/modal-template-driven',
          subItems: []
        },
        {
          fragments: [],
          name: 'Overlay',
          route: 'components/overlay',
          subItems: []
        },
        {
          fragments: [
            { fragments: [], name: 'Types', route: 'pie-chart-types', subItems: [] },
            { fragments: [], name: 'Legend', route: 'pie-chart-with-legend', subItems: [] },
            { fragments: [], name: 'Labels', route: 'pie-chart-with-labels', subItems: [] },
            { fragments: [], name: 'Color palettes', route: 'pie-chart-with-different-color-palettes', subItems: [] }
          ],
          name: 'Pie Chart',
          route: 'components/pie-chart',
          subItems: []
        },
        {
          fragments: [
            { fragments: [], name: 'Horizontal', route: 'tabs-horizontal', subItems: [] },
            { fragments: [], name: 'Vertical', route: 'tabs-vertical', subItems: [] },
            { fragments: [], name: 'Set active tab', route: 'tabs-active', subItems: [] }
          ],
          name: 'Tabs',
          route: 'components/tabs',
          subItems: []
        },
        {
          fragments: [
            { fragments: [], name: 'Alignment', route: 'tooltip-alignment', subItems: [] },
            { fragments: [], name: 'Set a custom class', route: 'tooltip-class', subItems: [] },
            { fragments: [], name: 'Show/hide delay', route: 'tooltip-delay', subItems: [] },
            { fragments: [], name: 'Custom Trigger', route: 'tooltip-custom-trigger', subItems: [] }
          ],
          name: 'Tooltip',
          route: 'components/tooltip',
          subItems: []
        }
      ]
    },
    {
      fragments: [],
      name: 'Patterns',
      route: 'patterns',
      subItems: [
        {
          fragments: [],
          name: 'Contextual Modal',
          route: 'patterns/modal-contextual',
          subItems: []
        },
        {
          fragments: [],
          name: 'Multi-step Modal',
          route: 'patterns/modal-multistep',
          subItems: []
        },
        {
          fragments: [
            { fragments: [], name: 'Basic', route: 'side-nav-basic', subItems: [] },
            { fragments: [], name: 'Auto-collapse', route: 'side-nav-autocollapse', subItems: [] },
            { fragments: [], name: 'Searchable', route: 'side-nav-searchable', subItems: [] },
            { fragments: [], name: 'With Header and Footer', route: 'side-nav-header-footer', subItems: [] },
            { fragments: [], name: 'Dual side nav', route: 'side-nav-dual', subItems: [] }
          ],
          name: 'Side Navigation',
          route: 'patterns/side-nav',
          subItems: []
        }
      ]
    },
    {
      fragments: [
        {
          fragments: [],
          name: 'Create a library component',
          route: 'create-component',
          subItems: []
        },
        {
          fragments: [],
          name: 'Create an example',
          route: 'create-example',
          subItems: []
        }
      ],
      name: 'Contribute',
      route: 'contribute',
      subItems: []
    }
    */
  ];

  handleItemFragments = (fragments: any) => {
    this.rightNavObjAction.emit(fragments);
  }
}

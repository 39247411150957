// Angular
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

// Third-party Libraries
import * as d3 from 'd3';
import { clone, findWhere, isNull, map, values } from 'underscore';
import { Observable } from 'rxjs';

// Internal Dependencies
import { ChartLegendService } from '../chart-legend.service';
import { ChartService } from '../chart.service';
import { DxUtilsService } from '../../dx-utils/dx-utils.service';
import { DxWindowService } from '../../dx-utils/dx-window.service';
import { IChartMargins } from '../../dx-utils/dx-types';

@Component({
  selector: 'dx-bar-chart',
  template: `<svg class="dxd3 dxd3-svg dx-chart dx-bar-chart" [id]="chartId"></svg>`
})
export class DxBarChartComponent implements AfterViewInit, OnChanges, OnDestroy, OnInit {

  @Input() color: string;
  @Input() chartData: any[];
  @Input() margin: IChartMargins = {left: 60, right: 20, top: 20, bottom: 60};
  @Input() showControls: boolean;
  @Input() showLegend: boolean;
  @Input() stacked: boolean;
  @Input() updateEvents: Observable<string>;
  @Input() xAxisLabel: string;
  @Input() xAxisLabelDistance: number;
  @Input() xAxisPreset: any;
  @Input() xAxisTickFormat: any;
  @Input() yAxisLabel: string;
  @Input() yAxisLabelDistance: number;
  @Input() yAxisPreset: any;
  @Input() yAxisTickFormat: any;

  @Output() elementclick: EventEmitter<any> = new EventEmitter();

  chart: any;
  chartId: string;
  chartHeight: number;
  chartWidth: number;
  controlOffset_H = 170;
  controlOffset_V = 20;
  series: any[];
  groupNode: any;
  legendConfig: any;
  legendHeight = 0;
  legendSeries: any[] = [];
  resizeTimeout: any;
  scaleX: any;
  scaleY: any;
  scaleC: any;
  scaleS: any;
  sDomain: any[];
  seriesPadding = 0.1;
  tickFormat = null;
  tooltipClass = 'bar-tooltip';
  window: Window;
  xAxisHeight = 20;
  xDomain: any[];
  xTickCount = 7;
  yDomain: number[];

  constructor(
    private chartLegendService: ChartLegendService,
    private chartService: ChartService,
    private dxWindowService: DxWindowService,
    private elementRef: ElementRef,
    private utilsService: DxUtilsService
  ) {
    this.window = this.dxWindowService.nativeWindow;
  }

  /**
   * Appends structural containers to the svg.
   */
  addContainers = (): void => {
    this.chart.svg.append('g').attr('class', 'y-axis dx-axis');
    this.chart.svg.append('g').attr('class', 'x-axis dx-axis');
  }

  /**
   * Adds the tooltip to a chart node.
   * @param data  The hovered bar data to build the tooltip from.
   */
  addTooltip = (data: any): void => {
    // Remove any previous tooltip
    this.removeTooltip();

    d3.select('body')
      .append('div')
      .attr('class', `dxtooltip ${this.tooltipClass}`);

    const tooltip = d3.select(`.${this.tooltipClass}`);

    tooltip
      .style('position', 'absolute')
      .style('visibility', 'visible')
      .html(this.buildTooltip(data));
  }

  /**
   * Builds the chart control to toggle between grouped and stacked chart types.
   */
  buildChartControls = (): void => {
    const controlData = [{label: 'Grouped', type: 'group'}, {label: 'Stacked', type: 'stack'}];
    const dxSpacing = 75;
    const hOffset = this.margin.left + 10;
    const vOffset = this.controlOffset_V;

    const changeChartType = () => {
      this.drawChart();
    };

    this.chart.svg.select('.dx-controlsWrap').remove();
    const controls = this.chart.svg.append('g').attr('class', 'dx-controlsWrap')
      .attr('transform', `translate(${hOffset}, ${vOffset})`)
      .selectAll('label')
      .data(controlData)
      .enter();

    controls.append('circle')
      .attr('cx', (d, i) => { return dxSpacing * i; } )
      .attr('c', 0)
      .attr('r', 5)
      .attr('class', 'dx-legend-symbol')
      .attr('stroke-width', '2')
      .style('fill-opacity', (d) => {
        return (this.stacked && d.type === 'stack' || !this.stacked && d.type === 'group') ? 1 : 0;
      })
      .on('click', (d) => {
        this.stacked = d.type === 'stack';
        changeChartType();
      });

    controls.append('text')
      .attr('dx', (d, i) => { return dxSpacing * i + 10; })
      .attr('dy', '4')
      .text((d) => d.label)
      .on('click', (d, i) => {
        this.stacked = d.type === 'stack';
        changeChartType();
      });
  }

  /**
   * Returns the chart object model.
   */
  barChartModel = (): any => {
    return {
      svg: {},
      update: () => {
        return this.drawChart();
      },
      xAxis: {},
      xAxisGrid: {},
      yAxis: {},
      yAxisGrid: {}
    };
  }

  /**
   * Builds the X axis for the chart.
   */
  buildXAxis = (): void => {
    this.chart.xAxis = d3.axisBottom(this.scaleX);
  }

  /**
   * Builds the Y axis for the chart.
   */
  buildYAxis = (): void =>  {
    this.chart.yAxis = d3.axisLeft(this.scaleS);
  }

  /**
   * Builds the bar-chart bar tooltip content.
   * @param data  The hovered bar data to build the tooltip from.
   * @return  The tooltip DOM content
   */
  buildTooltip = (data: any): string => {
    const template = `
      <table>
        <tbody>
          <tr>
            <td colspan="2" class="legend-color-guide">
              <div
                style="
                  display: inline-block;
                  background-color: ${this.scaleC(data.c)};"
              >
              </div>
              <span class="key valign-middle padding-h-xsmall">
                <b>${this.utilsService.truncateToChars(data.y, 50)}</b>
              </span>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              ${this.utilsService.truncateToChars(data.c, 50)}: <b>${this.utilsService.truncateToChars(data.x, 50)}</b>
            </td>
          </tr>
        </tbody>
      </table >`;
    return template;
  }

  /**
   * Builds the gridlines for the X axis.
   */
  buildXAxisGrid = (): void => {
    const chartHeight = this.getChartHeight() - this.xAxisHeight;
    const tickCount = this.xTickCount;
    const tickValues = this.xAxisTickFormat(this.scaleX.ticks(tickCount).concat(this.scaleX.domain()));
    const vOffset = this.margin.top + (this.showControls && !this.showLegend ? this.controlOffset_V : 0);
    const totalOffset = this.showLegend ? vOffset + this.legendHeight : vOffset;

    this.chart.xAxisGrid = this.chart.xAxis
      .ticks(tickCount)
      .tickValues(tickValues)
      .tickSize(chartHeight)
      .tickFormat(this.xAxisTickFormat);

    this.chart.svg.select('g.x-axis')
      .attr('transform', () => {
        return `translate(${this.margin.left},${totalOffset})`;
      })
      .call(this.chart.xAxisGrid)
      .selectAll('path')
      .attr('d', () => `M${this.chartWidth},${this.chartHeight}V${this.chartHeight}H0V0`);
  }

  /**
   * Adds and positions the X axis label.
   * @param label  The x-axis label.
   * @param distance  The label offset from the x-axis.
   */
  buildXAxisLabel = (label: string, distance: number): void => {
    const height = this.elementRef.nativeElement.parentNode.clientHeight;
    const labelWidth = this.getChartWidth();
    const self = this;
    const xOffset = (labelWidth / 2) + this.margin.left;
    const yOffset = height - 14 + (distance || 0);

    this.chart.svg.select('.x-axis-label').remove();
    return this.chart.svg.append('g')
      .attr('class', 'x-axis-label')
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('width', labelWidth)
      .attr('x', xOffset)
      .attr('y', yOffset)
      .text(function() {
        const selection = d3.select(this);
        return self.chartService.truncateText(label, selection);
      });
  }

  /**
   * Builds the grid lines for the Y axis.
   */
  buildYAxisGrid = (): void => {
    const legendOffset = this.showLegend ? this.legendHeight : (this.showControls ? this.controlOffset_V : 0);
    const vOffset = this.margin.top + legendOffset;
    const hOffset = this.margin.left;

    const tickCount = this.yDomain.length;
    this.chart.yAxisGrid = this.chart.yAxis
      .ticks(tickCount)
      .tickSize(-this.chartWidth)
      .tickFormat(this.yAxisTickFormat);

    this.chart.svg.select('g.y-axis')
      .attr('transform', `translate(${hOffset}, ${vOffset})`)
      .call(this.chart.yAxisGrid);
  }

  /**
   * Adds and positions the Y axis label.
   * @param label  The y-axis label.
   * @param distance  The label offset from the y-axis.
   */
  buildYAxisLabel = (label: string, distance: number): any => {
    const labelWidth = this.getChartHeight();
    const self = this;
    const xOffset = 14 + (-distance || 0);
    const yOffset = (labelWidth / 2) + (this.showLegend ? this.legendHeight :
      (this.showControls && !this.showLegend ? this.margin.top + this.controlOffset_V : this.margin.top));

    let rotateOrigin;

    rotateOrigin = [xOffset, yOffset].join(' ');

    this.chart.svg.select('.y-axis-label').remove();
    return this.chart.svg.append('g')
      .attr('class', 'y-axis-label')
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('width', labelWidth)
      .attr('x', xOffset)
      .attr('y', yOffset)
      .attr('transform', 'rotate(-90 ' + rotateOrigin + ')')
      .text(function() {
        const selection = d3.select(this);
        return self.chartService.truncateText(label, selection);
      });
  }

  /**
   * Build all the grids including the chart axis and labels.
   */
  buildChartGrid = (): void => {
    this.buildXAxis();
    this.buildYAxis();
    this.buildXAxisGrid();
    this.buildYAxisGrid();

    if (this.xAxisLabel) {
      this.buildXAxisLabel(this.xAxisLabel, this.xAxisLabelDistance);
    }

    if (this.yAxisLabel) {
      this.buildYAxisLabel(this.yAxisLabel, this.yAxisLabelDistance);
    }
  }

  /**
   * Build the chart series legend.
   */
  buildChartLegend = (): void => {
    this.legendConfig.height = this.getChartHeight();
    this.legendConfig.width = this.getChartWidth() - this.controlOffset_H;
    this.legendConfig.controlOffset = this.controlOffset_H;
    this.legendConfig.marginTop = 10;
    this.legendConfig.maxHeight = 50;
    this.legendConfig.showControls = this.showControls;
    this.legendConfig.colorScale = this.scaleC;
    this.legendConfig.callback = (data: any) => {
      if (data && data.series) {
        const seriesData = clone(data.series);
        this.chartData = this.chartData.map((series) => {
          const item = seriesData.filter((d) => d.key === series.key)[0];
          if (item) {
            return {...series, ...{disabled: !!item.disabled }};
          }
          return series;
        });

        this.drawChart();
      }
    };

    if (this.showLegend) {
      this.legendHeight = this.chartLegendService.buildLegend(this.chart.svg, this.legendConfig, this.chartData);
      this.legendSeries = this.chart.svg.selectAll('.xs-legend-item').data();
    }
  }

  /**
   * Build the array of color hex values for a given palette id.
   * @param palette  The color palette id.
   * @return  An array of hex color values.
   */
  colorValues = (palette: string): string[] => {
    const colors = findWhere(this.chartService.getColors(), {id: palette});
    return map(colors.values, (val) => {
      return 'rgb(' + values(d3.rgb(val)).join(',') + ')';
    });
  }

  /**
   * Draw the chart and its axis.
   */
  drawChart = (): void => {
    if (!this.chart) { return; }

    this.setChartDimensions();

    this.chart.svg = d3.select(`#${this.chartId}`);

    const margin = this.margin;
    const width = this.chartWidth;
    const height = this.chartHeight;

    this.chart.svg.attr('width', width)
      .attr('height', height);

    this.addContainers();

    if (this.showControls) {
      this.buildChartControls();
    }

    this.groupNode = this.chart.svg.append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    this.updateChart();
  }

  /**
   * Calculates the chart height.
   * @return  The computed chart height.
   */
  getChartHeight = (): number => {
    const height = this.elementRef.nativeElement.parentNode.clientHeight;
    const innerHeight = height - this.margin.bottom - this.margin.top;
    return innerHeight;
  }

  /**
   * Calculates the chart width.
   * @return  The computed chart width.
   */
  getChartWidth = (): number => {
    const width = this.elementRef.nativeElement.clientWidth;
    return width - this.margin.right - this.margin.left;
  }

  /**
   * Transform the series data using the stack function of D3
   * @param series  The series data to be transformed.
   * @return  The array of data representing the coordinates of each point of the chart.
   */
  getPlotableSerieData = (series: any): any[] => {
    const chartData = [];
    series.values.forEach((value, idx) => {
      const x = this.yFunction()(value);
      const y = this.xFunction()(value);
      const c = this.chartData[idx].key;

      if (!isNull(x) && !isNull(y) && !this.chartData[idx].disabled) {
        chartData.push({x, y, c});
      }
    });

    const keys = Object.keys(chartData);
    const stack = d3.stack().value((d) => d.x).keys(keys);
    return stack(chartData).map((d, i) => {
      let x0 = 0;
      let x1 = 0;
      d.forEach((data) => {
        x0 += data[0] / d.length;
        x1 += data[1] / d.length;
      });

      return {...d[i].data, ...{x0, x1}};
    });
  }

  /**
   * Re-group the chart data per value instead of series
   * So all the bars with the same height (x-value) go in the same bucket
   */
  groupDataByValue = (): void => {
    const chartData = [];
    this.chartData.map((series, i) => {
      series.values.forEach((value) => {
        const name = this.chartData[i].key;
        const key = this.xFunction()(value);
        let newSerie = chartData.filter((item) => item.key === key)[0];
        if (!newSerie) {
          chartData.push({key, name, values: []});
          newSerie = chartData.filter((item) => item.key === key)[0];
        }
        newSerie.values.push(value);
      });
    });

    this.series = chartData;
  }

  /**
   * Responds to the postLink component lifecycle hook.
   */
  ngAfterViewInit() {
    if (!this.chartData) { return; }

    this.xAxisTickFormat = this.chartService.axisTickFormat(this.xAxisPreset);
    this.yAxisTickFormat = this.chartService.axisTickFormat(this.yAxisPreset);

    this.chart.resizeHandler = () => {
      this.window.clearTimeout(this.resizeTimeout);
      this.resizeTimeout = this.window.setTimeout(() => {
        this.drawChart();
      }, 1000);
    };

    this.window.addEventListener('resize', this.chart.resizeHandler);

    if (this.updateEvents && typeof this.updateEvents.subscribe === 'function') {
      this.updateEvents.subscribe(() => {
        this.window.setTimeout(() => {
          this.drawChart();
        }, 500);
      });
    }

    if (this.chartData.length) {
      this.drawChart();
    }
  }

  /**
   * Responds to changes in the component bindings.
   * @param changesObj  The updated object
   */
  ngOnChanges(changesObj: any): void {
    if (changesObj.data && changesObj.data.currentValue.length) {
      this.drawChart();
    }
  }

  /**
   * Responds to the onDestroy component lifecycle hook.
   */
  ngOnDestroy() {
    this.window.removeEventListener('resize', this.chart.resizeHandler);
  }

  /**
   * Responds to the onInit component lifecycle hook.
   */
  ngOnInit() {
    if (!this.showControls) { this.controlOffset_H = 0; }
    this.chart = this.barChartModel();
    this.chartId = DxUtilsService.guid('str-');
    this.legendConfig = {
      callback: this.updateChart,
      margin: this.margin
    };
  }

  /**
   * Determines a position for a tooltip, avoiding the screen edges.
   */
  positionTooltip = (event: any): void => {
    const tooltipDom = document.querySelector(`.${this.tooltipClass}`) as HTMLElement;
    const clientWidth = document.documentElement.clientWidth;
    const height = tooltipDom.clientHeight;
    const width = tooltipDom.clientWidth;
    let left = Math.abs(event.pageX - width / 2);
    let top = event.pageY - height - 30;

    if (left + width > clientWidth) { left -= width / 2; } // off right side
    if (top < 0) { top += height; } // off top

    tooltipDom.style.top = top + 'px';
    tooltipDom.style.left = left + 'px';
  }

  /**
   * Remove the tooltip elements from the dom.
   */
  removeTooltip = (): void => {
    document.querySelectorAll(`.${this.tooltipClass}`)
    .forEach((elt) => elt.parentNode.removeChild(elt));
  }

  /**
   * Calculate the actual width and height of the chart.
   */
  setChartDimensions = (): void => {
    this.chartHeight = this.getChartHeight() - this.xAxisHeight;
    this.chartWidth = this.getChartWidth();
  }

  /**
   * Set the chart series-group, x-axis, y-axis and color scales.
   */
  setChartScales = (): void => {
    this.scaleS = d3.scaleBand()
      .range([0, this.chartHeight])
      .domain(this.sDomain)
      .padding(this.seriesPadding);

    this.scaleX = d3.scaleLinear()
      .domain(this.xDomain)
      .range([0, this.chartWidth]);

    this.scaleY = d3.scaleBand()
      .rangeRound([0, this.scaleS.bandwidth()])
      .domain(this.yDomain)
      .padding(this.seriesPadding);

    this.scaleC = d3.scaleOrdinal()
      .domain(this.chartData.map((d) => d.key))
      .range(this.colorValues(this.color));

    this.buildChartLegend();
    this.buildChartGrid();
  }

  /**
   * Set the chart series-group, x-axis and y-axis domains.
   */
  setDomains = (): void => {
    this.sDomain = this.series.map((d) => d.key);

    if (this.stacked) {
      this.xDomain = [0, d3.max(this.series, (series) => {
        const chartData = this.getPlotableSerieData(series);
        const vals = chartData.map((d) => d.x);

        return d3.sum(vals);
      })];
    } else {

      this.xDomain = [0, d3.max(this.series, (series) => {
        const chartData = this.getPlotableSerieData(series);
        return d3.max(chartData.map((d) => {
          return d.x;
        }));
      })];
    }

    this.yDomain = [];
    this.series.map((series) => {
      const chartData = this.getPlotableSerieData(series);
      return chartData.map((d, idx) =>  {
        this.yDomain.push(idx);
      });
    });

    this.setChartScales();
  }

  /**
   * Draw the the grouped bar chart with transition.
   * @param layer  The D3 bar chart layer.
   */
  transitionToGroup = (layer: any): any => {
    const x = this.scaleX;
    const y = this.scaleY;

    layer
      .transition()
      .attr('y', (d, i) => y(i))
      .text((d) => d.y)
      .attr('height', y.bandwidth())
      .attr('width', (d) =>  {
        return x(d.x);
      });
  }

  /**
   * Draw the the stacked bar chart with transition.
   * @param layer  The D3 bar chart layer.
   */
  transitionToStack = (layer: any): any => {
    const x = this.scaleX;
    const s = this.scaleS;

    layer
      .transition()
      .attr('y', (d, i) => s(this.chartData[i].key))
      .text((d) => d.y)
      .attr('height', s.bandwidth())
      .attr('x', (d) => x(d.x0))
      .attr('width', (d) =>  {
        return x(d.x1) - x(d.x0);
      });
  }

  /**
   * Redraw the chart bars after a change.
   */
  updateChart = (): void => {
    this.groupDataByValue();

    if (!(this.groupNode && this.series && this.series.length > 0)) { return; }

    this.setDomains();

    const s = this.scaleS;
    const c = this.scaleC;
    const vOffset = this.margin.top + (this.showControls && !this.showLegend ? this.controlOffset_V : 0);
    const totalOffset = this.showLegend ? vOffset + this.legendHeight : vOffset;

    const elts = this.groupNode
      .attr('transform', () => {
        return `translate(${this.margin.left},${totalOffset})`;
      })
      .selectAll('g')
      .data(this.series)
      .enter().append('g')
      .attr('transform', (d) => {
        return `translate(0, ${s(d.key)})`;
      })
      .text((d) => d.key);

    const self = this;
    const refresh = (dom) => {
      const layer = dom
        .selectAll('rect')
        .data((series) => this.getPlotableSerieData(series))
        .enter().append('rect')
        .attr('class', 'bar');

      if (this.stacked) {
        this.transitionToStack(layer);
      } else {
        this.transitionToGroup(layer);
      }

      layer.on('mouseover', function(d) {
        self.addTooltip(d);
        self.positionTooltip(d3.event);
        d3.select(this).style('fill', () => {
          return c(d.c);
        });
      })
      .on('mousemove', function() {
        const tip = d3.select(`.${this.tooltipClass}`);
        if (tip) {
          self.positionTooltip(d3.event);
        }
      })
      .on('mouseout', function(d) {
        self.removeTooltip();

        d3.select(this).style('fill', () => {
          return c(d.c);
        });
      })
      .style('fill', (d) => {
        return c(d.c);
      });
    };

    // Update attributes
    refresh(elts.attr('class', 'bar'));

    // Add new nodes
    refresh(elts.enter().append('g'));

    const previousGrpCls = 'previous-nodes';
    this.chart.svg.selectAll(`.${previousGrpCls}`).transition().remove();
    this.groupNode.classed(previousGrpCls, true);
  }

  /**
   * D3 function to get the getter function for the value of the x component of a data point.
   */
  xFunction = (): any => {
    return (d) => {
      return d[0].display;
    };
  }

  /**
   * D3 function to get the getter function for the value of the y component of a data point.
   */
  yFunction = (): any => {
    return (d) => {
      return d[1].display;
    };
  }
}

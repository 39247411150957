import { EventEmitter, Injectable } from '@angular/core';
import { Themes } from './themes.constant';

@Injectable()
export class ThemeService {

  currentTheme: {};
  themeChange = new EventEmitter();

  constructor() {
    if (localStorage.getItem('theme')) {
      this.currentTheme = JSON.parse(localStorage.getItem('theme'));
    } else {
      this.currentTheme = this.getDefaultTheme();
    }
  }

  getDefaultTheme = () => {
    return Themes.filter((theme) => theme.default)[0];
  }

  getTheme = () => {
    return this.currentTheme;
  }

  getThemes = () => {
    return Themes;
  }

  setTheme = (theme) => {
    localStorage.setItem('theme', JSON.stringify(theme));
    this.themeChange.emit(theme);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Components
import { DxAlertComponent } from './dx-alert.component';
import { DxMessagingService } from './dx-messaging.service';

@NgModule({
  declarations: [
    DxAlertComponent
  ],
  entryComponents: [
    DxAlertComponent
  ],
  exports: [
    DxAlertComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    DxMessagingService
  ]
})

export class DxAlertModule {}

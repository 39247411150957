import { find, reject } from 'underscore';
import { ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, Injector } from '@angular/core';

import { DxAlertComponent } from './dx-alert.component';
import { DxOverlayService } from '../dx-overlay/dx-overlay.service';
import { DxUtilsService } from '../dx-utils/dx-utils.service';
import { IDxMessage } from './dx-message.interface';

@Injectable({ providedIn: 'root' })
export class DxMessagingService {

  private systemMessages: Array<ComponentRef<any>> = [];
  private alertComponentFactory: ComponentFactory<DxAlertComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private dxOverlayService: DxOverlayService
  ) {
    this.alertComponentFactory = this.componentFactoryResolver.resolveComponentFactory(DxAlertComponent);
  }

  /**
   * Adds a message to the systemMessage state.
   * @param   type   The message type.
   * @param   title  The message title.
   * @param   text   The complete text of the message.
   * @returns        Undefined.
   */
  addMessage = (type: string, title: string, text: string): void => {
    if (type === 'error') {
      type = 'alert';
    }

    // only allow one message at a time...
    if (this.systemMessages.length > 0) {
      this.destroyAlert(this.systemMessages[0].instance.message._id);
    }

    const message: IDxMessage = {
      _id: DxUtilsService.guid(),
      msg: text,
      title: title,
      type: type
    };

    // create alert-component
    const componentRef = this.dxOverlayService.addOverlay(this.alertComponentFactory, [[]], this.injector);
    componentRef.instance.autoDismiss = true;
    componentRef.instance.customCSS = 'alert-container';
    componentRef.instance.message = message;

    this.systemMessages.push(componentRef);
  }

  /**
   * Destroy the alert component from the page using the overlay service
   * @param   id  The message Id
   * @returns     Undefined.
   */
  destroyAlert = (id: string): void => {
    const componentRef = find(this.systemMessages, (message: ComponentRef<any>) => {
      return message.instance.message._id === id;
    });
    if (componentRef) {
      this.dxOverlayService.removeOverlay(componentRef.hostView);
    }
    this.removeMessage(id);
  }

  /**
   * Destroy all alerts.
   * @returns Undefined.
   */
  destroyAllAlerts = (): void => {
    this.dxOverlayService.removeOverlayByType(DxAlertComponent);
    this.systemMessages = [];
  }

  /**
   * Removes a message from the systemMessage state
   * @param   _id  The _id of the message to remove.
   * @returns      Undefined.
   */
  removeMessage = (_id: string): void => {
    this.systemMessages = reject(this.systemMessages, (messageComponent: ComponentRef<any>) => {
      return messageComponent.instance.message._id === _id;
    });
  }
}

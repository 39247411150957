import { Injectable } from '@angular/core';
import { clone, each, isNull, isObject } from 'underscore';

@Injectable({ providedIn: 'root' })
export class DxUtilsService {

  /**
   * Generates a new globally unique identifier string.
   */
  static guid(prefix: string = '') {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };

    const newGuid = s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    return `${prefix}${newGuid}`;
  }

  constructor() {}

  /**
   * Create a hash from a String
   */
  static createHashFromString(str) {
    const salt = 'x15';

    let hash: any = '0',
        i,
        chr,
        len;

    if (!str) {
      return hash;
    }

    str += salt + str; // Better randomization for short inputs.

    for (i = 0, len = str.length; i < len; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash.toString();
  }

  /**
   * Return the size formatted value of a given number.
   */
  static formatSize(value: any) {
    if (typeof value === 'undefined') {
      return '';
    }
    value = parseFloat(value);
    if (typeof value !== 'number') {
      return '';
    }
    let n = 0;
    while (value >= 1024) {
      value = value / 1024;
      ++n;
    }
    const byteUnits = [' B', ' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    return value.toFixed(1) + byteUnits[n];
  }

  /**
   * Returns the median for an array of values.
   */
  static median(values) {
    values.sort((a, b) => a - b);
    return (values[(values.length - 1) >> 1] + values[values.length >> 1]) / 2;
  }

  /**
   * Gets or sets an object property described by a dotted notation string.
   */
  static propertyByString(object, propString, value) {
    if (!isObject(object)) {
      return object;
    } else if (typeof propString === 'string') {
      return this.propertyByString(object, propString.split('.'), value);
    } else if (propString.length === 1 && value !== undefined) {
      object[propString[0]] = value;
      return object[propString[0]];
    } else if (propString.length === 0) {
      return object;
    } else {
      return this.propertyByString(object[propString[0]], propString.slice(1), value);
    }
  }

  /**
   * Calculate the rough size of a Javascript object.
   */
  static roughSizeOfObject(object) {
    const objectList = [],
          stack = [object];
    let bytes = 0;

    while (stack.length) {
      const value = stack.pop();

      if (typeof value === 'boolean') {
        bytes += 4;
      } else if (typeof value === 'string') {
        bytes += value.length * 2;
      } else if (typeof value === 'number') {
        bytes += 8;
      } else if (typeof value === 'object' && !isNull(value) && objectList.indexOf(value) === -1) {
        objectList.push(value);
        for (const i of Object.keys(value)) {
          stack.push(value[ i ]);
        }
      }
    }
    return bytes;
  }

  /**
   * Creates a deep clone of an object.
   * @param   object  The object to clone.
   * @returns A deep cloned copy of the object.
   */
  deepClone(object: any) {
    function cloneObj(obj: any) {
      const copy = clone(obj);
      each(copy, function(value: any, key: any) {
        if (isObject(value)) {
          copy[key] = cloneObj(value);
        }
      });
      return copy;
    }

    return cloneObj(object);
  }

  /**
   * Truncates a string to a given number of characters.
   * @param text   The text to truncate.
   * @param length The number of characters to allow.
   * @Return  The truncated string.
   */
  truncateToChars(text: string, length: number): string {
    let truncatedText = text;
    if (text && text.length > length) {
      truncatedText = text.slice(0, length) + '…';
    }
    return truncatedText;
  }
}

import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../../common/services/theme.service';

@Component({
  selector: 'app-intro',
  styleUrls: ['./intro.component.scss'],
  templateUrl: './intro.component.html'
})
export class IntroComponent implements OnInit {
  currentTheme: {};

  constructor(private themeService: ThemeService) {}

  ngOnInit() {
    this.currentTheme = this.themeService.getTheme();
    this.themeService.themeChange.subscribe( (resp) => {
      this.currentTheme = resp;
    });
  }
}

import * as moment_ from 'moment-timezone';
import * as d3 from 'd3';
import { contains, escape, findWhere, isObject, map, pluck, reject, sortBy } from 'underscore';

import { Injectable } from '@angular/core';

import { DxUtilsService } from '../dx-utils/dx-utils.service';

const moment = moment_;

@Injectable({ providedIn: 'root' })
export class DataFormatterService {

  private dataTypes = [
    {
      description: 'Type undetermined.',
      formatter: 'text',
      id: 'ANY',
      name: 'Any type',
      parent: false,
      primitive: 'string',
      supported: true,
      type: 'abstract'
    },
    {
      description: 'An array of values of any type.',
      formatter: 'text',
      id: 'ARRAY',
      name: 'Array',
      parent: false,
      primitive: 'string',
      supported: true,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'text',
      id: 'BINARY',
      name: 'Binary',
      parent: false,
      primitive: 'string',
      supported: true,
      type: 'concrete'
    },
    {
      description: 'A true or false value',
      formatter: 'text',
      id: 'BOOLEAN',
      name: 'Boolean',
      parent: false,
      primitive: 'boolean',
      supported: true,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'text',
      id: 'BOX',
      name: 'Box',
      parent: false,
      primitive: 'string',
      supported: false,
      type: 'concrete'
    },
    {
      description: 'A classless inter-domain routing',
      formatter: 'text',
      id: 'CIDR',
      name: 'CIDR',
      parent: false,
      primitive: 'string',
      supported: true,
      type: 'abstract'
    },
    {
      description: '',
      formatter: 'text',
      id: 'CIDR4',
      name: 'CIDR (IPV4)',
      parent: 'CIDR',
      primitive: 'string',
      supported: true,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'text',
      id: 'CIDR6',
      name: 'CIDR (IPV6)',
      parent: 'CIDR',
      primitive: 'string',
      supported: true,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'text',
      id: 'CIRCLE',
      name: 'Circle',
      parent: false,
      primitive: 'string',
      supported: false,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'shortDate',
      id: 'DATE',
      name: 'Date',
      parent: false,
      primitive: 'object',
      supported: true,
      type: 'concrete'
    },
    {
      description: 'A decimal number with user-specified precision. Up to 131072 digits before the decimal point; up to 16383 digits after the decimal point.',
      formatter: 'numberCommaDecimal',
      id: 'DECIMAL',
      name: 'Decimal',
      parent: false,
      primitive: 'number',
      supported: false,
      type: 'concrete'
    },
    {
      description: 'A decimal number with additional formats and keywords such as exponential format and the "NaN" and "Infinity" keywords.',
      formatter: 'numberCommaDecimal',
      id: 'DOUBLE',
      name: 'Double',
      parent: false,
      primitive: 'number',
      supported: true,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'text',
      id: 'DURATION',
      name: 'Duration',
      parent: false,
      primitive: 'string',
      supported: true,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'text',
      id: 'ERROR',
      name: 'Error',
      parent: false,
      primitive: 'string',
      supported: false,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'text',
      id: 'INET',
      name: 'IP address',
      parent: false,
      primitive: 'string',
      supported: true,
      type: 'abstract'
    },
    {
      description: '',
      formatter: 'text',
      id: 'INET4',
      name: 'IP address (IPV4)',
      parent: 'INET',
      primitive: 'string',
      supported: true,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'text',
      id: 'INET6',
      name: 'IP address (IPV6)',
      parent: 'INET',
      primitive: 'string',
      supported: true,
      type: 'concrete'
    },
    {
      description: 'A signed integer value between -127 and 127 or an unsigned integer value between 0 and 255.',
      formatter: 'shortNumber',
      id: 'INT8',
      name: 'Integer (8 bit)',
      parent: 'INTEGER',
      primitive: 'number',
      supported: true,
      type: 'concrete'
    },
    {
      description: 'A signed integer value between -32,768 and 32,768 or an unsigned integer value between 0 and 65,535.',
      formatter: 'shortNumber',
      id: 'INT16',
      name: 'Integer (16 bit)',
      parent: 'INTEGER',
      primitive: 'number',
      supported: true,
      type: 'concrete'
    },
    {
      description: 'A signed integer value between -8,388,608 and 8,388,608 or an unsigned integer value between 0 and 16,777,215.',
      formatter: 'shortNumber',
      id: 'INT24',
      name: 'Integer (24 bit)',
      parent: 'INTEGER',
      primitive: 'number',
      supported: true,
      type: 'concrete'
    },
    {
      description: 'A signed integer value between −2,147,483,648 and 2,147,483,648 or an unsigned integer value between 0 and 4,294,967,295.',
      formatter: 'shortNumber',
      id: 'INT32',
      name: 'Integer (32 bit)',
      parent: 'INTEGER',
      primitive: 'number',
      supported: true,
      type: 'concrete'
    },
    {
      description: 'A signed integer value between -9.22e+18 and 9.22e+18 or an unsigned integer value between 0 and 1.84e+19.',
      formatter: 'shortNumber',
      id: 'INTEGER',
      name: 'Integer',
      parent: false,
      primitive: 'number',
      supported: true,
      type: 'abstract'
    },
    {
      description: '',
      formatter: 'text',
      id: 'INTERVAL',
      name: 'Interval',
      parent: false,
      primitive: 'string',
      supported: false,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'text',
      id: 'JAVA_TYPE',
      name: 'Java',
      parent: false,
      primitive: 'string',
      supported: false,
      type: 'abstract'
    },
    {
      description: '',
      formatter: 'text',
      id: 'LABEL',
      name: 'Label',
      parent: false,
      primitive: 'string',
      supported: false,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'text',
      id: 'LATLON',
      name: 'Latitude/Longitude',
      parent: false,
      primitive: 'string',
      supported: false,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'text',
      id: 'LINE',
      name: 'Line',
      parent: false,
      primitive: 'string',
      supported: false,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'text',
      id: 'LINESEGMENT',
      name: 'Line segment',
      parent: false,
      primitive: 'string',
      supported: false,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'text',
      id: 'MACADDR',
      name: 'MAC address',
      parent: false,
      primitive: 'string',
      supported: true,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'currencyComma',
      id: 'MONEY',
      name: 'Money ($USD)',
      parent: false,
      primitive: 'number',
      supported: true,
      type: 'concrete'
    },
    {
      descripiton: 'A null (empty) value',
      formatter: 'text',
      id: 'NULL',
      name: 'Null',
      parent: false,
      primitive: 'null',
      supported: true,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'text',
      id: 'PATH',
      name: 'Path',
      parent: false,
      primitive: 'string',
      supported: false,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'text',
      id: 'POINT',
      name: 'Point',
      parent: false,
      primitive: 'string',
      supported: false,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'text',
      id: 'RECORD',
      name: 'Record',
      parent: false,
      primitive: 'string',
      supported: true,
      type: 'concrete'
    },
    {
      description: 'A unicode string',
      formatter: 'text',
      id: 'STRING',
      name: 'String',
      parent: false,
      primitive: 'string',
      supported: true,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'shortTime',
      id: 'TIME',
      name: 'Time',
      parent: false,
      primitive: 'string',
      supported: true,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'shortDateTime',
      id: 'TIMESTAMP',
      name: 'Timestamp',
      parent: false,
      primitive: 'string',
      supported: true,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'text',
      id: 'TYPE',
      name: 'Type',
      parent: false,
      primitive: 'string',
      supported: false,
      type: 'concrete'
    },
    {
      description: 'A mix of types.',
      formatter: 'text',
      id: 'UNION',
      name: 'Union',
      parent: false,
      primitive: 'string',
      supported: true,
      type: 'abstract'
    },
    {
      description: 'A 128 bit universally-unique identifier',
      formatter: 'text',
      id: 'UUID',
      name: 'UUID',
      parent: false,
      primitive: 'string',
      supported: true,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'text',
      id: 'VOID',
      name: 'Void',
      parent: false,
      primitive: 'string',
      supported: false,
      type: 'concrete'
    },
    {
      description: '',
      formatter: 'text',
      id: 'XML',
      name: 'XML',
      parent: false,
      primitive: 'string',
      supported: false,
      type: 'concrete'
    }
  ];

  constructor() {}

  /**
   * Attempts to convert temporal data stored as a string into a moment date object wrapper.
   */
  convertStringToMoment = (data: any): any => {
    let asOffset,
        asUTC;
    const isValid = moment(data).isValid();

    if (isValid) {

      asOffset = moment.parseZone(data); // parse expecting a valid timezone offset
      asUTC = moment.utc(data); // parse as UTC

      // If there's no offset from UTC, use the UTC parsing, otherwise use the offset
      if (asOffset.utcOffset() === 0) {
        return asUTC;
      }

      return asOffset;
    }
    return this._shortRound(data);
  }

  /**
   * Returns an array of data type options for use in a dropdown menu.
   */
  dataTypeOptions = (supportLevel: string): any[] => {
    let options: any =  sortBy(this.dataTypes, 'name');

    if (supportLevel === 'parameters') {
      options = reject(options, (dataType: any) => {
        return dataType.parent ||
               !dataType.supported ||
               dataType.id === 'RECORD' ||
               dataType.id === 'ARRAY' ||
               dataType.id === 'ANY' ||
               dataType.id === 'UNION';
      });
    }

    if (supportLevel === 'filters') {
      options = reject(options, (dataType: any) => {
        return !dataType.supported || dataType.id === 'ANY' || dataType.id === 'UNION';
      });
      options.push({
        description: '',
        formatter: 'text',
        id: 'UNION',
        name: 'Select a type',
        parent: false,
        primitive: 'string',
        supported: true,
        type: 'abstract'
      });
    }

    return options;
  }

  /**
   * Formats X15 data objects into JavaScript primitives for display.
   */
  formatAsPrimitive = (dataObj: any): any => {
    let formattedValue;
    const typeObj = findWhere(this.dataTypes, {id: dataObj.type});
    switch (typeObj.primitive) {
      case 'boolean':
        formattedValue = dataObj.value.toLowerCase() === 'true' ? true : false;
        break;
      case 'number':
        formattedValue = dataObj.value.indexOf('.') !== -1 ? parseFloat(dataObj.value) : parseInt(dataObj.value, 10);
        break;
      case 'null':
        formattedValue = null;
        break;
      default:
        formattedValue = escape(dataObj.value);
    }
    return formattedValue;
  }

  /**
   * Returns the data type tag for X15 type objects.
   */
  getDataType = (typeObject: any, nullableUnions?: boolean): string => {
    let types;
    if (typeObject.typeTag === 'UNION' && !nullableUnions) {
      types = this.getUnionTypes(typeObject.choiceTypes);
      if (types.length === 2 && contains(types, 'NULL')) {
        return reject(types, (type) => { return type === 'NULL'; }).toString();
      }
    }
    return typeObject.typeTag;
  }

  /**
   * Returns the human-readable data type name given a data type ID.
   */
  getDataTypeName = (typeId: string): string => {
    return findWhere(this.dataTypes, {id: typeId}).name;
  }

  /**
   * Returns the types contained in a UNION.
   */
  getUnionTypes = (choiceTypes: any[]): any[] => {
    return pluck(choiceTypes, 'typeTag');
  }

  /**
   * Return the user timezone setting or the browser timezone.
   */
  getUserTimezone = (userSettings: any): string => {
    if (!isObject(userSettings)) { userSettings = {}; }
    return userSettings.timezone === 'browser' ? moment.tz.guess() : userSettings.timezone;
  }

  /**
   * Returns a map of data formatter presets.
   */
  presets = (userSettings): any => {
    const service = this;
    const options = {
      bytes: function(d) {
        const asNum = Number(d);
        return isNaN(asNum) ? d : DxUtilsService.formatSize(asNum);
      },
      currency: function(d) {
        const asNum = Number(d);
        return isNaN(asNum) ? d : d3.format('$.2f')(asNum);
      },
      currencyComma: function(d) {
        const asNum = Number(d);
        return isNaN(asNum) ? d : d3.format('$,.2f')(asNum);
      },
      financial: function(d) {
        const asNum = Number(d);
        if (isNaN(asNum)) {
          return d;
        }
        if (d >= 0) {
          return d3.format(',.2f')(d);
        } else {
          return '(' + d3.format(',.2f')(Math.abs(d)) + ')';
        }
      },
      mediumDate: function(d) {
        return moment(d).isValid() ? moment.tz(d, service.getUserTimezone(userSettings)).format('ll') : 'Invalid date';
      },
      mediumDateTime: function(d) {
        return moment(d).isValid() ? moment.tz(d, service.getUserTimezone(userSettings)).format('ll LTS') : 'Invalid date';
      },
      mediumTime: function(d) {
        return moment(d).isValid() ? moment.tz(d, service.getUserTimezone(userSettings)).format('LTS') : 'Invalid time';
      },
      number: function(d) {
        const asNum = Number(d);
        return isNaN(asNum) ? d : d3.format(',d')(Math.round(asNum));
      },
      numberCommaDecimal: function(d) {
        const asNum = Number(d);
        return isNaN(asNum) ? d : d3.format(',.2f')(asNum);
      },
      numberDecimal: function(d) {
        const asNum = Number(d);
        return isNaN(asNum) ? d : d3.format('.2f')(asNum);
      },
      percent: function(d) {
        const asNum = Number(d);
        return isNaN(asNum) ? d : d3.format('.0%')(asNum);
      },
      preciseDateTime: function(d) {
        return moment(d).isValid() ? moment.tz(d, service.getUserTimezone(userSettings)).format('ll hh:mm:ss.SSS A') : 'Invalid date';
      },
      scientificNumber: function(d) {
        const asNum = Number(d);
        return isNaN(asNum) ? d : asNum.toExponential();
      },
      shortDate: function(d) {
        return moment(d).isValid() ? moment.tz(d, service.getUserTimezone(userSettings)).format('l') : 'Invalid date';
      },
      shortDateTime: function(d) {
        return moment(d).isValid() ? moment.tz(d, service.getUserTimezone(userSettings)).format('l LT') : 'Invalid date';
      },
      shortInteger: function(d) {
        return options.shortNumber(Math.round(d));
      },
      shortNumber: function(d) {
        const asNum = Number(d);
        if (isNaN(asNum)) {
          return d;
        }

        if ((asNum / 1000000000) >= 1) {
          return service._shortRound(asNum / 1000000000) + 'b';
        } else if ((asNum / 1000000) >= 1) {
          return service._shortRound(asNum / 1000000) + 'm';
        } else if ((asNum / 1000) >= 1) {
          return service._shortRound(asNum / 1000) + 'k';
        } else {
          return service._shortRound(asNum);
        }
      },
      shortTime: function(d) {
        return moment(d).isValid() ? moment.tz(d, service.getUserTimezone(userSettings)).format('LT') : 'Invalid time';
      },
      text: function(d) {
        return d;
      }
    };
    return options;
  }

  /**
   * Returns a quoted SQL value given a value, correcting for included single quotes.
   */
  quoteValue = (value: string): string => {
    const stringVal = String(value),
          singleQuotes = stringVal.indexOf('\'') > -1 ? '$$' : '\'';
    return singleQuotes + stringVal + singleQuotes;
  }

  /**
   * Recursively removes the "type" property from X15 results record cells.
   */
  stripDataType = (data: any): any => {
    const typeless = { ...data };
    const collapseType = (item) => {
      if (item.type === 'ARRAY') {
        item = map(item.value, (val) => {
          return collapseType(val);
        });
      } else if (item.type === 'RECORD') {
        const temp = { ...item.value },
              keys = Object.keys(item.value);

        keys.forEach((key) => {
          temp[key] = collapseType(temp[key]);
        });
        return temp;
      } else {
        item = this.formatAsPrimitive(item);
      }
      return item;
    };

    return collapseType(typeless);
  }

  /**
   * Rounds a number to 3 significant digits.
   */
  _shortRound = (n: number): number => {
    return Math.round(n * 1000) / 1000;
  }
}

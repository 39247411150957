import { Component, Input } from '@angular/core';

@Component({
  selector: 'mark-up',
  templateUrl: './mark-up.component.html'
})
export class MarkUpComponent {

  @Input() dataModel?;
  @Input() designGuidelines?;
  @Input() exampleTemplateCode?;
  @Input() exampleTypescriptCode?;
  @Input() htmlPath?;
}

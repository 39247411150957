import { Injectable } from '@angular/core';

import { CHART_COLORS } from './chart-colors.constant';
import { DataFormatterService } from '../data-formatter/data-formatter.service';

@Injectable({ providedIn: 'root' })
export class ChartService {

  constructor(
    private dataFormatterService: DataFormatterService
  ) {}

  /**
   * Returns a selection of color palettes for use with dxd3 charts.
   */
  getColors = (): any => {
    return CHART_COLORS;
  }

  /**
   * Truncates a text node to the width specified in its width attribute.
   */
  truncateText = (text: string, selection: any): string => {
    const maxWidth = selection.attr('width');
    const node = selection.node();
    const textCharacterCount = text.length;
    let approxFitCharacters;
    let textAvgCharacterWidth;
    let textLength;
    let truncatedText = text;

    selection.text(text);
    textLength = node.getComputedTextLength();
    textAvgCharacterWidth = textLength / textCharacterCount;
    approxFitCharacters = Math.floor(maxWidth / textAvgCharacterWidth);

    if (textCharacterCount > approxFitCharacters) {
      truncatedText = truncatedText.slice(0, approxFitCharacters);
      selection.text(truncatedText);
      textLength = node.getComputedTextLength();
    }

    while (textLength > maxWidth && textLength > 1) {
      truncatedText = truncatedText.slice(0, -1);
      selection.text(truncatedText);
      textLength = node.getComputedTextLength();
    }

    if (truncatedText.length < text.length) {
      truncatedText = truncatedText.slice(0, -2) + '\u2026';
    }

    return truncatedText;
  }

  /**
   * Axis tick formatter presets.
   */
  axisTickFormat = (preset: any): any => {
    const defaultFormatter = (d) => { return d; },
          userSettings = {};

    return this.dataFormatterService.presets(userSettings)[preset] || defaultFormatter;
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-release-instructions',
  styleUrls: ['./release-instructions.component.scss'],
  templateUrl: './release-instructions.component.html'
})
export class ReleaseInstructionsComponent {
  releaseInstructionsText = {
    description: '',
    title: 'Release and Publish Instructions'
  };
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html'
})
export class PageHeaderComponent {
  @Input() componentName?: string;
  @Input() headerText: any = {};
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-content-return-component',
  templateUrl: './modal-content-return.component.html'
})

export class ModalContentReturnComponent {
  @Input() modal;

  close = (modalOutput: any): void => {
    this.modal.close(modalOutput);
  }

  dismiss = (): void => {
    this.modal.dismiss();
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html'
})
export class OverlayComponent {

  overlayText = {
    title: 'Overlay'
  };

  usage = {
    importStatement: `import { DxOverlayModule } from '@dx/dx-angular-components-lib';`,
    overlayTag: `<dx-overlay-outlet></dx-overlay-outlet>`
  };
}

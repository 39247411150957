export const PIE_CHART_DATA = [
  {
    key: 'jelly',
    y: 125117
  },
  {
    key: 'bavarian creme',
    y: 47019
  },
  {
    key: 'powdered',
    y: 39171
  },
  {
    key: 'cinnamon',
    y: 42006
  },
  {
    key: 'Boston Creme',
    y: 43000
  },
  {
    key: 'coconut',
    y: 49135
  },
  {
    key: 'glazed',
    y: 82618
  },
  {
    key: 'lemon',
    y: 42004
  },
  {
    key: 'strawberry filled',
    y: 43131
  },
  {
    key: 'buttermilk',
    y: 42125
  },
  {
    key: 'old fashioned',
    y: 45706
  }
];

import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[dxAutofocus]'
})

export class DxAutofocusDirective implements AfterViewInit {

  constructor(
    private elementRef: ElementRef
  ) {}

  ngAfterViewInit() {
    setTimeout(
      (): void => {
        this.elementRef.nativeElement.focus();
      }, 200);
  }
}

import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges
} from '@angular/core';

@Directive({
  selector: '[dxDropdownSelectable]'
})
export class DxDropdownSelectableDirective implements OnChanges {
  @Input('dxDropdownSelectable') menu;

  @HostListener('mouseenter')
  onmouseenter() {
    const hoveredEl = document.getElementById(this.menu).querySelector('li.hovered');
    if (hoveredEl) { hoveredEl.classList.remove('hovered'); }

    this.el.nativeElement.classList.add('hovered');
  }

  @HostListener('mouseleave')
  onmouseleave() {
    this.el.nativeElement.classList.remove('hovered');
  }

  constructor(private el: ElementRef) {}

  ngOnChanges(changes) {
    this.menu = changes.menu.currentValue;
  }
}

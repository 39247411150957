import { Component } from '@angular/core';
import { ThemeService } from '../services/theme.service';

@Component({
  selector: 'app-nav-header',
  templateUrl: './app-nav-header.component.html'
})
export class AppNavHeaderComponent {

  currentTheme: any;
  defaultTheme: any;
  themeOptions: any[];
  themeLinkIds: any[];
  dropdowOptions = [
    {
      default: true,
      label: 'Fast & Simple',
      type: 'dark',
      value: 'dx-dark'
    }
  ];

  constructor(private themeService: ThemeService) {
    this.themeLinkIds = ['dx-dark', 'app-dark'];
    this.currentTheme = this.themeService.getTheme();
    this.defaultTheme = this.themeService.getDefaultTheme();
    this.themeOptions = this.themeService.getThemes();
    this.changeTheme(this.currentTheme);
  }

  changeTheme = (theme: any) => {
    this.currentTheme = theme;
    this.themeService.setTheme(this.currentTheme);

    if (theme.value === this.defaultTheme.value) {
      this.themeLinkIds.forEach((id) => {
        const link = document.getElementById(id);
        if (link) {
          link.remove();
        }
      });
    } else {
      this.themeLinkIds.forEach((id) => {
        const newThemelink = document.createElement('link');
        newThemelink.setAttribute('rel', 'stylesheet');
        newThemelink.setAttribute('id', id);
        newThemelink.setAttribute('type', 'text/css');
        newThemelink.setAttribute('href', '/assets/css/' + id + '.css');
        document.getElementsByTagName('head').item(0).appendChild(newThemelink);
      });
    }
  }

  selectTheme = (theme: any) => {
    /*
    if (this.currentTheme !== theme) {
      this.changeTheme(theme);
    }
     */
  }
}

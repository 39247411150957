import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const fadeAnimation =
  trigger('routeAnimations', [
    transition('* <=> *', [
      query(':enter', [ style({ opacity: 0 }) ], { optional: true }),
      query(':leave', [ style({ opacity: 1 }) ], { optional: true }),
      group([
        query(':enter', [
          animate('250ms ease', style({ opacity: 1 }))
        ], { optional: true }),
        query(':leave', [
          animate('250ms ease', style({ opacity: 0 }))
        ], { optional: true })
      ])
    ])
  ]);

import { Component } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

@Component({
  animations: [
    trigger('fadeIn', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(150, style({opacity: 1}))
      ]),
      transition(':leave', [
        animate(1000, style({opacity: 0}))
      ])
    ])
  ],
  selector: 'dx-modal-backdrop',
  template: `
    <div
      class="reveal-modal-bg in"
      style="display: block;"
      [@fadeIn]="'in'"
    ></div>
  `
})
export class DxModalBackdropComponent {}

import {
  ApplicationRef,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  TemplateRef,
  Type
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { find, reject } from 'underscore';

import { DxModalBackdropComponent } from './dx-modal-backdrop.component';
import { DxModalComponent } from './dx-modal.component';
import { DxOverlayService } from '../dx-overlay/dx-overlay.service';
import { DxUtilsService } from '../dx-utils/dx-utils.service';
import { IDxModalConfig } from './dx-modal-config.interface';

@Injectable({ providedIn: 'root' })
export class DxModalService {

  modalStack: BehaviorSubject<Array<ComponentRef<DxModalComponent>>>;
  private backdropComponentFactory: ComponentFactory<DxModalBackdropComponent>;
  private backdropRef: ComponentRef<DxModalBackdropComponent>;
  private modalComponentFactory: ComponentFactory<DxModalComponent>;
  private modalStackSource = [];

  constructor(
    private appRef: ApplicationRef,
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private dxOverlayService: DxOverlayService
  ) {
    this.backdropComponentFactory = this.resolver.resolveComponentFactory(DxModalBackdropComponent);
    this.modalComponentFactory = this.resolver.resolveComponentFactory(DxModalComponent);
    this.modalStack = new BehaviorSubject([]);
  }

  /**
   * Close a given modal.
   * @param  modalId The modal identifier.
   * @return         Whether the modal was closed or not.
   */
  closeModal = (modalId: string): boolean => {
    let modalClosed = false;
    const modalRef = this.getModal(modalId);

    if (modalRef) {
      modalClosed = this.destroyModal(modalRef);
    }
    return modalClosed;
  }

  /**
   * Close all open modals.
   * @return void.
   */
  closeAllModals = (): void => {
    const firstModal = this.modalStackSource[0];
    if (firstModal) {
      firstModal.instance.lastFocusElement.focus();
    }
    this.dxOverlayService.removeOverlayByType(DxModalComponent);
    this.dxOverlayService.removeOverlayByType(DxModalBackdropComponent);
    this.modalStackSource = [];
    this.modalStack.next(this.modalStackSource);
  }

  /**
   * Destroy a given modal.
   * @param  modalRef The modal reference to destroy.
   * @return          Whether the modal was destroyed or not.
   */
  destroyModal = (modalRef: ComponentRef<DxModalComponent>): boolean => {
    let modalDestroyed = false;

    // Restore focus to the previously focused element.
    modalRef.instance.lastFocusElement.focus();

    // Remove the modal component reference from the stack.
    this.modalStackSource = reject(this.modalStackSource, (stackRef) => {
      return stackRef.instance.identifier === modalRef.instance.identifier;
    });

    // Update the stack subject for subscribers.
    this.modalStack.next(this.modalStackSource);

    // Destroy the dynamic component.
    modalDestroyed = this.dxOverlayService.removeOverlay(modalRef.hostView);

    // If there are no more modals, remove the overlay.
    if (!this.modalStackSource.length) {
      this.dxOverlayService.removeOverlay(this.backdropRef.hostView);
    }

    return modalDestroyed;
  }

  /**
   * Get a modal instance by id.
   * @param  modalId The modal identifier.
   * @return         The referenced modal instance or undefined.
   */
  getModal = (modalId: string): ComponentRef<DxModalComponent> => {
    return find(this.modalStackSource, (modalRef) => {
      return modalRef.instance.identifier === modalId;
    });
  }

  /**
   * Open a modal.
   * @param  config The modal configuration object.
   * @return        The opened model component instance.
   */
  openModal = <T>(config: IDxModalConfig<T>): DxModalComponent => {
    const existingModal = this.getModal(config.id);
    const contentIsRef = config.content instanceof TemplateRef;
    let componentRef;
    let contentComponentRef;
    let lastFocusElement;
    let modalContent;
    let modalElement;

    // Modal IDs must be unique.
    if (existingModal) { return existingModal.instance; }

    // Preserve the currently focused element.
    lastFocusElement = document.activeElement;

    // Resolve the modal content based on type.
    if (contentIsRef) {
      modalContent = this.resolveTemplateContent(config.content);
    } else {
      contentComponentRef = this.resolveComponentContent(config);
      modalContent = [[(contentComponentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement]];
    }

    // If this is the first modal, add the overlay component behind it.
    if (!this.modalStackSource.length) {
      this.openModalBackdrop();
    }

    // Create the modal component to inject.
    componentRef = this.dxOverlayService.addOverlay(this.modalComponentFactory, modalContent, this.injector);

    // Set instance properties.
    componentRef.instance.identifier = config.id || DxUtilsService.guid();
    componentRef.instance.index = this.modalStackSource.length;
    componentRef.instance.lastFocusElement = lastFocusElement;
    componentRef.instance.noEscape = !!config.noEscape;
    componentRef.instance.title = config.title;
    componentRef.instance.submitButtonText = config.submitButtonText;
    componentRef.instance.windowClass = config.windowClass;

    // If this is a component content modal pass a reference to the parent modal.
    if (!contentIsRef) {
      contentComponentRef.instance['modal'] = componentRef.instance;
    }

    // Get the dynamic component's DOM element to render.
    modalElement = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // Add the component reference to the stack and attach it to the DOM.
    modalElement.setAttribute('id', `modal-${componentRef.instance.identifier}`);
    this.modalStackSource = [...this.modalStackSource, componentRef];
    this.modalStack.next(this.modalStackSource);

    // Return the component instance so callers can subscribe to the result subject.
    return componentRef.instance;
  }

  /**
   * Open a modal overlay.
   * @return The opened modal overlay component instance.
   */
  openModalBackdrop = (): DxModalBackdropComponent  => {
    const componentRef = this.dxOverlayService.addOverlay(this.backdropComponentFactory, [[]], this.injector);

    this.backdropRef = componentRef;

    return componentRef.instance;
  }

  /**
   * Returns a dynamic component reference given a modal config.
   * @param  config The modal config with the content source to resolve.
   * @return        A dynamic component reference.
   */
  resolveComponentContent = <T>(config: IDxModalConfig<T>): ComponentRef<any> => {
    const content = config.content as Type<T>;
    const resolve = config.resolve;
    const factory = this.resolver.resolveComponentFactory(content);
    const contentComponentRef = factory.create(this.injector);

    // Pass resolve values to instance public properties.
    Object.assign(contentComponentRef.instance, resolve);

    // Attach the dynamic component's hostView to the appRef for change detection.
    this.appRef.attachView(contentComponentRef.hostView);

    return contentComponentRef;
  }

  /**
   * Returns an array of projectable nodes given a TemplateRef.
   * @param  content The content source to resolve.
   * @return         An array of nodes.
   */
  resolveTemplateContent = (content): any[][] => {
    const viewRef = content.createEmbeddedView(null);
    this.appRef.attachView(viewRef);
    return [viewRef.rootNodes];
  }
}

import { Component } from '@angular/core';
import { DxModalService } from 'dx-angular-components-lib';
import { ModalMultistepContentComponent } from './modal-multistep-content.component';

@Component({
  selector: 'app-modal',
  templateUrl: './modal-multistep.component.html'
})
export class ModalMultistepComponent {
  modalDataModel = {
    modalData: {}
  };
  modalText = {
    title: 'Multi-step Modal'
  };

  multistepContentExampleData = {
    currentStep: 0,
    steps: [
      { step: 0, title: 'Step 1' },
      { step: 1, title: 'Step 2' }
    ]
  };

  typescript = {
    modalContentExample:
`import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-multistep-content-example',
  templateUrl: './modal-multistep-content-example.component.html'
})

export class ModalMultistepContentExampleComponent {
  @Input() modal;

  currentStep = 0;
  steps = [
    {
      step: 0,
      title: 'Step 1'
    },
    {
      step: 1,
      title: 'Step 2'
    }
  ];

  close = (modalOutput: any): void => {
    this.modal.close(modalOutput);
  }

  dismiss = (): void {
    this.modal.dismiss();
  }
}`,
    multistep:
`import { Component } from '@angular/core';
import { DxModalService } from '@dx/dx-angular-components-lib';
import { ModalMultistepContentExampleComponent } from './modal-multistep-content-example.component';

@Component({
  selector: 'app-modal-example',
  templateUrl: './modal-example.component.html'
})

export class ModalExampleComponent {
  modalData: {}
  constructor(private dxModalService: DxModalService) {}

  openMultistepModal = (modalData): void => {
    const modalInstance = this.dxModalService.openModal({
      content: ModalMultistepContentComponent,
      windowClass: 'modal-multistep'
    });

    modalInstance.result
      .subscribe((modalOutput) => {
        modalData.modalResults = modalOutput;
      });
  }
}`
  };
  usage = {
    appRootTag: `<app-root></app-root>`,
    importStatement: `import { DxModalModule } from '@dx/dx-angular-components-lib';`,
    overlayTag: `<dx-overlay-component></dx-overlay-component>`
  };

  constructor(private dxModalService: DxModalService) {}

  openMultistepModal = (modalData): void => {
    const modalInstance = this.dxModalService.openModal({
      content: ModalMultistepContentComponent,
      windowClass: 'modal-multistep'
    });

    modalInstance.result
      .subscribe((modalOutput) => {
        modalData.modalResults = modalOutput;
      });
  }

  close = (): void => {
    return;
  }

  dismiss = (): void => {
    return;
  }
}

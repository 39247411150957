import CodeMirror from 'codemirror/lib/codemirror';
import { isArray, isFunction, isObject } from 'underscore';

import 'codemirror/addon/display/autorefresh';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/htmlmixed/htmlmixed';

import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  providers: [NgModel],
  selector: '[dxCodemirror]'
})
export class DxCodemirrorDirective implements AfterViewInit, OnChanges, OnDestroy {
  codemirror;
  iElement;
  modelValueSubscription;

  @Input() dxCodemirror;
  @Input() dxCodemirrorOpts;
  @Input() dxRefresh;

  constructor(private elementRef: ElementRef, private ngModel: NgModel) {
    this.iElement = this.elementRef.nativeElement;
  }

  /**
   * Subscribe for codeMirror value change and set updated value
   */
  configNgModelLink = (): void => {
    this.modelValueSubscription = this.ngModel.valueChanges.subscribe(() => {
      const safeViewValue = this.ngModel.model || '';
      if (safeViewValue !== this.codemirror.getDoc().getValue()) {
        this.codemirror.setValue(safeViewValue);
      }
    });

    this.codemirror.on('change', (instance) => {
      const newValue = instance.getValue();
      if (newValue !== this.ngModel.model) {
        this.ngModel.update.emit(newValue);
      }
    });

    if (isObject(this.ngModel.model) || isArray(this.ngModel.model)) {
      throw new Error('ui-codemirror cannot use an object or an array as a model');
    }

    setTimeout(() => {
      this.codemirror.setValue(this.ngModel.model);
    });
  }

  /**
   * update editor value with code mirror editor contents
   *
   * @param iElement           Dom element
   * @param codeMirrorOptions  Code mirror options
   *
   * @returns                  Editor content
   */
  newCodeMirrorEditor = (iElement: HTMLElement, codeMirrorOptions: any) => {
    let editor;
    if (iElement.tagName === 'TEXTAREA') {
      editor = CodeMirror.fromTextArea(iElement, codeMirrorOptions);
    } else {
      iElement.innerHTML = '';
      editor = CodeMirror((cm_el) => {
        iElement.append(cm_el);
      }, codeMirrorOptions);
    }

    // onLoad is not a CodeMirror property. We have to call the custom callbacks manually.
    if (isFunction(codeMirrorOptions.onLoad)) {
      codeMirrorOptions.onLoad(editor);
    }

    return editor;
  }

  /**
   * Initialize code mirror with updated options
   */
  ngAfterViewInit(): void {
    const codeMirrorOptions = Object.assign({ value: this.ngModel.model }, this.dxCodemirror, this.dxCodemirrorOpts);
    this.codemirror = this.newCodeMirrorEditor(this.iElement, codeMirrorOptions);
    this.configNgModelLink();
  }

  /**
   * Angular OnChanges lifecycle hook.
   * @param changesObj  Angular SimpleChanges object.
   */
  ngOnChanges(changesObj: SimpleChanges): void {
    if (!CodeMirror) {
      throw new Error('ui-codemirror needs CodeMirror to work');
    }

    const codeMirrorDefaultsKeys = Object.keys(CodeMirror.defaults);
    const codeMirrorOptions = changesObj.dxCodemirror || changesObj.dxCodemirrorOpts;
    const codeMirrorAttr = codeMirrorOptions ? codeMirrorOptions.currentValue : '';

    if (isObject(codeMirrorAttr) && this.codemirror) {
      codeMirrorDefaultsKeys.forEach((key) => {
        if (codeMirrorAttr.hasOwnProperty(key)) {
          this.codemirror.setOption(key, codeMirrorAttr[key]);
        }
      });
    }

    const dxRefreshAttr = changesObj.dxRefresh ? changesObj.dxRefresh.currentValue : false;

    if (dxRefreshAttr) {
      setTimeout(() => {
        this.codemirror.refresh();
      });
    }
  }

  /**
   * Angular OnDestroy lifecycle hook.
   * Unsubscribe from model value subscription
   */
  ngOnDestroy(): void {
    this.modelValueSubscription.unsubscribe();
  }
}

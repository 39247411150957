import {
  Directive,
  ElementRef,
  HostListener,
  Input
} from '@angular/core';

@Directive({
  selector: '[dxIsolateScrolling]'
})
export class DxIsolateScrollingDirective {

  element: HTMLElement;

  @Input('dxIsolateScrolling') scrollAssignTo;

  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.mouseWheelFunc(event);
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.mouseWheelFunc(event);
  }

  constructor(private el: ElementRef) {
    this.element = this.el.nativeElement;
  }

  /**
   * Isolate scrolling of element
   * @param e  The scroll event.
   */
  mouseWheelFunc = (e) => {
    const direction = e.wheelDelta ? e.wheelDelta : -e.detail;

    try {
      if (e.deltaY !== 0) {
        let gridData;
        if (this.scrollAssignTo) {
          gridData = this.element.querySelector(this.scrollAssignTo);
        } else {
          gridData = this.element;
        }

        const clientHeight = gridData.clientHeight;
        const scrollHeight = gridData.scrollHeight;
        const scrollTop = gridData.scrollTop;

        if ((scrollTop === (scrollHeight - clientHeight) && direction < 0) || (scrollTop === 0 && direction > 0)) {
          e.preventDefault();
        }
      }
    } catch (e) {
      console.warn('IsolateScrolling Directive: invalid target element querySelector: ', this.scrollAssignTo);
    }
  }
}

import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { filter, map } from 'rxjs/operators';
import { ThemeService } from './common/services/theme.service';
import { fadeAnimation } from './common/animations/route-animations';

@Component({
  animations: [ fadeAnimation ],
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  currentTheme: any = {};
  currentRoute: string;
  isRightNavNested = false;
  rightNav: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private themeService: ThemeService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.currentTheme = this.themeService.getTheme();
    this.themeService.themeChange.subscribe( (resp) => {
      this.currentTheme = resp;
    });

    const viewTitle = this.titleService.getTitle();

    this.router
      .events.pipe(
        filter((event) => event instanceof NavigationEnd)
      ).subscribe(() => {
        const child = this.activatedRoute.firstChild;
        const view = viewTitle + ' | FireEye';
        let title;

        this.currentRoute = child.snapshot.routeConfig.path;

        if (child.snapshot.data['title']) {
          title = child.snapshot.data['title'] + ' | ' + view;
        } else {
          title = view;
        }
        this.titleService.setTitle(title);
      });
  }

  openRightNav = (rightNavObj: any) => {
    this.rightNav = rightNavObj;

    // check if the right nav is nested
    if (rightNavObj && rightNavObj.fragments.length > 0) {
      this.isRightNavNested = rightNavObj.fragments.some((fragment) => fragment.fragments.length > 0);
    }

  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patterns',
  templateUrl: './patterns.component.html'
})
export class PatternsComponent implements OnInit {

  patternsText = {
    title: 'Patterns'
  };

  constructor() { }

  ngOnInit() {
  }

}

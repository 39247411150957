import { Component, TemplateRef, ViewChild } from '@angular/core';
import { DxModalService } from 'dx-angular-components-lib';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})
export class ModalTemplateDrivenComponent {
  idxModalConfigProperties = [
    {
      description: 'The content that will be projected into the modal.',
      name: 'content',
      required: 'required',
      type: 'DxModalContent<T>'
    },
    {
      description: 'An ID that can be used to close the modal remotely.',
      name: 'id',
      required: 'optional',
      type: 'string'
    },
    {
      default: 'false',
      description: 'Whether the modal can be closed with the esc key or not.',
      name: 'noEscape',
      required: 'optional',
      type: 'boolean'
    },
    {
      description: 'A data object to pass into the modal.',
      name: 'resolve',
      required: 'optional',
      type: 'any'
    },
    {
      description: 'If supplied, will show a footer with a submit button using the given text.',
      name: 'submitButtonText',
      required: 'optional',
      type: 'string'
    },
    {
      description: 'If supplied, will show a header with the given text.',
      name: 'title',
      required: 'optional',
      type: 'string'
    },
    {
      default: '"medium"',
      description: 'Classes to customize modal appearance.',
      name: 'windowClass',
      possibleValues: 'tiny, small, default: medium, large,  xlarge, modal-multistep',
      required: 'optional',
      type: 'string'
    }
  ];
  modalText = {
    description: 'Template driven modals are modals that contain content from an ng-template in the DOM.',
    title: 'Modal'
  };
  typescript =
`import { Component } from '@angular/core';
import { DxModalService } from '@dx/dx-angular-components-lib';

@Component({
  selector: 'app-modal-example',
  templateUrl: './modal-example.component.html'
})

export class ModalExampleComponent {
  @ViewChild('tpl') tpl: TemplateRef<any>;

  constructor(private dxModalService: DxModalService) {}

  openTemplateModal = <T>(template: TemplateRef<T>, obj?: any): void => {
    let modalConfig = {
      content: template
    };

    if (obj) {
      modalConfig = {...modalConfig, ...obj};
    }

    this.dxModalService.openModal(modalConfig);
  }
}`;
  usage = {
    importStatement: `import { DxModalModule } from '@dx/dx-angular-components-lib';`
  };

  @ViewChild('tpl', { static: false }) tpl: TemplateRef<any>;

  constructor(private dxModalService: DxModalService) {}

  openTemplateModal = <T>(template: TemplateRef<T>, obj?: any): void => {
    let modalConfig = {
      content: template
    };

    if (obj) {
      modalConfig = {...modalConfig, ...obj};
    }

    this.dxModalService.openModal(modalConfig);
  }
}

// Angular
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

// Third-party Libraries
import * as d3 from 'd3';
import { clone, findWhere, isArray, isEqual, map, values } from 'underscore';

// Internal Dependencies
import { ChartLegendService } from '../chart-legend.service';
import { ChartService } from '../chart.service';
import { DxUtilsService } from '../../dx-utils/dx-utils.service';
import { DxWindowService } from '../../dx-utils/dx-window.service';
import { IChartMargins } from '../../dx-utils/dx-types';

@Component({
  selector: 'dx-pie-chart',
  template: `<svg class="dxd3 dxd3-svg dx-chart dx-pie-chart" [id]="chartId"></svg>`
})
export class DxPieChartComponent implements AfterViewInit, OnChanges, OnDestroy, OnInit {

  @Input() color: string;
  @Input() chartData: any[];
  @Input() donutRatio = 0;
  @Input() margin: IChartMargins = {left: 60, right: 20, top: 20, bottom: 70};
  @Input() showLabels: boolean;
  @Input() showLegend: boolean;
  @Input() valueFormatterPreset: any;

  @Output() clickAction: EventEmitter<any> = new EventEmitter();

  chart: any;
  chartId: string;
  chartHeight: number;
  chartWidth: number;
  controlOffset_H = 10;
  currentArcs: any = {};
  donut: boolean;
  groupNode: any;
  hostClass = 'widget-chart-visualization flex-column';
  legendConfig: any;
  legendHeight = 0;
  legendSeries: any[] = [];
  pieChartData: any[];
  resizeTimeout: number;
  colorScale: any;
  reduceSeries: EventEmitter<any> = new EventEmitter();
  reduceSeriesData: any = {};
  selectedSeries: any[];
  tooltipClass = 'pie-tooltip';
  window: Window;

  constructor(
    private chartLegendService: ChartLegendService,
    private chartService: ChartService,
    private dxWindowService: DxWindowService,
    private elementRef: ElementRef,
    private utilsService: DxUtilsService
  ) {
    this.window = this.dxWindowService.nativeWindow;
  }

  /**
   * Limits the pie series length by taking the top 10 and aggregating rest as "Others".
   * @param   pieSeries   The series data to be truncated.
   * @param   othersKey   The new series key.
   * @param   maxSlices   The maximum number of series slices to draw
   * @return              The truncated series data
   */
  addOthersSliceToPieSeries = (pieSeries: any[], othersKey: string, maxSlices: number): any[] => {
    let others;
    let otherSeries;

    if (!pieSeries || !isArray(pieSeries) || pieSeries.length <= maxSlices) { return pieSeries; }

    const total = pieSeries.reduce((sum, series) => { return sum + series.y; }, 0);

    others = pieSeries.splice(maxSlices - 1);
    otherSeries = {
      key: othersKey,
      y: 0
    };

    others.forEach((series) => {
      const y = series.y;
      otherSeries.y += y;
      series.percent = y / total;
    });

    otherSeries.detail = clone(others);
    pieSeries.push(otherSeries);

    return pieSeries;
  }

  /**
   * Adds a tooltip to a node.
   * @param data The pie slice data.
   */
  addTooltip = (data: any[]): void => {
    // Remove any previous tooltip
    this.removeTooltip();

    d3.select('body')
      .append('div')
      .attr('class', `dxtooltip ${this.tooltipClass}`);

    const tooltip = d3.select(`.${this.tooltipClass}`);

    tooltip
      .style('position', 'absolute')
      .style('visibility', 'visible')
      .html(this.buildTooltip(data));
  }

  /**
   * Builds the pie-chart arc tooltip content.
   * @param   data      The hovered pie slice data to build the tooltip from.
   * @param   maxItems  The maximum number of truncated items to display in the tooltip.
   * @return            The tooltip DOM content
   */
  buildTooltip = (d: any, maxItems = 10): string => {
    const data = d.data;
    const otherSeries = (data.detail || []);
    const valueFormat = this.chartService.axisTickFormat(this.valueFormatterPreset);
    const remainingCount = otherSeries.length - maxItems;
    const more = remainingCount > 0 ? `and ${remainingCount} more` : '';
    let key = data.key;

    let content = '';
    otherSeries.slice(0, maxItems).forEach((series) => {
      content +=
        `<tr>
          <td></td>
          <td>${this.utilsService.truncateToChars(series.key, 50)}</td>
          <td>${valueFormat(series.y)}</td>
          <td>(${(parseInt(String(10000 * parseFloat(series.percent)), 10)) / 100}%)</td>
        </tr>`;
    });

    if (otherSeries.length) { key = 'Others'; }

    const template = `
      <table>
        <tr>
          <td colspan="2" class="legend-color-guide" valign="top">
            <table>
              <tr>
                <td>
                  <div style="display: inline-block; background-color: ${this.colorScale(data.key)};"></div>
                </td>
                <td>
                  ${this.utilsService.truncateToChars(key, 50)}
                </td>
                <td>
                  <b>${valueFormat(data.y)}</b>
                </td>
                <td>(${(parseInt(String(10000 * parseFloat(data.percent)), 10)) / 100}%)</td>
              </tr>
              ${content}
              <tr>
              <td colspan="4" align="center">${more}</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>`;
    return template;
  }

  /**
   * Build the chart series legend.
   */
  buildChartLegend = (): void => {
    this.legendConfig.height = this.getChartHeight();
    this.legendConfig.width = this.getChartWidth() - this.controlOffset_H;
    this.legendConfig.controlOffset = this.controlOffset_H;
    this.legendConfig.marginTop = 10;
    this.legendConfig.maxHeight = 50;
    this.legendConfig.margin = {left: 300};
    this.legendConfig.colorScale = this.colorScale;
    this.legendConfig.reduceSeriesCallback = (data: {key: string, maxSlices: number}) => {
      this.reduceSeries.emit(data);
    };

    this.legendConfig.callback = (data: any) => {
      if (data && data.series) {
        const seriesData = clone(data.series);
        this.pieChartData = this.pieChartData.map((series) => {
          const item = seriesData.filter((d) => d.key === series.key)[0];
          if (item) {
            return {...series, ...{disabled: !!item.disabled }};
          }
          return series;
        });
        this.updateChart();
      }
    };

    if (this.showLegend) {
      this.legendHeight = this.chartLegendService.buildLegend(this.chart.svg, this.legendConfig, clone(this.chartData));
      this.legendSeries = this.chart.svg.selectAll('.xs-legend-item').data();
    }
  }

  /**
   * Returns the array of color hex values for a given palette id.
   * @param   palette   The color palette id.
   * @return            An array of hex color values.
   */
  colorValues = (palette: string): string[] => {
    const colors = findWhere(this.chartService.getColors(), {id: palette});
    return map(colors.values, (val) => {
      return 'rgb(' + values(d3.rgb(val)).join(',') + ')';
    });
  }

  /**
   * Draw the pie chart.
   */
  drawChart = (): void => {
    if (!this.chart) { return; }

    this.chart.svg = d3.select(`#${this.chartId}`);

    // color range
    this.colorScale = d3.scaleOrdinal().range(this.colorValues(this.color));

    this.setChartDimensions();
    this.buildChartLegend();

    const vOffset = this.margin.top;
    const totalOffset = this.showLegend ? vOffset + this.legendHeight : vOffset;

    this.chart.svg.attr('width', this.chartWidth).attr('height', this.chartHeight);

    this.groupNode = this.chart.svg.append('g')
      .attr('class', 'dxd3 dx-wrap dx-pieChart')
      .attr('transform', () => {
        return `translate(${this.margin.left},${totalOffset})`;
      });

    this.updateChart();
  }

  /**
   * Explode the pie slice on click.
   * @param   data  The corresponding chart slice data.
   * @return        The tooltip DOM content
   */
  explodeChartSlice = (data: any): string => {
    d3.selectAll('.exploded-arc')
      .attr('transform', 'translate(0, 0)');

    if ( this.selectedSeries === data) {
      this.selectedSeries = null;
      return '';
    }

    this.selectedSeries = data;
    const offset = 10;
    const angle = (data.startAngle + data.endAngle) / 2;
    const xOff = Math.sin(angle) * offset;
    const yOff = -Math.cos(angle) * offset;
    return 'translate(' + xOff + ',' + yOff + ')';
  }

  /**
   * Calculates the chart height.
   * @return  The computed chart height.
   */
  getChartHeight = (): number => {
    const height = this.elementRef.nativeElement.parentNode.clientHeight;
    const innerHeight = height - this.margin.bottom - this.margin.top;
    return innerHeight;
  }

  /**
   * Calculates the chart width.
   * @return  The computed chart width.
   */
  getChartWidth = (): number => {
    const width = this.elementRef.nativeElement.clientWidth;
    return width - this.margin.right - this.margin.left;
  }

  /**
   * Responds to the postLink component lifecycle hook.
   */
  ngAfterViewInit() {
    if (!this.pieChartData) { return; }

    this.reduceSeries.subscribe((data: {key: string, maxSlices: number}) => {
      if (data && !isEqual(data, this.reduceSeriesData)) {
        const series = clone(this.chartData);
        this.reduceSeriesData = data;
        this.pieChartData = this.addOthersSliceToPieSeries(series, data.key, data.maxSlices);
      }
    });

    this.elementRef.nativeElement.setAttribute('class', this.hostClass);

    this.chart.resizeHandler = (): void => {
      this.window.clearTimeout(this.resizeTimeout);
      this.resizeTimeout = this.window.setTimeout(() => {
        this.pieChartData = this.chartData;
        this.drawChart();
      }, 1000);
    };

    this.window.addEventListener('resize', this.chart.resizeHandler);

    if (this.pieChartData.length) {
      this.drawChart();
    }
  }

  /**
   * Responds to changes in the component bindings.
   * @param changesObj  The updated object
   */
  ngOnChanges(changesObj: any) {
    if (changesObj.data && changesObj.data.currentValue.length) {
      this.drawChart();
    }
  }

  /**
   * Responds to the onDestroy component lifecycle hook.
   */
  ngOnDestroy() {
    this.reduceSeries.unsubscribe();
    this.window.removeEventListener('resize', this.chart.resizeHandler);
  }

  /**
   * Responds to the onInit component lifecycle hook.
   */
  ngOnInit() {
    this.chartId = DxUtilsService.guid('str-');
    this.pieChartData = this.chartData;
    this.chart = this.pieChartModel();
    this.legendConfig = {
      callback: this.updateChart,
      margin: this.margin
    };

    this.donut = this.donutRatio > 0;
  }

  /**
   * Provide the chart object model.
   * @return  The chart object model.
   */
  pieChartModel = (): any => {
    return {
      svg: {},
      update: () => {
        return this.drawChart();
      },
      xAxis: {},
      xAxisGrid: {},
      yAxis: {},
      yAxisGrid: {}
    };
  }

  /**
   * Determines a position for a tooltip, avoiding the screen edges.
   */
  positionTooltip = (event): void => {
    const tooltipDom = document.querySelector(`.${this.tooltipClass}`) as HTMLElement;
    const clientWidth = document.documentElement.clientWidth;
    const height = tooltipDom.clientHeight;
    const width = tooltipDom.clientWidth;
    let left = Math.abs(event.pageX - width / 2);
    let top = event.pageY - height - 30;

    if (left + width > clientWidth) { left -= width / 2; }
    if (top < 0) { top += height; }

    tooltipDom.style.top = top + 'px';
    tooltipDom.style.left = left + 'px';
  }

  /**
   * Remove the tooltip elements from the dom.
   */
  removeTooltip = (): void => {
    document.querySelectorAll(`.${this.tooltipClass}`)
      .forEach((elt) => elt.parentNode.removeChild(elt));
  }

  /**
   * Calculate the actual width and height of the chart.
   */
  setChartDimensions = (): void => {
    this.chartHeight = this.getChartHeight();
    this.chartWidth = this.getChartWidth();
  }

  /**
   * Redraw the chart after a change.
   */
  updateChart = (): void => {
    const radius = (this.chartHeight < this.chartWidth ? this.chartHeight : this.chartWidth) / 2,
          labelRadius = radius - 5,
          donutRadius = this.donut && this.donutRatio ? radius * this.donutRatio : 0,
          total = this.pieChartData.reduce((sum, series) => { return sum + series.y; }, 0),
          data = this.pieChartData.map((series) => {
            const y = series.disabled === true ? 0 : series.y;
            const percent = y / total;
            return {...series, ...{percent, y }};
          }),
          easingSpeed = 150,
          self = this;

    // Remove existing arcs
    this.chart.svg.selectAll('.arc').remove();

    const arc = d3.arc()
      .outerRadius(radius - 10)
      .innerRadius(donutRadius);

    const arcOver = d3.arc()
      .outerRadius(radius - 5)
      .innerRadius(donutRadius);

    // arc for the labels position
    const labelArc = d3.arc()
      .outerRadius(labelRadius)
      .innerRadius(labelRadius);

    // Helper function for arc transition effect
    const arcTween = (d) => {
      d.endAngle = isNaN(d.endAngle) ? 0 : d.endAngle;
      d.startAngle = isNaN(d.startAngle) ? 0 : d.startAngle;

      if (!this.donutRatio) { d.innerRadius = 0; }
      const i = d3.interpolate(self.currentArcs[d.data.key], d);
      self.currentArcs[d.data.key] = i(1);

      return (t) => {
        return arc(i(t));
      };
    };

    const pie = d3.pie()
      .sort(null)
      .value((d) => d.y);

    // define the svg for pie chart
    const svg = this.groupNode
      .attr('width', this.chartWidth)
      .attr('height', this.chartHeight)
      .append('g')
      .attr('transform', 'translate(' + this.chartWidth / 2 + ',' + this.chartHeight / 2 + ')');

    const g = svg.selectAll('.arc')
        .data(pie(data))
        .enter().append('g')
        .attr('class', 'arc');

    const path = g.append('path')
        .attr('d', arc)
        .style('fill', (d) => { return this.colorScale(d.data.key); });

    path.on('mouseenter', function(d) {
      self.addTooltip(d);
      self.positionTooltip(d3.event);

      d3.select(this)
        .attr('stroke', 'white')
        .transition()
        .duration(200)
        .attr('d', arcOver)
        .attr('stroke-width', 1);
    })
    .on('mousemove', function() {
      const tip = d3.select(`.${this.tooltipClass}`);
      if (tip) {
        self.positionTooltip(d3.event);
      }
    })
    .on('mouseout', function(d) {
      self.removeTooltip();
    })
    .on('mouseleave', function() {
      d3.select(this).transition()
        .duration(200)
        .attr('d', arc)
        .attr('stroke', 'none');
    })
    .on('click', function(d) {

      self.clickAction.emit(d);

      d3.select(this)
        .transition()
        .duration(200)
        .attr('class', 'exploded-arc')
        .attr('transform', self.explodeChartSlice);
    }).each(function(d) {
      const key = d.data.key;
      if (!self.currentArcs[key]) { self.currentArcs[key] = d; }
    });

    path.transition()
    .ease(d3.easeLinear)
    .duration(easingSpeed)
    .attrTween('d', arcTween);

    if (this.showLabels) {
      g.append('text')
        .attr('class', 'arc-label')
        .attr('x', (d) => {
          const centroid = labelArc.centroid(d);
          const midAngle = Math.atan2(centroid[1], centroid[0]);
          const x = Math.cos(midAngle) * labelRadius;
          const sign = (x > 0) ? 1 : -1;
          const labelX = x + (5 * sign);
          return labelX;
        })
        .attr('y', (d) => {
          const centroid = labelArc.centroid(d);
          const midAngle = Math.atan2(centroid[1], centroid[0]);
          const y = Math.sin(midAngle) * labelRadius;
          return y;
        })
        .attr('text-anchor', (d) => {
          const centroid = labelArc.centroid(d);
          const midAngle = Math.atan2(centroid[1], centroid[0]);
          const x = Math.cos(midAngle) * labelRadius;
          return (x > 0) ? 'start' : 'end';
        }).text((d) => {
          return !d.data.disabled ? d.data.key : null;
        });
    }
  }
}

export const BAR_CHART_DATA = [
  {
    key: 'Boston Creme',
    values: [
      [
        {
          badData: false,
          display: 'info',
          type: 'STRING',
          value: 'info'
        },
        {
          badData: false,
          display: 4084,
          type: 'INTEGER',
          value: '4084'
        }
      ],
      [
        {
          badData: false,
          display: 'mil',
          type: 'STRING',
          value: 'mil'
        },
        {
          badData: false,
          display: 5177,
          type: 'INTEGER',
          value: '5177'
        }
      ],
      [
        {
          badData: false,
          display: 'biz',
          type: 'STRING',
          value: 'biz'
        },
        {
          badData: false,
          display: 6949,
          type: 'INTEGER',
          value: '6949'
        }
      ],
      [
        {
          badData: false,
          display: 'name',
          type: 'STRING',
          value: 'name'
        },
        {
          badData: false,
          display: 768,
          type: 'INTEGER',
          value: '768'
        }
      ],
      [
        {
          badData: false,
          display: 'org',
          type: 'STRING',
          value: 'org'
        },
        {
          badData: false,
          display: 2059,
          type: 'INTEGER',
          value: '2059'
        }
      ],
      [
        {
          badData: false,
          display: 'gov',
          type: 'STRING',
          value: 'gov'
        },
        {
          badData: false,
          display: 9124,
          type: 'INTEGER',
          value: '9124'
        }
      ],
      [
        {
          badData: false,
          display: 'com',
          type: 'STRING',
          value: 'com'
        },
        {
          badData: false,
          display: 7293,
          type: 'INTEGER',
          value: '7293'
        }
      ],
      [
        {
          badData: false,
          display: 'edu',
          type: 'STRING',
          value: 'edu'
        },
        {
          badData: false,
          display: 5446,
          type: 'INTEGER',
          value: '5446'
        }
      ],
      [
        {
          badData: false,
          display: 'net',
          type: 'STRING',
          value: 'net'
        },
        {
          badData: false,
          display: 2100,
          type: 'INTEGER',
          value: '2100'
        }
      ]
    ],
    x: 'sold',
    y: 'domain'
  },
  {
    key: 'bavarian creme',
    values: [
      [
        {
          badData: false,
          display: 'info',
          type: 'STRING',
          value: 'info'
        },
        {
          badData: false,
          display: 5216,
          type: 'INTEGER',
          value: '5216'
        }
      ],
      [
        {
          badData: false,
          display: 'mil',
          type: 'STRING',
          value: 'mil'
        },
        {
          badData: false,
          display: 5843,
          type: 'INTEGER',
          value: '5843'
        }
      ],
      [
        {
          badData: false,
          display: 'biz',
          type: 'STRING',
          value: 'biz'
        },
        {
          badData: false,
          display: 5356,
          type: 'INTEGER',
          value: '5356'
        }
      ],
      [
        {
          badData: false,
          display: 'name',
          type: 'STRING',
          value: 'name'
        },
        {
          badData: false,
          display: 6167,
          type: 'INTEGER',
          value: '6167'
        }
      ],
      [
        {
          badData: false,
          display: 'org',
          type: 'STRING',
          value: 'org'
        },
        {
          badData: false,
          display: 2162,
          type: 'INTEGER',
          value: '2162'
        }
      ],
      [
        {
          badData: false,
          display: 'gov',
          type: 'STRING',
          value: 'gov'
        },
        {
          badData: false,
          display: 5266,
          type: 'INTEGER',
          value: '5266'
        }
      ],
      [
        {
          badData: false,
          display: 'com',
          type: 'STRING',
          value: 'com'
        },
        {
          badData: false,
          display: 8165,
          type: 'INTEGER',
          value: '8165'
        }
      ],
      [
        {
          badData: false,
          display: 'edu',
          type: 'STRING',
          value: 'edu'
        },
        {
          badData: false,
          display: 3246,
          type: 'INTEGER',
          value: '3246'
        }
      ],
      [
        {
          badData: false,
          display: 'net',
          type: 'STRING',
          value: 'net'
        },
        {
          badData: false,
          display: 5598,
          type: 'INTEGER',
          value: '5598'
        }
      ]
    ],
    x: 'sold',
    y: 'domain'
  },
  {
    key: 'buttermilk',
    values: [
      [
        {
          badData: false,
          display: 'info',
          type: 'STRING',
          value: 'info'
        },
        {
          badData: false,
          display: 5099,
          type: 'INTEGER',
          value: '5099'
        }
      ],
      [
        {
          badData: false,
          display: 'mil',
          type: 'STRING',
          value: 'mil'
        },
        {
          badData: false,
          display: 5210,
          type: 'INTEGER',
          value: '5210'
        }
      ],
      [
        {
          badData: false,
          display: 'biz',
          type: 'STRING',
          value: 'biz'
        },
        {
          badData: false,
          display: 5616,
          type: 'INTEGER',
          value: '5616'
        }
      ],
      [
        {
          badData: false,
          display: 'name',
          type: 'STRING',
          value: 'name'
        },
        {
          badData: false,
          display: 4723,
          type: 'INTEGER',
          value: '4723'
        }
      ],
      [
        {
          badData: false,
          display: 'org',
          type: 'STRING',
          value: 'org'
        },
        {
          badData: false,
          display: 5181,
          type: 'INTEGER',
          value: '5181'
        }
      ],
      [
        {
          badData: false,
          display: 'gov',
          type: 'STRING',
          value: 'gov'
        },
        {
          badData: false,
          display: 7982,
          type: 'INTEGER',
          value: '7982'
        }
      ],
      [
        {
          badData: false,
          display: 'com',
          type: 'STRING',
          value: 'com'
        },
        {
          badData: false,
          display: 2422,
          type: 'INTEGER',
          value: '2422'
        }
      ],
      [
        {
          badData: false,
          display: 'edu',
          type: 'STRING',
          value: 'edu'
        },
        {
          badData: false,
          display: 3267,
          type: 'INTEGER',
          value: '3267'
        }
      ],
      [
        {
          badData: false,
          display: 'net',
          type: 'STRING',
          value: 'net'
        },
        {
          badData: false,
          display: 2625,
          type: 'INTEGER',
          value: '2625'
        }
      ]
    ],
    x: 'sold',
    y: 'domain'
  },
  {
    key: 'cinnamon',
    values: [
      [
        {
          badData: false,
          display: 'info',
          type: 'STRING',
          value: 'info'
        },
        {
          badData: false,
          display: 3999,
          type: 'INTEGER',
          value: '3999'
        }
      ],
      [
        {
          badData: false,
          display: 'mil',
          type: 'STRING',
          value: 'mil'
        },
        {
          badData: false,
          display: 4837,
          type: 'INTEGER',
          value: '4837'
        }
      ],
      [
        {
          badData: false,
          display: 'biz',
          type: 'STRING',
          value: 'biz'
        },
        {
          badData: false,
          display: 1712,
          type: 'INTEGER',
          value: '1712'
        }
      ],
      [
        {
          badData: false,
          display: 'name',
          type: 'STRING',
          value: 'name'
        },
        {
          badData: false,
          display: 4930,
          type: 'INTEGER',
          value: '4930'
        }
      ],
      [
        {
          badData: false,
          display: 'org',
          type: 'STRING',
          value: 'org'
        },
        {
          badData: false,
          display: 6973,
          type: 'INTEGER',
          value: '6973'
        }
      ],
      [
        {
          badData: false,
          display: 'gov',
          type: 'STRING',
          value: 'gov'
        },
        {
          badData: false,
          display: 7025,
          type: 'INTEGER',
          value: '7025'
        }
      ],
      [
        {
          badData: false,
          display: 'com',
          type: 'STRING',
          value: 'com'
        },
        {
          badData: false,
          display: 2703,
          type: 'INTEGER',
          value: '2703'
        }
      ],
      [
        {
          badData: false,
          display: 'edu',
          type: 'STRING',
          value: 'edu'
        },
        {
          badData: false,
          display: 6939,
          type: 'INTEGER',
          value: '6939'
        }
      ],
      [
        {
          badData: false,
          display: 'net',
          type: 'STRING',
          value: 'net'
        },
        {
          badData: false,
          display: 2888,
          type: 'INTEGER',
          value: '2888'
        }
      ]
    ],
    x: 'sold',
    y: 'domain'
  },
  {
    key: 'coconut',
    values: [
      [
        {
          badData: false,
          display: 'info',
          type: 'STRING',
          value: 'info'
        },
        {
          badData: false,
          display: 3900,
          type: 'INTEGER',
          value: '3900'
        }
      ],
      [
        {
          badData: false,
          display: 'mil',
          type: 'STRING',
          value: 'mil'
        },
        {
          badData: false,
          display: 5237,
          type: 'INTEGER',
          value: '5237'
        }
      ],
      [
        {
          badData: false,
          display: 'biz',
          type: 'STRING',
          value: 'biz'
        },
        {
          badData: false,
          display: 3085,
          type: 'INTEGER',
          value: '3085'
        }
      ],
      [
        {
          badData: false,
          display: 'name',
          type: 'STRING',
          value: 'name'
        },
        {
          badData: false,
          display: 8749,
          type: 'INTEGER',
          value: '8749'
        }
      ],
      [
        {
          badData: false,
          display: 'org',
          type: 'STRING',
          value: 'org'
        },
        {
          badData: false,
          display: 5919,
          type: 'INTEGER',
          value: '5919'
        }
      ],
      [
        {
          badData: false,
          display: 'gov',
          type: 'STRING',
          value: 'gov'
        },
        {
          badData: false,
          display: 5049,
          type: 'INTEGER',
          value: '5049'
        }
      ],
      [
        {
          badData: false,
          display: 'com',
          type: 'STRING',
          value: 'com'
        },
        {
          badData: false,
          display: 5130,
          type: 'INTEGER',
          value: '5130'
        }
      ],
      [
        {
          badData: false,
          display: 'edu',
          type: 'STRING',
          value: 'edu'
        },
        {
          badData: false,
          display: 6292,
          type: 'INTEGER',
          value: '6292'
        }
      ],
      [
        {
          badData: false,
          display: 'net',
          type: 'STRING',
          value: 'net'
        },
        {
          badData: false,
          display: 5774,
          type: 'INTEGER',
          value: '5774'
        }
      ]
    ],
    x: 'sold',
    y: 'domain'
  },
  {
    key: 'glazed',
    values: [
      [
        {
          badData: false,
          display: 'info',
          type: 'STRING',
          value: 'info'
        },
        {
          badData: false,
          display: 14693,
          type: 'INTEGER',
          value: '14693'
        }
      ],
      [
        {
          badData: false,
          display: 'mil',
          type: 'STRING',
          value: 'mil'
        },
        {
          badData: false,
          display: 6700,
          type: 'INTEGER',
          value: '6700'
        }
      ],
      [
        {
          badData: false,
          display: 'biz',
          type: 'STRING',
          value: 'biz'
        },
        {
          badData: false,
          display: 12281,
          type: 'INTEGER',
          value: '12281'
        }
      ],
      [
        {
          badData: false,
          display: 'name',
          type: 'STRING',
          value: 'name'
        },
        {
          badData: false,
          display: 8274,
          type: 'INTEGER',
          value: '8274'
        }
      ],
      [
        {
          badData: false,
          display: 'org',
          type: 'STRING',
          value: 'org'
        },
        {
          badData: false,
          display: 7478,
          type: 'INTEGER',
          value: '7478'
        }
      ],
      [
        {
          badData: false,
          display: 'gov',
          type: 'STRING',
          value: 'gov'
        },
        {
          badData: false,
          display: 9187,
          type: 'INTEGER',
          value: '9187'
        }
      ],
      [
        {
          badData: false,
          display: 'com',
          type: 'STRING',
          value: 'com'
        },
        {
          badData: false,
          display: 9028,
          type: 'INTEGER',
          value: '9028'
        }
      ],
      [
        {
          badData: false,
          display: 'edu',
          type: 'STRING',
          value: 'edu'
        },
        {
          badData: false,
          display: 7235,
          type: 'INTEGER',
          value: '7235'
        }
      ],
      [
        {
          badData: false,
          display: 'net',
          type: 'STRING',
          value: 'net'
        },
        {
          badData: false,
          display: 7742,
          type: 'INTEGER',
          value: '7742'
        }
      ]
    ],
    x: 'sold',
    y: 'domain'
  },
  {
    key: 'jelly',
    values: [
      [
        {
          badData: false,
          display: 'info',
          type: 'STRING',
          value: 'info'
        },
        {
          badData: false,
          display: 16263,
          type: 'INTEGER',
          value: '16263'
        }
      ],
      [
        {
          badData: false,
          display: 'mil',
          type: 'STRING',
          value: 'mil'
        },
        {
          badData: false,
          display: 15986,
          type: 'INTEGER',
          value: '15986'
        }
      ],
      [
        {
          badData: false,
          display: 'biz',
          type: 'STRING',
          value: 'biz'
        },
        {
          badData: false,
          display: 15420,
          type: 'INTEGER',
          value: '15420'
        }
      ],
      [
        {
          badData: false,
          display: 'name',
          type: 'STRING',
          value: 'name'
        },
        {
          badData: false,
          display: 15058,
          type: 'INTEGER',
          value: '15058'
        }
      ],
      [
        {
          badData: false,
          display: 'org',
          type: 'STRING',
          value: 'org'
        },
        {
          badData: false,
          display: 14387,
          type: 'INTEGER',
          value: '14387'
        }
      ],
      [
        {
          badData: false,
          display: 'gov',
          type: 'STRING',
          value: 'gov'
        },
        {
          badData: false,
          display: 14125,
          type: 'INTEGER',
          value: '14125'
        }
      ],
      [
        {
          badData: false,
          display: 'com',
          type: 'STRING',
          value: 'com'
        },
        {
          badData: false,
          display: 12831,
          type: 'INTEGER',
          value: '12831'
        }
      ],
      [
        {
          badData: false,
          display: 'edu',
          type: 'STRING',
          value: 'edu'
        },
        {
          badData: false,
          display: 10744,
          type: 'INTEGER',
          value: '10744'
        }
      ],
      [
        {
          badData: false,
          display: 'net',
          type: 'STRING',
          value: 'net'
        },
        {
          badData: false,
          display: 10303,
          type: 'INTEGER',
          value: '10303'
        }
      ]
    ],
    x: 'sold',
    y: 'domain'
  },
  {
    key: 'lemon',
    values: [
      [
        {
          badData: false,
          display: 'info',
          type: 'STRING',
          value: 'info'
        },
        {
          badData: false,
          display: 4542,
          type: 'INTEGER',
          value: '4542'
        }
      ],
      [
        {
          badData: false,
          display: 'mil',
          type: 'STRING',
          value: 'mil'
        },
        {
          badData: false,
          display: 5682,
          type: 'INTEGER',
          value: '5682'
        }
      ],
      [
        {
          badData: false,
          display: 'biz',
          type: 'STRING',
          value: 'biz'
        },
        {
          badData: false,
          display: 3834,
          type: 'INTEGER',
          value: '3834'
        }
      ],
      [
        {
          badData: false,
          display: 'name',
          type: 'STRING',
          value: 'name'
        },
        {
          badData: false,
          display: 4398,
          type: 'INTEGER',
          value: '4398'
        }
      ],
      [
        {
          badData: false,
          display: 'org',
          type: 'STRING',
          value: 'org'
        },
        {
          badData: false,
          display: 5850,
          type: 'INTEGER',
          value: '5850'
        }
      ],
      [
        {
          badData: false,
          display: 'gov',
          type: 'STRING',
          value: 'gov'
        },
        {
          badData: false,
          display: 6949,
          type: 'INTEGER',
          value: '6949'
        }
      ],
      [
        {
          badData: false,
          display: 'com',
          type: 'STRING',
          value: 'com'
        },
        {
          badData: false,
          display: 2931,
          type: 'INTEGER',
          value: '2931'
        }
      ],
      [
        {
          badData: false,
          display: 'edu',
          type: 'STRING',
          value: 'edu'
        },
        {
          badData: false,
          display: 4452,
          type: 'INTEGER',
          value: '4452'
        }
      ],
      [
        {
          badData: false,
          display: 'net',
          type: 'STRING',
          value: 'net'
        },
        {
          badData: false,
          display: 3366,
          type: 'INTEGER',
          value: '3366'
        }
      ]
    ],
    x: 'sold',
    y: 'domain'
  },
  {
    key: 'old fashioned',
    values: [
      [
        {
          badData: false,
          display: 'info',
          type: 'STRING',
          value: 'info'
        },
        {
          badData: false,
          display: 7507,
          type: 'INTEGER',
          value: '7507'
        }
      ],
      [
        {
          badData: false,
          display: 'mil',
          type: 'STRING',
          value: 'mil'
        },
        {
          badData: false,
          display: 7218,
          type: 'INTEGER',
          value: '7218'
        }
      ],
      [
        {
          badData: false,
          display: 'biz',
          type: 'STRING',
          value: 'biz'
        },
        {
          badData: false,
          display: 5002,
          type: 'INTEGER',
          value: '5002'
        }
      ],
      [
        {
          badData: false,
          display: 'name',
          type: 'STRING',
          value: 'name'
        },
        {
          badData: false,
          display: 3016,
          type: 'INTEGER',
          value: '3016'
        }
      ],
      [
        {
          badData: false,
          display: 'org',
          type: 'STRING',
          value: 'org'
        },
        {
          badData: false,
          display: 3478,
          type: 'INTEGER',
          value: '3478'
        }
      ],
      [
        {
          badData: false,
          display: 'gov',
          type: 'STRING',
          value: 'gov'
        },
        {
          badData: false,
          display: 6539,
          type: 'INTEGER',
          value: '6539'
        }
      ],
      [
        {
          badData: false,
          display: 'com',
          type: 'STRING',
          value: 'com'
        },
        {
          badData: false,
          display: 3821,
          type: 'INTEGER',
          value: '3821'
        }
      ],
      [
        {
          badData: false,
          display: 'edu',
          type: 'STRING',
          value: 'edu'
        },
        {
          badData: false,
          display: 5082,
          type: 'INTEGER',
          value: '5082'
        }
      ],
      [
        {
          badData: false,
          display: 'net',
          type: 'STRING',
          value: 'net'
        },
        {
          badData: false,
          display: 4043,
          type: 'INTEGER',
          value: '4043'
        }
      ]
    ],
    x: 'sold',
    y: 'domain'
  },
  {
    key: 'powdered',
    values: [
      [
        {
          badData: false,
          display: 'info',
          type: 'STRING',
          value: 'info'
        },
        {
          badData: false,
          display: 5002,
          type: 'INTEGER',
          value: '5002'
        }
      ],
      [
        {
          badData: false,
          display: 'mil',
          type: 'STRING',
          value: 'mil'
        },
        {
          badData: false,
          display: 3963,
          type: 'INTEGER',
          value: '3963'
        }
      ],
      [
        {
          badData: false,
          display: 'biz',
          type: 'STRING',
          value: 'biz'
        },
        {
          badData: false,
          display: 3065,
          type: 'INTEGER',
          value: '3065'
        }
      ],
      [
        {
          badData: false,
          display: 'name',
          type: 'STRING',
          value: 'name'
        },
        {
          badData: false,
          display: 4444,
          type: 'INTEGER',
          value: '4444'
        }
      ],
      [
        {
          badData: false,
          display: 'org',
          type: 'STRING',
          value: 'org'
        },
        {
          badData: false,
          display: 5296,
          type: 'INTEGER',
          value: '5296'
        }
      ],
      [
        {
          badData: false,
          display: 'gov',
          type: 'STRING',
          value: 'gov'
        },
        {
          badData: false,
          display: 6904,
          type: 'INTEGER',
          value: '6904'
        }
      ],
      [
        {
          badData: false,
          display: 'com',
          type: 'STRING',
          value: 'com'
        },
        {
          badData: false,
          display: 3571,
          type: 'INTEGER',
          value: '3571'
        }
      ],
      [
        {
          badData: false,
          display: 'edu',
          type: 'STRING',
          value: 'edu'
        },
        {
          badData: false,
          display: 2690,
          type: 'INTEGER',
          value: '2690'
        }
      ],
      [
        {
          badData: false,
          display: 'net',
          type: 'STRING',
          value: 'net'
        },
        {
          badData: false,
          display: 4236,
          type: 'INTEGER',
          value: '4236'
        }
      ]
    ],
    x: 'sold',
    y: 'domain'
  },
  {
    key: 'strawberry filled',
    values: [
      [
        {
          badData: false,
          display: 'info',
          type: 'STRING',
          value: 'info'
        },
        {
          badData: false,
          display: 2901,
          type: 'INTEGER',
          value: '2901'
        }
      ],
      [
        {
          badData: false,
          display: 'mil',
          type: 'STRING',
          value: 'mil'
        },
        {
          badData: false,
          display: 5244,
          type: 'INTEGER',
          value: '5244'
        }
      ],
      [
        {
          badData: false,
          display: 'biz',
          type: 'STRING',
          value: 'biz'
        },
        {
          badData: false,
          display: 3930,
          type: 'INTEGER',
          value: '3930'
        }
      ],
      [
        {
          badData: false,
          display: 'name',
          type: 'STRING',
          value: 'name'
        },
        {
          badData: false,
          display: 7219,
          type: 'INTEGER',
          value: '7219'
        }
      ],
      [
        {
          badData: false,
          display: 'org',
          type: 'STRING',
          value: 'org'
        },
        {
          badData: false,
          display: 5470,
          type: 'INTEGER',
          value: '5470'
        }
      ],
      [
        {
          badData: false,
          display: 'gov',
          type: 'STRING',
          value: 'gov'
        },
        {
          badData: false,
          display: 1956,
          type: 'INTEGER',
          value: '1956'
        }
      ],
      [
        {
          badData: false,
          display: 'com',
          type: 'STRING',
          value: 'com'
        },
        {
          badData: false,
          display: 4941,
          type: 'INTEGER',
          value: '4941'
        }
      ],
      [
        {
          badData: false,
          display: 'edu',
          type: 'STRING',
          value: 'edu'
        },
        {
          badData: false,
          display: 3084,
          type: 'INTEGER',
          value: '3084'
        }
      ],
      [
        {
          badData: false,
          display: 'net',
          type: 'STRING',
          value: 'net'
        },
        {
          badData: false,
          display: 8386,
          type: 'INTEGER',
          value: '8386'
        }
      ]
    ],
    x: 'sold',
    y: 'domain'
  }
];

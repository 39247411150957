import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// Components
import { DxSideNavComponent } from './dx-side-nav.component';
import { DxSideNavListComponent } from './dx-side-nav-list.component';
import { DxSideNavItemComponent } from './dx-side-nav-item.component';

@NgModule({
  declarations: [
    DxSideNavComponent,
    DxSideNavListComponent,
    DxSideNavItemComponent
  ],
  exports: [
    DxSideNavComponent,
    DxSideNavListComponent,
    DxSideNavItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class DxSideNavModule {}

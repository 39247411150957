import { Component } from '@angular/core';
import { DxMessagingService } from 'dx-angular-components-lib';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent {
  alertText = {
    title: 'Alert'
  };
  basicDataModel = {
    message: {
      _id: '',
      msg: '',
      title: '',
      type: ''
    }
  };
  dxAlertProperties = [
    {
      description: 'Message to be displayed',
      name: 'message',
      required: 'required',
      type: 'IDxMessage'
    },
    {
      description: 'Classes for the alert',
      name: 'customCSS',
      required: 'optional',
      type: 'string'
    },
    {
      description: 'Whether the alert will close automatically. If set to true, all alerts will close after 5s, with the exception of success alerts which will close after 2s.',
      name: 'autoDismiss',
      required: 'optional',
      type: 'boolean'
    }
  ];
  dxMessageProperties = [
    {
      description: 'Generated unique id of the message.',
      name: '_id',
      possibleValues: '',
      required: 'required',
      type: 'string'
    },
    {
      description: 'Text to be displayed.',
      name: 'msg',
      possibleValues: '',
      required: 'required',
      type: 'string'
    },
    {
      description: 'The title of the alert.',
      name: 'title',
      possibleValues: '',
      required: 'required',
      type: 'string'
    },
    {
      description: 'Type of alert.',
      name: 'type',
      possibleValues: '"default", "success", "info", "alert"',
      required: 'required',
      type: 'string'
    }
  ];
  inlineTypescriptCode =
  `import { Component } from '@angular/core';

@Component({
  selector: 'app-alert-example',
  templateUrl: './alert-example.component.html'
})
export class AlertExampleComponent  {
  message: {
    _id: '',
    msg: '',
    title: '',
    type: ''
  }

  clearInputMessages = () => {
    this.message._id = '';
    this.message.msg = '';
    this.message.title = '';
    this.message.type = '';
  }

  clickInlineAlertHandler = (type: string): void => {
    this.clearInputMessages();

    setTimeout(() => {
      this.message._id = '123';
      this.message.msg = 'Custom alert message.';
      this.message.title = 'Title: ';
      this.message.type = type;
    });
  };
}`;
  typesTypescriptCode =
  `import { Component } from '@angular/core';
import { DxMessagingService } from '@dx/dx-angular-components-lib';

@Component({
  selector: 'app-alert-example',
  templateUrl: './alert-example.component.html'
})
export class AlertExampleComponent  {
  constructor(private dxMessagingService: DxMessagingService) {}

  clickHandler = (type: string): void => {
    const title = 'Title: ';
    const text = 'Custom alert message.';

    this.dxMessagingService.addMessage(type, title, text);
  }
}`;
  usage = `import { DxAlertModule } from '@dx/dx-angular-components-lib';`;

  constructor(private dxMessagingService: DxMessagingService) {}

  clickHandler = (type: string): void => {
    const title = 'Title: ';
    const text = 'Custom alert message.';

    this.dxMessagingService.addMessage(type, title, text);
  }

  clearInputMessages = () => {
    this.basicDataModel.message._id = '';
    this.basicDataModel.message.msg = '';
    this.basicDataModel.message.title = '';
    this.basicDataModel.message.type = '';
  }

  clickInlineAlertHandler = (type: string): void => {
    this.clearInputMessages();

    setTimeout(() => {
      this.basicDataModel.message._id = '123';
      this.basicDataModel.message.msg = 'Custom alert message.';
      this.basicDataModel.message.title = 'Title: ';
      this.basicDataModel.message.type = type;
    });
  }
}

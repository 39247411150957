export const CHART_COLORS = [
  {
    description: '',
    id: 'x15Default',
    type: 'qualitative',
    values: ['#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a', '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5']
  },
  {
    description: '',
    id: 'qualitative1',
    type: 'qualitative',
    values: ['#458CD8', '#D73E2D', '#4A9B65', '#A053AE', '#209DC0', '#EB8A34', '#D23C76', '#F3E056', '#54C7AB', '#919FB4']
  },
  {
    description: '',
    id: 'qualitative2',
    type: 'qualitative',
    values: ['#B5D5F9', '#458CD8', '#ED9292', '#D73E2D', '#90CFA5', '#4A9B65', '#CB9DD4', '#A053AE', '#A1DBEC', '#209DC0']
  },
  {
    description: '',
    id: 'qualitative3',
    type: 'qualitative',
    values: ['#B5D5F9', '#ED9292', '#90CFA5', '#CB9DD4', '#A1DBEC', '#FFBF77', '#F3B6D1', '#FEEFB0', '#A0EBDE', '#D8DDE6']
  },
  {
    description: '',
    id: 'sequential1',
    type: 'sequential',
    values: ['#F7FCF0', '#E0F3DB', '#CCEBC5', '#A8DDB5', '#7BCCC4', '#4EB3D3', '#2B8CBE', '#0868AC', '#084081', '#00034D']
  },
  {
    description: '',
    id: 'sequential2',
    type: 'sequential',
    values: ['#FFF7EC', '#FEE8C8', '#FDD49E', '#FDBB84', '#FC8D59', '#EF6548', '#D7301F', '#B30000', '#7F0000', '#430000']
  },
  {
    description: '',
    id: 'sequential3',
    type: 'sequential',
    values: ['#FBFDBF', '#F6E69E', '#EDD293', '#E09E5D', '#C7775E', '#A47BC3', '#6E4F98', '#4743A5', '#14077A', '#090054']
  },
  {
    description: '',
    id: 'diverging1',
    type: 'diverging',
    values: ['#A50026', '#D73027', '#F46D43', '#FDAE61', '#FEE08B', '#D9EF8B', '#A6D96A', '#66BD63', '#1A9850', '#006837']
  },
  {
    description: '',
    id: 'diverging2',
    type: 'diverging',
    values: ['#A50026', '#D73027', '#F46D43', '#FDAE61', '#FEE08B', '#E0F3F8', '#ABD9E9', '#74ADD1', '#4575B4', '#243C8D']
  },
  {
    description: '',
    id: 'diverging3',
    type: 'diverging',
    values: ['#543005', '#8C510A', '#BF812D', '#DFC27D', '#F6E8C3', '#E0F3F8', '#ABD9E9', '#74ADD1', '#4575B4', '#243C8D']
  }
];

import { Component } from '@angular/core';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html'
})
export class FormComponent {

  designGuidelines = {
    selectOnFocus: `The dxSelectOnFocus directive automatically selects the value of a focusable element like an input or textarea
    when the element is focused. This is useful in cases where the value is intended to be immediately copied or replaced.`
  };
  formText = {
    description: 'Form layouts and elements.',
    title: 'Form'
  };
  selectOnFocusInput = {
    input: 'Input selected on focus'
  };
  usage = {
    importStatement: `import { DxFormModule } from '@dx/dx-angular-components-lib';`
  };
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-contribute',
  templateUrl: './contribute.component.html'
})
export class ContributeComponent {

  contributeText = {
    description: '',
    title: 'Contribute'
  };

  code = {
    exampleComponent:
`<example
  title="dx-my-component-example"
  htmlPath="/path/to/dx-my-component/html"
></example>`,
    exportComponent: `export * from './lib/dx-my-component/dx-my-component.component';`,
    input:
`<example
  title="dx-my-component-example"
  htmlPath="/path/to/dx-my-component/html"
  [input]="this"
></example>`,
    navItem:
`{ name: 'Components',
  subItems: [
    { name: 'Foo', route: '/foo' }
  ]
}`,
    pageHeader: `<page-header [headerText]="fooText"></page-header>`,
    pageHeaderTypescript:
`fooText = {
  description: 'This is a description of the dx-my-component',
  title: 'dx-my-component'
};`,
    parentRef:
`<dx-my-component
  (clickAction)="parentRef.clickHandler($event)"
></dx-my-component>`,
    route: `{ path: 'foo', component: FooComponent }`,
    usage: `<usage usageCode="This is how to import the component"></usage>`
  };
}

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { DxAutofocusDirective } from '../dx-autofocus/dx-autofocus.directive';
import { DxSelectOnFocusDirective } from '../dx-select-on-focus/dx-select-on-focus.directive';
import { DxSwitchComponent } from '../dx-switch/dx-switch.component';

@NgModule({
  declarations: [
    DxAutofocusDirective,
    DxSelectOnFocusDirective,
    DxSwitchComponent
  ],
  exports: [
    DxAutofocusDirective,
    DxSelectOnFocusDirective,
    DxSwitchComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ]
})
export class DxFormModule {}

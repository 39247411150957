import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AlertComponent } from '@views/alert/alert.component';
import { BarChartExampleComponent } from '@views/bar-chart/bar-chart-example.component';
import { ButtonComponent } from '@views/button/button.component';
import { ColumnChartExampleComponent } from '@views/column-chart/column-chart-example.component';
import { ComponentsComponent } from '@views/components/components.component';
import { ContributeComponent } from '@views/contribute/contribute.component';
import { DropdownComponent } from '@views/dropdown/dropdown.component';
import { FormComponent } from '@views/form/form.component';
import { GetStartedComponent } from '@views/get-started/get-started.component';
import { IntroComponent } from '@views/intro/intro.component';
import { ModalComponentDrivenComponent } from '@views/modal-component-driven/modal.component';
import { ModalContextualComponent } from '@views/modal-contextual/modal-contextual.component';
import { ModalMultistepComponent } from '@views/modal-multistep/modal-multistep.component';
import { ModalTemplateDrivenComponent } from '@views/modal-template-driven/modal.component';
import { OverlayComponent } from '@views/overlay/overlay.component';
import { PatternsComponent } from '@views/patterns/patterns.component';
import { PieChartExampleComponent } from '@views/pie-chart/pie-chart-example.component';
import { SideNavComponent } from '@views/side-nav/side-nav.component';
import { StylesThemesComponent } from '@views/styles-themes/styles-themes.component';
import { TabsComponent } from '@views/tabs/tabs.component';
import { TooltipComponent } from '@views/tooltip/tooltip.component';

const routes: Routes = [
  { path: '', redirectTo: '/intro', pathMatch: 'full' },
  {
    children: [
      { path: '', component: ComponentsComponent },
      { path: 'alert', component: AlertComponent, data: { title: 'Alert' } },
      { path: 'bar-chart', component: BarChartExampleComponent, data: { title: 'Bar Chart' } },
      { path: 'column-chart', component: ColumnChartExampleComponent, data: { title: 'Column Chart' } },
      { path: 'dropdown', component: DropdownComponent, data: { title: 'Dropdown' } },
      { path: 'form', component: FormComponent, data: { title: 'Form' } },
      { path: 'modal-component-driven', component: ModalComponentDrivenComponent, data: { title: 'Modal (Component-driven)' } },
      { path: 'modal-template-driven', component: ModalTemplateDrivenComponent, data: { title: 'Modal (Template-driven)' } },
      { path: 'overlay', component: OverlayComponent, data: { title: 'Overlay' } },
      { path: 'pie-chart', component: PieChartExampleComponent, data: { title: 'Pie Chart' } },
      { path: 'tabs', component: TabsComponent, data: { title: 'Tabs' } },
      { path: 'tooltip', component: TooltipComponent, data: { title: 'Tooltip' } }
    ],
    data: { title: 'Components'},
    path: 'components'
  },
  {
    children: [
      { path: '', component: PatternsComponent },
      { path: 'modal-contextual', component: ModalContextualComponent, data: { title: 'Contextual Modal' } },
      { path: 'modal-multistep', component: ModalMultistepComponent, data: { title: 'Modal-multistep' } },
      {
        children: [
          { path: 'intro', component: SideNavComponent },
          { path: 'get-started', component: SideNavComponent },
          {
            children: [{ path: 'button', component: SideNavComponent }],
            component: SideNavComponent,
            path: 'styles'
          },
          {
            children: [{ path: 'form', component: SideNavComponent }],
            component: SideNavComponent,
            path: 'components'
          }
        ],
        component: SideNavComponent,
        data: { title: 'side-nav' },
        path: 'side-nav'
      }
    ],
    data: { title: 'Patterns' },
    path: 'patterns'
  },
  {
    children: [
      { path: '', component: StylesThemesComponent },
      { path: 'button', component: ButtonComponent, data: { title: 'Button' } }
    ],
    data: { title: 'Styles'},
    path: 'styles-themes'
  },
  { path: 'contribute', component: ContributeComponent, data: { title: 'Contribute' } },
  { path: 'get-started', component: GetStartedComponent, data: { title: 'Get Started' } },
  { path: 'intro', component: IntroComponent, data: { animation: 'Fade', title: 'Intro' } }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })]
})
export class AppRoutingModule { }

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { isUndefined, map, reject } from 'underscore';

@Component({
  selector: 'dx-dropdown-menu',
  templateUrl: 'dx-dropdown-menu.component.html'
})
export class DxDropdownMenuComponent implements AfterViewInit, OnDestroy, OnInit {

  domReady: BehaviorSubject<any> = new BehaviorSubject(null);

  @Output() closeMenu = new EventEmitter();
  @Output() listItemsChanged = new EventEmitter();

  dropdown: HTMLElement;
  filterableList: any[] = [];
  filterableListOriginal: any[] = [];
  inputEl: HTMLInputElement;
  selectableItems: any[];
  selectedIndex = 0;

  @HostListener('click', ['$event'])
  onclick($event) {
    $event.preventDefault();
    this.closeDropdownMenu($event);
  }

  constructor(private elementRef: ElementRef) {
    this.dropdown = this.elementRef.nativeElement;
  }

  ngAfterViewInit() {
    this.domReady.next({ menuElement: this.elementRef.nativeElement.childNodes[0], dropdownMenuComp: this });
  }

  ngOnDestroy() {
    document.body.removeEventListener('keydown', this.closeOnEscape);
    document.removeEventListener('keydown', this.monitorUpDown);
    document.removeEventListener('click', this.closeDropdownMenu);

    if (this.inputEl) {
      this.inputEl.removeEventListener('keyup', this.filterList);
    }
  }

  ngOnInit() {
    this.init();
  }

  /**
   * Initialize the dropdown menu and all it event listeners.
   */
  init() {
    // Clear any previously hovered classes
    const hoveredEl = this.dropdown.querySelector('li.hovered');
    this.inputEl = this.dropdown.querySelector('input');

    this.setSelectableItems();

    if (hoveredEl) { hoveredEl.classList.remove('hovered'); }

    setTimeout(() => {
      // If there's a filter input, focus it
      if (this.inputEl) {
        if (this.inputEl.type === 'checkbox') {
          this.inputEl.parentElement.focus();
        } else {
          this.inputEl.focus();
        }
      }
    }, 200);

    document.body.addEventListener('keydown', this.closeOnEscape);
    document.addEventListener('keydown', this.monitorUpDown);
    document.addEventListener('click', this.closeDropdownMenu);

    if (this.inputEl) {
      this.inputEl.value = '';
      this.inputEl.addEventListener('keyup', this.filterList);
    }
  }

  /**
   * Emits a closeMenu event.
   * @param $event  The mouse click event.
   */
  closeDropdownMenu = ($event: MouseEvent) => {
    const eventTarget = $event.target as HTMLElement;
    const hasTarget = !isUndefined($event);
    // TODO: Add a helper to walk up the DOM to mimic JQuery's 'parents'.
    // inMenu = hasTarget ? $event.target.parents('.dx-dropdown').length : false;

    // If this click event target is an input, nevermind...
    if (hasTarget) {
      if (eventTarget.classList.contains('durationPicker')) { return; }
      if (eventTarget.tagName === 'INPUT') { return; }
      if (eventTarget.classList.contains('title')) { return; }
      if (eventTarget.tagName === 'LABEL') { return; }
      if (eventTarget.classList.contains('input')) { return; }
      if (eventTarget.classList.contains('switch')) { return; }
    }

    this.closeMenu.emit();
  }

  /**
   * Emits a closeMenu event on escape / tab keypress.
   * @param $event  A keyboard event.
   */
  closeOnEscape = ($event: KeyboardEvent) => {
    if (($event.keyCode === 27 || $event.keyCode === 9)) {
      this.closeMenu.emit();
      $event.stopPropagation();
    }
  }

  /**
   * Filters menu list items by entity name and emits the filtered list.
   * @param $event  The inout keyboard event.
   */
  filterList = ($event: KeyboardEvent) => {
    const eventTarget = $event.target as HTMLInputElement;
    const filter = eventTarget.value.toLowerCase();

    if (!filter) {
      this.filterableList = this.filterableListOriginal;
    } else {
      this.filterableList = this.filterableListOriginal.filter((item) => {
        const itemName = item.name.toLowerCase();
        return itemName.indexOf(filter) >= 0;
      });
    }

    this.listItemsChanged.emit(this.filterableList);
    this.setSelectableItems();
  }

  /**
   * Handles keyboard events within the menu.
   * @param $event  A keyboard event.
   */
  monitorUpDown = ($event: KeyboardEvent) => {
    const eventTarget =  $event.target as HTMLElement;

    this.setSelectableItems();

    if (($event.keyCode === 40) || ($event.keyCode === 38) || $event.keyCode === 13) {
      if (eventTarget.hasAttribute('type')) {
        if (eventTarget.getAttribute('type') === 'number') {
          return;
        }
      }

      // prevent parent form submission
      $event.stopPropagation();
    }

    const hoveredEl = this.dropdown.querySelector('li.hovered');

    if (hoveredEl) {
      hoveredEl.classList.remove('hovered');
      this.selectedIndex = this.selectableItems.indexOf(hoveredEl);
    }

    if ((($event.keyCode === 40) || ($event.keyCode === 38)) && (!hoveredEl)) {
      this.selectableItems[0].classList.add('hovered');
      this.selectedIndex = 0;
    } else if ($event.keyCode === 40) { // down
      this.selectedIndex += 1;
      if (this.selectedIndex >= this.selectableItems.length) {
        this.selectedIndex = 0;
      }
    } else if ($event.keyCode === 38) { // up
      this.selectedIndex -= 1;
      if (this.selectedIndex < 0) {
        this.selectedIndex = this.selectableItems.length - 1;
      }
    }

    if (this.selectableItems.length && this.selectableItems[this.selectedIndex]) {
      this.selectableItems[this.selectedIndex].classList.add('hovered');
    }

    if (($event.keyCode === 40) || ($event.keyCode === 38)) {
      $event.preventDefault(); // prevent window scrolling on up/down
    } else if (($event.keyCode === 32) || ($event.keyCode === 13)) { // spacebar (32) or ENTER (13)
      // If this is a searchable dropdown, allow spaces in search string
      if ($event.keyCode === 32 && eventTarget.tagName === 'INPUT') { return; }

      if (hoveredEl) {
        $event.preventDefault();
        const clickableEl = hoveredEl.querySelector('a') || hoveredEl.querySelector('button');
        if (clickableEl) { clickableEl.click(); }
      }
    }
  }

  /**
   * Update the list of menu items that are selectable.
   */
  setSelectableItems = (): void => {
    const selectableNodes = map(this.dropdown.querySelectorAll('a, button'), (node) => {
      return node.parentNode;
    });

    this.selectableItems = reject(selectableNodes, (link: Element) => {
      return link.classList.contains('divider') || link.classList.contains('title') || link.classList.contains('input') || link.classList.contains('fixed') || link.hasAttribute('hidden') || link.hasAttribute('disabled') || link.classList.contains('disabled');
    });
  }

  /**
   * Set the menu width.
   * @param  width  The trigger element's parent container width.
   */
  setWidth = (width: number): void => {
    const dropdownMenuEl = this.dropdown.querySelector('ul');
    dropdownMenuEl.style.width = width + 'px';
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ElementRef } from '@angular/core';

@Component({
  selector: 'demo',
  templateUrl: './demo.component.html'
})
export class DemoComponent implements OnInit {
  @Input() componentName: string;
  @Input() demoTitle: string;
  @Input() description: string;
  @Input() subtitle: string;

  fragment: string;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.fragment = this.elementRef.nativeElement.parentElement.parentElement.attributes.id.value;
  }
}

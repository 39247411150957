import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-components',
  templateUrl: './components.component.html'
})
export class ComponentsComponent implements OnInit {

  componentsText = {
    title: 'Components'
  };

  constructor() { }

  ngOnInit() {
  }

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DxModalBackdropComponent } from './dx-modal-backdrop.component';
import { DxModalComponent } from './dx-modal.component';
import { DxModalService } from './dx-modal.service';

@NgModule({
  declarations: [
    DxModalBackdropComponent,
    DxModalComponent
  ],
  entryComponents: [
    DxModalBackdropComponent,
    DxModalComponent
  ],
  exports: [
    DxModalBackdropComponent,
    DxModalComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    DxModalService
  ]
})

export class DxModalModule {}

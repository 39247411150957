import { Component } from '@angular/core';
import { Subject, timer } from 'rxjs';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html'
})
export class TooltipComponent {
  dxTooltipProperties = [
    {
      description: 'Sets the tooltip value to be displayed.',
      name: 'dxTooltip',
      possibleValues: '',
      required: 'required',
      type: 'any'
    },
    {
      description: 'Delay in ms before hiding the tooltip.',
      name: 'dxHideDelay',
      possibleValues: 'default: 0',
      required: 'optional',
      type: 'number'
    },
    {
      description: 'Delay in ms before showing the tooltip.',
      name: 'dxShowDelay',
      possibleValues: 'default: 0',
      required: 'optional',
      type: 'number'
    },
    {
      description: 'Adds additional classes to the tooltip.',
      name: 'dxTooltipClass',
      possibleValues: '',
      required: 'optional',
      type: 'string'
    },
    {
      description: 'Shows tooltip based on some custom trigger.',
      name: 'dxTooltipCustomTrigger',
      possibleValues: '',
      required: 'optional',
      type: 'Observable<boolean>'
    },
    {
      description: 'Sets the placement of the tooltip.',
      name: 'dxTooltipPlacement',
      possibleValues: 'default: "bottom", "bottom-left", "bottom-right", "top", "top-left", "top-right", "left", "right"',
      required: 'optional',
      type: 'string'
    }
  ];
  tooltipShow = new Subject();
  tooltipText = {
    title: 'Tooltip'
  };

  typescript =
`import { Component } from '@angular/core';
import { Subject, timer } from 'rxjs';

@Component({
  selector: 'app-tooltip-example',
  templateUrl: './tooltip-example.component.html'
})

export class TooltipExampleComponent {
  tooltipShow = new Subject();

  clickHandler(): void {
    const tooltipTimer = timer(2000);

    this.tooltipShow.next(true);
    tooltipTimer.subscribe(() => {
      this.tooltipShow.next(false);
    });
  }
}`;

  usage = {
    importStatement: `import { DxTooltipModule } from '@dx/dx-angular-components-lib';`
  };

  clickHandler(): void {
    const tooltipTimer = timer(2000);

    this.tooltipShow.next(true);
    tooltipTimer.subscribe(() => {
      this.tooltipShow.next(false);
    });
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html'
})
export class TabsComponent {
  dxTabProperties = [
    {
      description: 'Sets the active state of the tab.',
      name: 'active',
      possibleValues: 'default: false',
      required: 'optional',
      type: 'boolean'
    },
    {
      description: 'Text label for the tab.',
      name: 'tabTitle',
      possibleValues: '',
      required: 'required',
      type: 'string'
    }
  ];
  dxTabsSetProperties = [
    {
      description: 'Sets the orientation of the tabs to vertical.',
      name: 'vertical',
      possibleValues: 'default: false',
      required: 'optional',
      type: 'boolean'
    }
  ];
  tabsText = {
    title: 'Tabs'
  };
  usage = `import { DxTabsModule } from '@dx/dx-angular-components-lib';`;
}

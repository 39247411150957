import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InavListItem } from './dx-side-nav.interface';

@Component({
  selector: 'dx-side-nav-item',
  templateUrl: './dx-side-nav-item.component.html'
})

export class DxSideNavItemComponent implements OnInit {
  @Input() item: InavListItem;
  @Input() itemIndex: number;
  @Input() parentRoute: string;

  @Output() clickedItem = new EventEmitter();

  /**
   * Handles side nav item clicks.
   * @param type          The nav item type: "item" | "subItem".
   * @param itemIndex     The index of the clicked item.
   * @param subItemIndex  The index of the clicked subItem.
   */
  handleClick = (type: string, itemIndex: number, subItemIndex?: number): void => {
    this.clickedItem.emit({itemIndex: itemIndex, subItemIndex: subItemIndex, type: type});
  }

  ngOnInit(): void {
    // to auto open the sub menu
    this.clickedItem.emit({itemIndex: 2, subItemIndex: 0, type: 'item'});
  }
}

import { Injectable } from '@angular/core';

import { DxWindowService } from '../dx-utils/dx-window.service';

@Injectable({providedIn: 'root'})
export class DxPositionService {

  window: Window;

  constructor(
    private dxWindowService: DxWindowService
   ) {
    this.window = this.dxWindowService.nativeWindow;
  }
  /**
   * Get a DOM element offset property
   * @param   element   Raw DOM element
   * @param   round     Define whether to round the values
   * @return            Element offset property object
   */
  elementOffset(element: HTMLElement, round = true): ClientRect {
    const elBcr = element.getBoundingClientRect();
    const viewportOffset = {
      left: this.window.pageXOffset - document.documentElement.clientLeft,
      top: this.window.pageYOffset - document.documentElement.clientTop
    };

    const elOffset = {
      bottom: elBcr.bottom + viewportOffset.top,
      height: elBcr.height || element.offsetHeight,
      left: elBcr.left + viewportOffset.left,
      right: elBcr.right + viewportOffset.left,
      top: elBcr.top + viewportOffset.top,
      width: elBcr.width || element.offsetWidth
    };

    if (round) {
      elOffset.height = Math.round(elOffset.height);
      elOffset.width = Math.round(elOffset.width);
      elOffset.top = Math.round(elOffset.top);
      elOffset.bottom = Math.round(elOffset.bottom);
      elOffset.left = Math.round(elOffset.left);
      elOffset.right = Math.round(elOffset.right);
    }

    return elOffset;
  }

  /**
   * returns all the style properties of a given element
   * @param   element   Raw DOM element
   * @return            Element style properties
   */
  private getAllStyles(element: HTMLElement) {
    return this.window.getComputedStyle(element);
  }

  /**
   * Get the css property of a DOM element
   * @param   el        Raw DOM element
   * @param   cssProp   Css property name
   * @return            Style property value
   */
  getStyle = (el: any, cssProp: string): string => {
    if (el.currentStyle) { // IE
      return el.currentStyle[cssProp];
    } else if (this.window.getComputedStyle) {
      return this.window.getComputedStyle(el)[cssProp];
    }
    // finally try and get inline style
    return el.style[cssProp];
  }

  /**
   * Checks if a given element is statically positioned
   * @param   element   Raw DOM element
   */
  isStaticPositioned = (element: any): boolean => {
    return (this.getStyle(element, 'position') || 'static') === 'static';
  }

  /**
   * returns offset properties of a given element
   * @param   element   Raw DOM element
   * @return            Element offset properties
   */
  offset = (element: any): any => {
    const boundingClientRect = element[0].getBoundingClientRect();
    return {
      height: boundingClientRect.height || element.prop('offsetHeight'),
      left: boundingClientRect.left + (this.window.pageXOffset || document.body.scrollLeft  || document.documentElement.scrollLeft),
      top: boundingClientRect.top + (this.window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop),
      width: boundingClientRect.width || element.prop('offsetWidth')
    };
  }

  /**
   * returns the closest, non-statically positioned parentOffset of a given element
   * @param   element   Raw DOM element
   * @return            Parent offset element
   */
  parentOffsetEl = (element: any): any => {
    const docDomEl =  document;
    let offsetParent = element.offsetParent || docDomEl;
    while (offsetParent && offsetParent !== docDomEl && this.isStaticPositioned(offsetParent)) {
      offsetParent = offsetParent.offsetParent;
    }
    return offsetParent || docDomEl;
  }

  /**
   * returns position properties of a given element
   * @param   element   Raw DOM element
   * @return            Element position properties
   */
  position = (element: any): any => {
    const elBCR = this.offset(element);
    let offsetParentBCR = { top: 0, left: 0 };
    const offsetParentEl = this.parentOffsetEl(element[0]);
    if (offsetParentEl !== document) {
      const offsetContainer = document.createElement('div');
      offsetContainer.innerHTML = offsetParentEl;
      offsetParentBCR = this.offset(offsetParentEl.firstChild);
      offsetParentBCR.top += offsetParentEl.clientTop - offsetParentEl.scrollTop;
      offsetParentBCR.left += offsetParentEl.clientLeft - offsetParentEl.scrollLeft;
    }

    const boundingClientRect = element[0].getBoundingClientRect();
    return {
      height: boundingClientRect.height || element.prop('offsetHeight'),
      left: elBCR.left - offsetParentBCR.left,
      top: elBCR.top - offsetParentBCR.top,
      width: boundingClientRect.width || element.prop('offsetWidth')
    };
  }

  /**
   * Position the target element relatively to a host element
   * @param   hostElement     Parent raw DOM element
   * @param   targetElement   Target raw DOM element
   * @param   placement       Absolute position location
   * @param   appendToBody    Define whether to append the target to the document body
   * @return                  Target element position object
   */
  positionElements(hostElement: HTMLElement, targetElement: HTMLElement, placement: string, appendToBody?: boolean):
    ClientRect {
    const hostElPosition = appendToBody ? this.elementOffset(hostElement) : this.position(hostElement);
    const placementPrimary = placement.split('-')[0] || 'top';
    const placementSecondary = placement.split('-')[1] || 'center';
    const targetElBCR = targetElement.getBoundingClientRect();
    const targetElStyles = this.getAllStyles(targetElement);

    const targetElPosition: ClientRect = {
      bottom: targetElBCR.height || targetElement.offsetHeight,
      height: targetElBCR.height || targetElement.offsetHeight,
      left: 0,
      right: targetElBCR.width || targetElement.offsetWidth,
      top: 0,
      width: targetElBCR.width || targetElement.offsetWidth
    };

    switch (placementPrimary) {
      case 'top':
        targetElPosition.top =
          hostElPosition.top - (targetElement.offsetHeight + parseFloat(targetElStyles.marginBottom));
        break;
      case 'bottom':
        targetElPosition.top = hostElPosition.top + hostElPosition.height;
        break;
      case 'left':
        targetElPosition.left =
          hostElPosition.left - (targetElement.offsetWidth + parseFloat(targetElStyles.marginRight));
        break;
      case 'right':
        targetElPosition.left = hostElPosition.left + hostElPosition.width;
        break;
    }

    switch (placementSecondary) {
      case 'top':
        targetElPosition.top = hostElPosition.top;
        break;
      case 'bottom':
        targetElPosition.top = hostElPosition.top + hostElPosition.height - targetElement.offsetHeight;
        break;
      case 'left':
        targetElPosition.left = hostElPosition.left;
        break;
      case 'right':
        targetElPosition.left = hostElPosition.left + hostElPosition.width - targetElement.offsetWidth;
        break;
      case 'center':
        if (placementPrimary === 'top' || placementPrimary === 'bottom') {
          targetElPosition.left = hostElPosition.left + hostElPosition.width / 2 - targetElement.offsetWidth / 2;
        } else {
          targetElPosition.top = hostElPosition.top + hostElPosition.height / 2 - targetElement.offsetHeight / 2;
        }
        break;
    }

    targetElPosition.top = Math.round(targetElPosition.top);
    targetElPosition.bottom = Math.round(targetElPosition.bottom);
    targetElPosition.left = Math.round(targetElPosition.left);
    targetElPosition.right = Math.round(targetElPosition.right);

    return targetElPosition;
  }
}

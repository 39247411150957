// Angular components
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

// DxAngularComponent Library
import { DxAngularComponentsLibModule } from 'dx-angular-components-lib';

// Internal Dependencies
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppNavComponent } from './common/app-nav/app-nav.component';
import { AppNavHeaderComponent } from './common/app-nav/app-nav-header.component';
import { AppNavFooterComponent } from './common/app-nav/app-nav-footer.component';
import { DemoComponent } from './common/demo/demo.component';
import { ExampleComponent } from './common/example/example.component';
import { HeaderTitleComponent } from './common/header-title/header-title.component';
import { MarkUpComponent } from './common/mark-up/mark-up.component';
import { PageHeaderComponent } from './common/page-header/page-header.component';
import { ThemeService } from './common/services/theme.service';
import { UsageComponent } from './common/usage/usage.component';

import { AlertComponent } from '@views/alert/alert.component';
import { BarChartExampleComponent } from '@views/bar-chart/bar-chart-example.component';
import { ButtonComponent } from '@views/button/button.component';
import { ColumnChartExampleComponent } from '@views/column-chart/column-chart-example.component';
import { ComponentsComponent } from './views/components/components.component';
import { ContributeComponent } from './views/contribute/contribute.component';
import { DropdownComponent } from '@views/dropdown/dropdown.component';
import { FormComponent } from '@views/form/form.component';
import { GetStartedComponent } from '@views/get-started/get-started.component';
import { IntroComponent } from '@views/intro/intro.component';
import { ModalComponentDrivenComponent } from '@views/modal-component-driven/modal.component';
import { ModalContentComponent } from '@views/modal-component-driven/modal-content.component';
import { ModalContentNoEscapeComponent } from '@views/modal-component-driven/modal-content-noescape.component';
import { ModalContentReturnComponent } from '@views/modal-component-driven/modal-content-return.component';
import { ModalContextualComponent } from '@views/modal-contextual/modal-contextual.component';
import { ModalMultistepComponent } from '@views/modal-multistep/modal-multistep.component';
import { ModalMultistepContentComponent } from '@views/modal-multistep/modal-multistep-content.component';
import { ModalTemplateDrivenComponent } from '@views/modal-template-driven/modal.component';
import { OverlayComponent } from '@views/overlay/overlay.component';
import { PatternsComponent } from './views/patterns/patterns.component';
import { PieChartExampleComponent } from '@views/pie-chart/pie-chart-example.component';
import { ReleaseInstructionsComponent } from '@views/release-instructions/release-instructions.component';
import { SideNavComponent } from '@views/side-nav/side-nav.component';
import { StylesThemesComponent } from './views/styles-themes/styles-themes.component';
import { TabsComponent } from '@views/tabs/tabs.component';
import { TooltipComponent } from '@views/tooltip/tooltip.component';

@NgModule({
  bootstrap: [
    AppComponent
  ],
  declarations: [
    AlertComponent,
    AppComponent,
    AppNavComponent,
    AppNavHeaderComponent,
    AppNavFooterComponent,
    BarChartExampleComponent,
    ButtonComponent,
    ColumnChartExampleComponent,
    ComponentsComponent,
    ContributeComponent,
    DemoComponent,
    DropdownComponent,
    ExampleComponent,
    FormComponent,
    HeaderTitleComponent,
    GetStartedComponent,
    IntroComponent,
    MarkUpComponent,
    ModalComponentDrivenComponent,
    ModalContentComponent,
    ModalContentNoEscapeComponent,
    ModalContentReturnComponent,
    ModalContextualComponent,
    ModalMultistepComponent,
    ModalMultistepContentComponent,
    ModalTemplateDrivenComponent,
    OverlayComponent,
    PageHeaderComponent,
    PatternsComponent,
    PieChartExampleComponent,
    ReleaseInstructionsComponent,
    SideNavComponent,
    StylesThemesComponent,
    TabsComponent,
    TooltipComponent,
    UsageComponent
  ],
  entryComponents: [
    ModalContentComponent,
    ModalContentNoEscapeComponent,
    ModalContentReturnComponent,
    ModalMultistepContentComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    DxAngularComponentsLibModule,
    HttpClientModule
  ],
  providers: [ ThemeService ]
})
export class AppModule { }

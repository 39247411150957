// Angular
import {
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';

// Third-party Libraries
import { find } from 'underscore';
import { fromEvent as observableFromEvent, Subscription } from 'rxjs';

// Internal Dependencies
import { DxDropdownMenuComponent } from './dx-dropdown-menu.component';
import { DxPositionService } from '../dx-position/dx-position.service';
import { DxOverlayService } from '../dx-overlay/dx-overlay.service';
import { DxWindowService } from '../dx-utils/dx-window.service';

export abstract class DxDropdownComponentAbstract implements OnChanges, OnDestroy {

  @Input() closeOnScroll = false;
  @Input() position = 'left';
  @Input() filterableList: any[];

  @ViewChild(TemplateRef, {static: true}) tpl: TemplateRef<any>;

  componentRef: ComponentRef<DxDropdownMenuComponent>;
  dropdownMaxWidth = 350;
  factoryComponent: any = DxDropdownMenuComponent;
  filterableListOriginal: any[] = [];
  menuElement;
  open = false;
  onScrollSub$: Subscription;
  overlaysSubscription;
  subs: Subscription = new Subscription();
  viewContainerRef = null;
  window: Window;

  @HostListener('click', ['$event'])
  onclick($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.toggleMenu();
  }

  @HostListener('keydown', ['$event'])
  onkeydown($event) {
    if (!this.open && $event.keyCode === 40) {
      $event.preventDefault();
      this.openMenu();
    }
  }

  protected constructor(
    public dxWindowService: DxWindowService,
    public elementRef: ElementRef,
    public injector: Injector,
    public overlayService: DxOverlayService,
    public positionService: DxPositionService,
    public resolver: ComponentFactoryResolver
  ) {
    this.subs.add(this.overlaysSubscription = this.overlayService.overlaysSubject$
      .subscribe((overlays) => { this.updateMenuState(overlays); }));
    this.window = this.dxWindowService.nativeWindow;
  }

  beforeMenuOpen = (): void => {};

  /**
   * Close the dropdown menu.
   */
  closeMenu = (): void => {
    this.open = false;
    if (this.onScrollSub$) { this.onScrollSub$.unsubscribe(); }
    this.overlayService.removeOverlayByType(this.factoryComponent);
  }

  /**
   * Angular OnChanges lifecycle hook.
   * @param changesObj  Angular SimpleChanges object.
   */
  ngOnChanges(changesObj: SimpleChanges) {
    if (changesObj.filterableList && !changesObj.filterableList.firstChange) {
      this.filterableListOriginal = changesObj.filterableList.currentValue;
    }
  }

  /**
   * Angular OnDestroy lifecycle hook.
   */
  ngOnDestroy() {
    this.overlaysSubscription.unsubscribe();
    this.subs.unsubscribe();
  }

  /**
   * Opens the dropdown menu by dynamically creating the XsDropMenuComponent.
   */
  openMenu = (): void => {
    const factory = this.resolver.resolveComponentFactory(this.factoryComponent);
    const ngContent = this.resolveNgContent();

    // Create the modal component to inject.
    this.componentRef = this.overlayService.addOverlay(factory, ngContent, this.injector);

    this.beforeMenuOpen();

    this.componentRef.instance['domReady'].subscribe((dom) => {
      if (!dom) { return; }

      this.menuElement = dom.menuElement;
      this.setPosition();

      this.open = true;

      // If the dropdown menu has the class 'dropdown-stretch',
      // then set the menu width to that of the trigger element's parent container width
      if (this.menuElement.classList.contains('dropdown-stretch')) {
        dom.dropdownMenuComp.setWidth(this.elementRef.nativeElement.parentElement.clientWidth);
      }

      // Listen to the closeMenu event fired by the menu component.
      if (dom.dropdownMenuComp['closeMenu']) {
        dom.dropdownMenuComp['closeMenu'].subscribe(() => {
          const originalList = dom.dropdownMenuComp.filterableListOriginal;
          if (originalList && originalList.length > 0) {
            this.filterableList = dom.dropdownMenuComp.filterableListOriginal;
          }

          this.closeMenu();
        });
      }

      if (this.filterableList) {

        // Init the dropdown menu and it event listeners
        dom.dropdownMenuComp.init();

        // Set the initial filter data
        dom.dropdownMenuComp.filterableList = this.filterableList;
        dom.dropdownMenuComp.filterableListOriginal = this.filterableList;

        // Update the list with the filtered data
        if (dom.dropdownMenuComp['listItemsChanged']) {
          dom.dropdownMenuComp['listItemsChanged'].subscribe((updatedList) => {
            this.filterableList = updatedList;
            this.componentRef.instance['filterableList'] = updatedList;
          });
        }
      }
    });

    this.onScrollSub$ = observableFromEvent(this.window, 'scroll').subscribe(() => {
      if (this.closeOnScroll === true) {
        this.closeMenu();
      }
    });
  }

  /**
   * Return a tree of DOM nodes from a template.
   */
  resolveNgContent = (): any[] => {
    // Handle a menu template
    if (this.tpl) {
      const viewRef = this.tpl.createEmbeddedView(null);
      return [viewRef.rootNodes];
    }

    return [[]];
  }

  /**
   * Properly set the absolute position of the dropdown menu.
   */
  setPosition() {
    if (!this.menuElement) { return; }
    const menuElement = this.menuElement;
    const triggerElement = this.elementRef.nativeElement.querySelector('[dxDropdownTrigger]');

    // When the trigger width is greather than the max dropdown width (350px),
    // make sure the menu doesn't stretch wider than the trigger.
    setTimeout(() => {
      const triggerWidth = triggerElement.offsetWidth;
      if (triggerElement.offsetWidth > this.dropdownMaxWidth) {
        menuElement.style.maxWidth = `${triggerWidth}px`;
      }
    });

    menuElement.style.position = 'absolute';
    menuElement.style.display = 'block';

    let position;
    if (this.position === 'right') {
      position = this.positionService.positionElements(triggerElement, menuElement, 'bottom-right', true);
    } else if (this.position === 'left') {
      position = this.positionService.positionElements(triggerElement, menuElement, 'bottom-left', true);
    } else if (this.position === 'center') {
      position = this.positionService.positionElements(triggerElement, menuElement, 'bottom-center', true);
    } else if (this.position === 'up') {
      position = this.positionService.positionElements(triggerElement, menuElement, 'top-left', true);
    }

    menuElement.style.top = position.top + 'px';
    menuElement.style.left = position.left + 'px';
  }

  /**
   * Toggles the state of the menu.
   */
  toggleMenu = () => {
    const alreadyOpen = this.open;
    this.overlayService.removeOverlayByType(this.factoryComponent);
    if (alreadyOpen) {
      this.open = false;
    } else {
      this.openMenu();
    }
  }

  /**
   * Dropdown list track-by function.
   * @param index      Index of list item.
   * @param listItem  X15 entity object.
   */
  trackById = (index: number, listItem: any): any => {
    return listItem ? listItem._id : undefined;
  }

  /**
   * Upates the open state of the menu.
   * @param overlays The array of extant overlay components from the overlay service.
   */
  updateMenuState = (overlays: Array<ComponentRef<any>>) => {
    if (this.componentRef) {
      this.open = !!find(overlays, (overlay: ComponentRef<any>) => {
        return overlay === this.componentRef;
      });
    }
  }
}
